/**
 * In React Admin's own words, the "[s]tore is a global, synchronous,
 * persistent store for storing user preferences. Think of the [s]tore
 * as a key-value [DB] that persists between page loads." React Admin's
 * default store utilizes local storage.
 *
 * In theory, the store can be helpful. For example, it can save the current
 * language or theme. It can also save the "state" of a particular page such
 * as its selected filters, sorts, etc. However, if a saved preference, such
 * as a filter, causes an error, it can be difficult for the user to exit an
 * error state whenever that preference is used, especially when the default
 * local storage store is used. If you want to recreate this hard-to-escape
 * error state behavior, you can can set the "per_page" query parameter in
 * the URL to a value such as 500000 (on a list page).
 *
 * Fortunately, React Admin offers us an alternate store implementation: the
 * memory store. As the name implies, the memory store uses memory rather than
 * local storage. Using a memory store allows us to enjoy the benefits of a
 * store but also have the escape hatch of refreshing a page, or closing a
 * window, in order to clear the store when necessary.
 */

import { memoryStore } from "react-admin";

const store = memoryStore();

export default store;
