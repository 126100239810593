/**
 * IMPORTANT: The order of actions, as enforced by React Admin is ⬇️
 *
 * 1. checkAuth
 *
 * 2. getIdentity
 *
 * 3. checkError (only called if `dataProvider` returns an error!)
 *
 * Source: https://marmelab.com/react-admin/AuthProviderWriting.html
 */

import { AuthProvider, HttpError as ReactAdminHttpError } from "react-admin";

import { storage } from "../storage";
import {
  AdminSignInResponse,
  HttpClientError,
  HttpClientResponse,
} from "../types";
import { errorTracker } from "../utils";
import { apiUtils, urlBuilder } from "./api_utils";
import { accessTokenUpdater, httpClient } from "./http_client";

const authProvider: AuthProvider = {
  /**
   * Send username and password to the BE and get back credentials.
   */
  login: (params: { password: string; username: string }): Promise<any> => {
    const url = urlBuilder.super("sign_in");
    return httpClient
      .post(url, {
        user_login: {
          email: params.username,
          password: params.password,
        },
      })
      .then((res: HttpClientResponse<AdminSignInResponse>) => {
        storage.storeAuthInfo(res.data);
        accessTokenUpdater.updateAccessToken(res.data.access_token, httpClient);
        return Promise.resolve();
      })
      .catch((err: HttpClientError) => {
        const message = apiUtils.generateErrorMessage(err);

        return Promise.reject({
          message,
        });
      });
  },

  /**
   * When the data provider returns an error, check if this is an authentication error.
   *
   * Please note that the error type here is a bit different than the error type in the
   * login method. This is because this method receives the error from the data provider
   * whereas the login method receives its error from the HTTP client.
   */
  checkError: (err: ReactAdminHttpError) => {
    // If we receive an unauthorized response it is likely that something is
    // wrong. This is because an unauthorized response should be intercepted
    // and handled at the HTTP client layer. Let's redirect the user to sign
    // in again.
    if (err.status === 401) {
      return Promise.reject(err);
    }
    // Perhaps the error isn't related to authentication, in which case we
    // let the user proceed.
    return Promise.resolve();
  },

  /**
   * When the user navigates, make sure that their credentials still exist.
   * If the credentials no longer exist, the user is routed to the sign in.
   * NOTE: Just because credentials exist in local storage doesn't guarantee
   * that they are valid. For example, the access token could be expired.
   */
  checkAuth: (_params): Promise<void> => {
    return storage.loadAuthInfo() ? Promise.resolve() : Promise.reject();
  },

  /**
   * Tell the BE to sign the current admin user out, remove local credentials,
   * and route the current admin user to sign in.
   */
  logout: () => {
    const url = urlBuilder.super("sign_out");
    return (
      httpClient
        .delete(url)
        // If the call to the BE suceeded that's great.
        .then((_res: HttpClientResponse<void>) => Promise.resolve())
        // If the call to the BE failed that's not so
        // great. Let's log the error to Sentry but
        // allow the sign out to continue.
        .catch((err: HttpClientError) => {
          errorTracker.captureException(err);
          return Promise.resolve();
        })
        .finally(() => {
          storage.removeAuthInfo();
        })
    );
  },

  /**
   * Get the user's profile information. This is only used for display purposes.
   */
  getIdentity: () => {
    const authInfo = storage.loadAuthInfo();
    if (authInfo) {
      return Promise.resolve({
        id: authInfo.admin_user.id,
        fullName: authInfo.admin_user.email,
      });
    }

    return Promise.reject();
  },

  /**
   *  Get the user permissions.
   */
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
