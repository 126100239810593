import { ReactElement } from "react";
import { MenuItemLink } from "react-admin";

import Authorize from "../Authorize";
import { menuItemLinkSx, SingleRegionMenuItemProps } from "./Common";

interface TopLevelSingleRegionMenuItemProps extends SingleRegionMenuItemProps {
  icon: ReactElement;
}

function TopLevelSingleRegionMenuItem(
  props: TopLevelSingleRegionMenuItemProps
) {
  return (
    <Authorize
      action={props.action}
      dataKind={props.dataKind}
      disableUnauthorizedMessage
    >
      <MenuItemLink
        key={props.to}
        to={props.to}
        primaryText={props.primaryText}
        leftIcon={props.icon}
        sx={menuItemLinkSx}
      />
    </Authorize>
  );
}

export default TopLevelSingleRegionMenuItem;
