/**
 * A combination of MUI's TextField component and Stripe's CardElement component.
 * We combine the two so that we give users the look/feel of a MUI TextField but
 * the functionality of the Stripe CardElement.
 */

import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import { forwardRef, useImperativeHandle } from "react";

// Following MUI's guide for integration with 3rd-party inputs:
//
// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
function StripeCreditCardTextField() {
  return (
    <TextField
      label="Credit Card Information"
      InputProps={{
        // @ts-expect-error
        inputComponent: StripeInput,
        inputProps: { component: CardElementWithoutZipCode },
      }}
      required
      // Used to horizontally center the CardElementWithoutZipCode
      // component within the TextField.
      sx={{
        [`& .${outlinedInputClasses.input}`]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    />
  );
}

// A wrapper around the Stripe CardElement component that returns a
// Stripe CardElement with the postal code hidden. This is necessary
// because the component passed to the TextField above needs to be a
// function as opposed to an element.
function CardElementWithoutZipCode(props: CardElementProps) {
  return <CardElement {...props} options={{ hidePostalCode: true }} />;
}

// Following MUI's guide for integration with 3rd-party inputs:
//
// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const StripeInput = forwardRef((props, ref) => {
  // @ts-expect-error
  const { component: Component, ...other } = props;

  // Implement `InputElement` interface.
  useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  return <Component {...other} />;
});

export default StripeCreditCardTextField;
