import { NumberField, TextField } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomShow, ShowTable } from "../custom";

function JobInfoShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.JobInfo}
      displayDelete={false}
      displayEdit={true}
    >
      <ShowTable title="Job Info Details">
        <TextField label="Id" source="id" />
        <TextField label="Kind" source="kind" />
        <TextField label="Status" source="status" />
        <NumberField
          label="Failed Attempts Made"
          source="failed_attempts_made"
        />
        <NumberField label="Failed Attempts Max" source="failed_attempts_max" />
        <NumberField label="Job Count" source="job_count" />
        <TextField label="Payload" source="payload" />
        <TextField label="Cron Key" source="cron_key" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Ended At" source="ended_at" />
        <CustomDateTimeField label="Process After" source="process_after" />
        <CustomDateTimeField label="Started At" source="started_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default JobInfoShow;
