import { TextField, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomList, SalesforceLink } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const companyFilters = [
  <TextInput label="Name" source="name_like" alwaysOn />,
  <TextInput label="Email Domain" source="email_domain_like" alwaysOn />,
  <TextInput label="Fieldwire Rep" source="fw_rep_admin_user_id_eq" alwaysOn />,
];

function CompanyList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.Company}
      displayCreate={true}
      filters={companyFilters}
    >
      <TextField label="Id" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Email Domain" source="email_domain" />
      <TextField label="Fieldwire Rep" source="fw_rep_admin_user_id" />
      {/* MISSING: Accounts */}
      {/* MISSING: Sum Lead Score */}
      {/* MISSING: Avg Lead Score */}
      {/* MISSING: Total Users */}
      {/* MISSING: 30 Day Users*/}
      <SalesforceLink label="Salesforce Id" />
    </CustomList>
  );
}

export default CompanyList;
