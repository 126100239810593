enum ChargeCategory {
  Subscription = "subscription",
  Overage = "overage",
}

enum ChargeState {
  Failed = "failed",
  Succeeded = "succeeded",
  Retried = "retried",
}

enum PremiumPlanType {
  EnterprisePlan = "EnterprisePlan",
  StripeSubscription = "StripeSubscription",
}

export { ChargeCategory, ChargeState, PremiumPlanType };
