/**
 * A modal that appears on the login page that routes users to the
 * IT help desk if they need help logging in (ex: forgot password).
 */

import Box from "@mui/material/Box";
import { Button } from "react-admin";

import { ModalProps } from "../../../types";
import { ActionButtonsBar, ExternalLinkButton, Modal } from "..";

function TroubleLoggingInModal(props: ModalProps) {
  return (
    <Modal open={props.modalOpen} title="Trouble logging in?">
      <Box
        component="p"
        sx={{ color: "fieldwire.gray.1", lineHeight: "150%", margin: "0" }}
      >
        To reset your password or unlock your account, please submit a help
        request to Fieldwire IT.
      </Box>
      <ActionButtonsBar>
        <Button
          label="Cancel"
          onClick={props.handleClose}
          variant="fieldwire-secondary"
        />
        <ExternalLinkButton
          href="https://fieldwirehelpdesk.zendesk.com/hc/en-us/requests/new"
          label="Submit Help Request"
        />
      </ActionButtonsBar>
    </Modal>
  );
}

export default TroubleLoggingInModal;
