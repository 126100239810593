/**
 * The purpose of the CustomReferenceField component is to
 * ensure that a reference field always links to the show
 * page of that record, rather than an edit page.
 */
import { ReactElement } from "react";
import { ReferenceField } from "react-admin";

interface CustomReferenceFieldProps {
  children: any;
  source: string;
  reference: string;
  label?: string | ReactElement;
  sortable?: boolean;
}

function CustomReferenceField(props: CustomReferenceFieldProps) {
  // If `sortable` is not specified, default to `true`.
  const sortable = props.sortable ?? true;

  return (
    <ReferenceField
      label={props.label}
      link="show"
      reference={props.reference}
      source={props.source}
      sortable={sortable}
    >
      {props.children}
    </ReferenceField>
  );
}

export default CustomReferenceField;
