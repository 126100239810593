import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

function ConfigVariableEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.ConfigVariable}>
      <TextInput label="Name" source="name" required />
      <TextInput label="Value" source="value" required />
    </CustomEdit>
  );
}

export default ConfigVariableEdit;
