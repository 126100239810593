/**
 * An opinionated, custom hook that adds additional functionality to
 * React Query's useMutation hook. The additional functionality is:
 *
 *  1) Display a message upon successful mutation.
 *  2) [Optional] Refresh the page upon successful mutation.
 *  3) Display an error message upon unsuccessful mutation.
 */

import { useNotify, useRefresh } from "react-admin";
import {
  MutationFunction,
  MutationKey,
  useMutation as useReactQueryMutation,
} from "react-query";

import { apiUtils } from "../api";
import { HttpClientError } from "../types";

function useMutation<TData, TVariables>(
  mutationFn: MutationFunction<TData, TVariables>,
  mutationKey: MutationKey,
  mutationSuccessMessage: string | ((response: TData) => string),
  skipPageRefresh?: boolean
) {
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate, isLoading } = useReactQueryMutation(mutationKey, mutationFn, {
    onSuccess: (response: TData) => {
      if (!skipPageRefresh) {
        refresh();
      }
      if (typeof mutationSuccessMessage === "string") {
        notify(mutationSuccessMessage);
      } else {
        notify(mutationSuccessMessage(response));
      }
    },
    onError: (error: HttpClientError) => {
      notify(apiUtils.generateErrorMessage(error));
    },
  });

  return { mutate, isLoading };
}

export default useMutation;
