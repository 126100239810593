import TextField from "@mui/material/TextField";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider } from "react-admin";

import { useForm, useMutation } from "../../../hooks";
import {
  CustomDataProvider,
  ModalProps,
  ProjectShowResponse,
  Region,
} from "../../../types";
import { ActionButtonsBar, Form, Modal } from "..";

interface ExportProjectModalProps extends ModalProps {
  project: ProjectShowResponse;
  region: Region;
}

interface FormState {
  projectId: Identifier;
  userId: Identifier;
}

function ExportProjectModal(props: ExportProjectModalProps) {
  const defaultFormState: FormState = {
    projectId: props.project.id,
    userId: "",
  };

  const { form, handleChangeForm, handleClearForm } = useForm(defaultFormState);

  const dataProvider = useDataProvider<CustomDataProvider>();
  const mutationFn = (form: FormState) => {
    return dataProvider.exportProject(
      props.region,
      form.projectId,
      form.userId
    );
  };

  const { mutate } = useMutation(
    mutationFn,
    ["createProjectExport", form],
    "Project export in progress! ✅",
    true
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();
    props.handleClose();
    mutate(form);
  };

  return (
    <Modal open={props.modalOpen} title="New Project Export">
      <Form onSubmit={handleSubmit}>
        <TextField
          disabled
          label="Project Id"
          name="projectId"
          value={form.projectId}
          required
        />
        <TextField
          label="User Id"
          name="userId"
          onChange={handleChangeForm}
          type="number"
          value={form.userId}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!form.userId}
            label="Create Project Export"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default ExportProjectModal;
