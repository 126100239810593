import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import { Action, DataKind } from "../../types";
import { Authorize, TitleRow } from "../custom";

function IosReleaseStatus() {
  return (
    <Authorize action={Action.Read} dataKind={DataKind.IosReleaseStatus}>
      <TitleRow title="iOS Release Status" />
      <Alert severity="warning">
        This chart is not environment dependent. It is hardcoded to represent
        production data. This means that it will display production data whether
        this code is being run in production, staging, or locally.
      </Alert>
      {/*
        Normally we wrap charts in the `ChartContainer` component. However, in
        this case we only have a single chart that is very large and follow the
        style we have in Admin V1 in which the chart has no container.
      */}
      <Box
        component="iframe"
        src="https://datastudio.google.com/embed/reporting/33066d9b-f5a7-4ffe-8aa9-01fc2f2c1acf/page/1xZU"
        sx={{
          border: "none",
          marginTop: "24px",
          // As specified in Admin V1.
          height: "2300px",
        }}
      ></Box>
    </Authorize>
  );
}

export default IosReleaseStatus;
