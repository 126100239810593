import { AccessToken, HttpClient } from "../../types";

const httpClientUpdater = {
  /**
   * For a given HTTP Client instance, update its Authorization header if possible.
   */
  updateAuthHeader: (
    accessToken: AccessToken,
    httpClient: HttpClient
  ): void => {
    // https://axios-http.com/docs/config_defaults
    httpClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  },
};

export default httpClientUpdater;
