import { SelectInput, TextInput } from "react-admin";

import { DataKind, JobInfoKind } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomCreate } from "../custom";

const kindChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(JobInfoKind);

function JobInfoCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.JobInfo}>
      <SelectInput label="Kind" source="kind" choices={kindChoices} required />
      <TextInput label="JSON Payload" source="payload" required />
    </CustomCreate>
  );
}

export default JobInfoCreate;
