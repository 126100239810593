/**
 * A simple, select field that allows the current admin user to select a country.
 *
 * Currently used by the Checkout and UpdateCreditCard modals to gather account
 * invoice address information.
 */

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { getCountries } from "../../../iso";
import { Country } from "../../../types";

interface CountrySelectFieldProps {
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required?: boolean;
}

function CountrySelectField(props: CountrySelectFieldProps) {
  return (
    <TextField
      label={props.label}
      name={props.name}
      onChange={props.onChange}
      required={props.required}
      value={props.value}
      select
    >
      {getCountries().map((country: Country) => {
        return (
          <MenuItem key={country.code} value={country.code}>
            {country.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default CountrySelectField;
