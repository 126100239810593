enum AccountRole {
  AccountMember = "account_member",
  AccountAdmin = "account_admin",
}

// TODO [Nice-to-Have] [BESTUS-591]: Add pretty name support for this input field
enum Market {
  Us = "us",
  De = "de",
  It = "it",
  Cz = "cz",
  Fr = "fr",
  Gb = "gb",
  Ca = "ca",
  Ir = "ir",
  Bg = "bg",
  Sx = "sx",
  Ky = "ky",
  Hu = "hu",
  Tz = "tz",
  No = "no",
  Bm = "bm",
  Nf = "nf",
  Br = "br",
  La = "la",
  Nz = "nz",
  Pm = "pm",
  Tm = "tm",
  Al = "al",
  Mu = "mu",
  Re = "re",
  Sz = "sz",
  Hk = "hk",
  Bo = "bo",
  Kg = "kg",
  Ae = "ae",
  Om = "om",
  Mx = "mx",
  Mp = "mp",
  Ne = "ne",
  Mf = "mf",
  Mv = "mv",
  Aq = "aq",
  Yt = "yt",
  Mt = "mt",
  Ax = "ax",
  Lc = "lc",
  Kr = "kr",
  Fo = "fo",
  Gs = "gs",
  Th = "th",
  Cf = "cf",
  Ec = "ec",
  Lu = "lu",
  Tf = "tf",
  Um = "um",
  Mc = "mc",
  Hm = "hm",
  Si = "si",
  At = "at",
  Sr = "sr",
  Sl = "sl",
  Kz = "kz",
  Gn = "gn",
  Pf = "pf",
  Aw = "aw",
  Pn = "pn",
  Cl = "cl",
  Sb = "sb",
  Tk = "tk",
  Cd = "cd",
  Iq = "iq",
  Lv = "lv",
  Ms = "ms",
  Az = "az",
  Ls = "ls",
  Ag = "ag",
  Np = "np",
  Wf = "wf",
  Km = "km",
  Pr = "pr",
  Sv = "sv",
  Pw = "pw",
  Im = "im",
  Ni = "ni",
  Cm = "cm",
  Tn = "tn",
  Nu = "nu",
  Kp = "kp",
  Mw = "mw",
  Td = "td",
  Ke = "ke",
  Py = "py",
  Bj = "bj",
  Ye = "ye",
  Gt = "gt",
  Cn = "cn",
  Io = "io",
  Dk = "dk",
  Eg = "eg",
  Gh = "gh",
  Li = "li",
  Nc = "nc",
  Na = "na",
  Jp = "jp",
  Dz = "dz",
  Pt = "pt",
  Fj = "fj",
  Ai = "ai",
  Jm = "jm",
  Fk = "fk",
  Sc = "sc",
  Mg = "mg",
  Rs = "rs",
  Tw = "tw",
  In = "in",
  Zm = "zm",
  So = "so",
  Pg = "pg",
  Lt = "lt",
  Ar = "ar",
  Mh = "mh",
  Mo = "mo",
  Cg = "cg",
  Mq = "mq",
  Kn = "kn",
  Tr = "tr",
  Ge = "ge",
  Gf = "gf",
  Dm = "dm",
  Ly = "ly",
  Bw = "bw",
  Pe = "pe",
  Cu = "cu",
  Tj = "tj",
  Mr = "mr",
  Be = "be",
  Ck = "ck",
  Bi = "bi",
  Gp = "gp",
  Vu = "vu",
  Mm = "mm",
  Bn = "bn",
  Qa = "qa",
  Bh = "bh",
  Ci = "ci",
  Ch = "ch",
  Bf = "bf",
  Kh = "kh",
  Bv = "bv",
  Ht = "ht",
  Tv = "tv",
  Kw = "kw",
  Lr = "lr",
  Uy = "uy",
  Me = "me",
  Et = "et",
  Ie = "ie",
  Cr = "cr",
  Gu = "gu",
  Mz = "mz",
  Vc = "vc",
  Cx = "cx",
  Er = "er",
  Ro = "ro",
  Id = "id",
  Va = "va",
  Ba = "ba",
  Md = "md",
  Gq = "gq",
  Gm = "gm",
  Mn = "mn",
  Ru = "ru",
  Bz = "bz",
  Ml = "ml",
  Rw = "rw",
  Jo = "jo",
  Ph = "ph",
  Co = "co",
  Tt = "tt",
  Pl = "pl",
  Sk = "sk",
  Nl = "nl",
  Mk = "mk",
  Hn = "hn",
  Ee = "ee",
  Se = "se",
  Au = "au",
  My = "my",
  Uz = "uz",
  Nr = "nr",
  Tg = "tg",
  Cy = "cy",
  Cv = "cv",
  Gi = "gi",
  Sd = "sd",
  Ad = "ad",
  Sa = "sa",
  Do = "do",
  Ve = "ve",
  Zw = "zw",
  Bl = "bl",
  Bb = "bb",
  Ws = "ws",
  Ss = "ss",
  Ua = "ua",
  Vg = "vg",
  Ng = "ng",
  Pa = "pa",
  Gy = "gy",
  Vi = "vi",
  Fi = "fi",
  Af = "af",
  Bt = "bt",
  Ma = "ma",
  Ps = "ps",
  Il = "il",
  Tc = "tc",
  Bd = "bd",
  Es = "es",
  Am = "am",
  Je = "je",
  Cc = "cc",
  Sh = "sh",
  By = "by",
  Gl = "gl",
  Fm = "fm",
  Dj = "dj",
  Eh = "eh",
  Gg = "gg",
  To = "to",
  Tl = "tl",
  Is = "is",
  As = "as",
  Sg = "sg",
  Cw = "cw",
  Ao = "ao",
  Ga = "ga",
  Gr = "gr",
  Gd = "gd",
  Lk = "lk",
  Ug = "ug",
  Hr = "hr",
  Za = "za",
  Sm = "sm",
  Sy = "sy",
  Lb = "lb",
  St = "st",
  Ki = "ki",
  Vn = "vn",
  Bs = "bs",
  Pk = "pk",
  Gw = "gw",
  Sn = "sn",
}

enum PlanInfo {
  EnterprisePro = "Pro - Invoice",
  EnterpriseBusiness = "Business - Invoice",
  EnterpriseBusinessPlus = "Business Plus - Invoice",
  EnterprisePremier = "Enterprise - Invoice",
  StripeTeamV1 = "Basic - v1",
  StripeProV1 = "Pro - v1",
  StripeProMonthlyV1 = "Pro - monthly - v1",
  StripeProMonthlyV2 = "Pro - monthly - v2",
  StripeProMonthlyV3 = "Pro - monthly - v3",
  StripeProMonthlyV4 = "Pro - monthly - v4",
  StripeProMonthlyV5 = "Pro - monthly - v5",
  StripeProYearlyV1 = "Pro - yearly - v1",
  StripeProYearlyV2 = "Pro - yearly - v2",
  StripeProYearlyV3 = "Pro - yearly - v3",
  StripeProYearlyV4 = "Pro - yearly - v4",
  StripeProYearlyV5 = "Pro - yearly - v5",
  StripeProYearlyV6 = "Pro - yearly - v6",
  StripeProYearlyV7 = "Pro - yearly - v7",
  StripeBusinessV1 = "Business - v1",
  StripeBusinessMonthlyV1 = "Business - monthly - v1",
  StripeBusinessMonthlyV2 = "Business - monthly - v2",
  StripeBusinessMonthlyV3 = "Business - monthly - v3",
  StripeBusinessMonthlyV4 = "Business - monthly - v4",
  StripeBusinessMonthlyV5 = "Business - monthly - v5",
  StripeBusinessMonthlyV6 = "Business - monthly - v6",
  StripeBusinessYearlyV1 = "Business - yearly - v1",
  StripeBusinessYearlyV2 = "Business - yearly - v2",
  StripeBusinessYearlyV3 = "Business - yearly - v3",
  StripeBusinessYearlyV4 = "Business - yearly - v4",
  StripeBusinessYearlyV5 = "Business - yearly - v5",
  StripeBusinessYearlyV6 = "Business - yearly - v6",
  StripeBusinessYearlyV7 = "Business - yearly - v7",
  StripeBusinessPlusMonthlyV1 = "Business Plus - monthly - v1",
  StripeBusinessPlusMonthlyV2 = "Business Plus - monthly - v2",
  StripeBusinessPlusMonthlyV3 = "Business Plus - monthly - v3",
  StripeBusinessPlusYearlyV1 = "Business Plus - yearly - v1",
  StripeBusinessPlusYearlyV2 = "Business Plus - yearly - v2",
  StripeBusinessPlusYearlyV3 = "Business Plus - yearly - v3",
}

enum PlanName {
  Basic = "Basic",
  Business = "Business",
  BusinessPlus = "Business Plus",
  Pro = "Pro",

  // Note that we rename for parity with our Rails app.
  Premier = "Enterprise",
}

enum PremiumPlanKind {
  EnterprisePro = "enterprise_pro",
  EnterpriseBusiness = "enterprise_business",
  EnterpriseBusinessPlus = "enterprise_business_plus",
  EnterprisePremier = "enterprise_premier",
  StripePro = "stripe_pro",
  StripeBusiness = "stripe_business",
  StripeBusinessPlus = "stripe_business_plus",
}

enum Region {
  Eu = "eu",
  Us = "us",
}

export { AccountRole, Market, PlanInfo, PlanName, PremiumPlanKind, Region };
