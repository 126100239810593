import { BooleanField, TextField } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomShow, ShowTable } from "../custom";

function InAppPurchaseShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.InAppPurchase}
      displayDelete={true}
      displayEdit={true}
    >
      <ShowTable title="In App Purchase Details">
        <TextField label="App Store" source="app_store_id" />
        <TextField label="Description" source="description" />
        <BooleanField label="Is Enabled" source="is_enabled" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default InAppPurchaseShow;
