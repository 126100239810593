import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function CrInvitesSevenDayTrailingChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      37
    );

    // NOTE: `invitesSentSevenDayTrailingSum` and `invitesAcceptedSevenDayTrailingSum` should be of the same length.
    const invitesSent = processedData.map(
      (d: ActiveUserCountsForMarketingResponse) => d.invites_sent
    );
    const invitesSentSevenDayTrailingSum =
      analyticsUtils.calculateSevenPeriodTrailingSum(invitesSent);

    const invitesAccepted = processedData.map(
      (d: ActiveUserCountsForMarketingResponse) => d.invites_accepted
    );
    const invitesAcceptedSevenDayTrailingSum =
      analyticsUtils.calculateSevenPeriodTrailingSum(invitesAccepted);

    const invitesConversionRate = invitesAcceptedSevenDayTrailingSum.map(
      (invitesAccepted: number, index: number) => {
        return invitesAccepted / invitesSentSevenDayTrailingSum[index];
      }
    );

    return (
      <ChartContainer title="CR Invite Conversions (7 Day Trailing)">
        <LineChart
          labels={analyticsUtils
            .getLastNItems(processedData, 30)
            .map((d: ActiveUserCountsForMarketingResponse) => d.date)}
          datasets={[
            {
              data: analyticsUtils.getLastNItems(invitesConversionRate, 30),
              label: "Conversion Rate",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default CrInvitesSevenDayTrailingChart;
