/**
 * The `TitleRow` is meant to wrap a `Title` component and, optionally, some
 * set of CRUD and/or custom Fieldwire action(s). The title will be displayed
 * on the left side of the row and the action(s) on the right side.
 */

import Box from "@mui/material/Box";

import { Title } from ".";

interface TitleRowProps {
  title: string;
  actions?: any;
}

function TitleRow(props: TitleRowProps) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "24px",
      }}
    >
      <Title title={props.title} />
      {props.actions}
    </Box>
  );
}

export default TitleRow;
