import { TextInput } from "react-admin";

import { DataKind, Region } from "../../types";
import { resourceUtils } from "../../utils";
import { routes } from "../../utils/Routes";
import {
  CustomCreate,
  CustomNumberInput,
  LabelWithInfoTooltip,
} from "../custom";

function StressTestingProjectCreate(props: { region: Region }) {
  return (
    <CustomCreate
      checkPermissionFor={DataKind.StressTestingProject}
      // A stress testing project is just a project. However, on the BE we
      // use a worker to generate the project which means we won't know the
      // id of the newly created project record right away (nor does the BE
      // return it, of course!). Therefore, we redirect to the project list
      // page instead.
      redirect={(..._args) =>
        routes.computeListFor(resourceUtils.projectResourceFor(props.region))
      }
    >
      <TextInput label="User Id" source="user_id" required />
      <TextInput label="Project Name" source="project_name" required />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Forms"
        source="num_forms"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Sections Per Form"
        source="num_sections_per_form"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Records Per Section"
        source="num_records_per_section"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Tasks"
        source="num_tasks"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label="Attachment Bubbles Per Task"
        source="num_bubbles_attachments_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label="Text Bubbles Per Task"
        source="num_bubbles_text_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Task Watchers Per Task"
            tooltipText="Please create appropriate number of Random Users."
          />
        }
        source="num_watchers_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label="Number of Custom Task Attributes"
        source="num_task_type_attributes"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Task Relations Per Task"
            tooltipText="Please create appropriate number of Tasks."
          />
        }
        source="num_task_relations_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Forms Per Task"
            tooltipText="Please create appropriate number of Tasks and Forms."
          />
        }
        source="num_forms_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Check List Items Per Task"
            tooltipText="Please create appropriate number of Tasks."
          />
        }
        source="num_check_items_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label="Number of Entity Tags"
        source="num_entity_tags"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Entity Tagging Items Per Task"
            tooltipText="Please create appropriate number of Tasks and Entity Tags."
          />
        }
        source="num_entity_taggings_per_task"
        required
      />
      <CustomNumberInput
        defaultValue={0}
        label={
          <LabelWithInfoTooltip
            labelText="Number of Random Users"
            tooltipText="Use 0 for the 12 shared default users!"
          />
        }
        source="num_random_users"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Floorplans"
        source="num_floorplans"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Sheets Per Floorplan"
        source="num_sheets_per_plan"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Markups Per Sheet"
        source="num_markups_per_sheet"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of RFIs"
        source="num_rfis"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Change Orders"
        source="num_change_orders"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Photos"
        source="num_photos"
        required
      />
      <CustomNumberInput
        defaultValue={1}
        label="Number of Files"
        source="num_files"
        required
      />
    </CustomCreate>
  );
}

export default StressTestingProjectCreate;
