/**
 * A wrapper around React Admin's DateTimeInput component that allows admin
 * users to create or edit times in UTC as opposed to their local timezone.
 * This allows us to be in line with Admin V1 and avoids placing the burden
 * of having to manage timezone differences on our admin users.
 *
 * This wrapper also allows its callers to specify a value for seconds, if
 * desired, that will be applied to the value of the input when it changes.
 */

import setSeconds from "date-fns/setSeconds";
import { DateTimeInput, DateTimeInputProps } from "react-admin";

interface CustomUtcDateTimeInputProps extends DateTimeInputProps {
  parseSecondsAs?: number;
}

function CustomUtcDateTimeInput(props: CustomUtcDateTimeInputProps) {
  const { parseSecondsAs, ...rest } = props;

  if (parseSecondsAs && (parseSecondsAs < 0 || parseSecondsAs > 59)) {
    throw new Error("seconds must be >= 0 and <= 59");
  }

  return (
    <DateTimeInput
      {...rest}
      format={formatDateTime}
      parse={parseDateTimeWithSeconds(parseSecondsAs)}
    />
  );
}

/**
 * Please note that this is copy-and-pasted from React Admin's source
 * and should not be modified.
 *
 * The reason that we need to do this is that we do need to modify the
 * `convertDateToString` helper that `formatDateTime` uses and there is
 * no way to do that without redefining and passing formatDateTime here.
 */
const formatDateTime = (value: string | Date) => {
  // null, undefined and empty string values should not go through
  // convertDateToString. Otherwise, it returns undefined and will
  // make the input an uncontrolled one.
  if (value == null || value === "") {
    return "";
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  // Valid dates should not be converted.
  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};

/**
 * Returns a slightly modified version of React Admin's default parse function.
 *
 * 1. Ensures that the datetime returned by the input is stored by us as a UTC
 *    datetime. This is accomplished by simply adding a `Z` to the end of the
 *    datetime string before it is passed to the `Date` constructor.
 *
 * 2. Sets seconds of parsed datetime to some desired value, if applicable.
 *
 * Note that the format of the datetime from the input is: `yyyy-MM-ddThh:mm`.
 */
const parseDateTimeWithSeconds = (parseSecondsAs?: number) => {
  return (datetime: string) => {
    if (datetime) {
      const utcDateInstance = new Date(`${datetime}Z`);
      return parseSecondsAs
        ? setSeconds(utcDateInstance, parseSecondsAs)
        : utcDateInstance;
    } else if (datetime === "") {
      return null;
    }
    return datetime;
  };
};

/**
 * A modification of React Admin's default `convertDateToString` that simply
 * uses `getUTC...` instead of `get...` for the date instance methods. This
 * is done because we create and store datetimes as UTC. As such, we should
 * display them as UTC as well.
 */
const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return "";
  const yyyy = leftPad4(value.getUTCFullYear());
  const MM = leftPad2(value.getUTCMonth() + 1);
  const dd = leftPad2(value.getUTCDate());
  const hh = leftPad2(value.getUTCHours());
  const mm = leftPad2(value.getUTCMinutes());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};

// Copy-and-pasted from React Admin source.
// yyyy-MM-ddThh:mm
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

// Copy-and-pasted from React Admin source.
const leftPad = (nb: number) => (value: number) =>
  ("0".repeat(nb) + value).slice(-nb);

// Copy-and-pasted from React Admin source.
const leftPad4 = leftPad(4);
// Copy-and-pasted from React Admin source.
const leftPad2 = leftPad(2);

export default CustomUtcDateTimeInput;
