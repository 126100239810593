import { TextField } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomReferenceField,
  CustomShow,
  EnterprisePlanInvoiceMrrField,
  ShowTable,
} from "../custom";

function EnterprisePlanInvoiceShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.EnterprisePlanInvoice}
      displayDelete={false}
      displayEdit={true}
    >
      <ShowTable title="Enterprise Plan Invoice Details">
        <TextField label="Id" source="id" />
        <CustomReferenceField
          label="Enterprise Plan"
          source="enterprise_plan_id"
          reference={Resource.EnterprisePlan}
        >
          <TextField source="name" />
        </CustomReferenceField>
        <CustomReferenceField
          label="Owner Email"
          source="enterprise_plan_id"
          reference={Resource.EnterprisePlan}
          sortable={false}
        >
          <TextField source="owner_email" />
        </CustomReferenceField>
        <TextField label="Description" source="description" />
        <TextField
          label="Purchase Order Number"
          source="purchase_order_number"
        />
        <TextField
          label="Currency"
          source="currency"
          sx={{ textTransform: "uppercase" }}
        />
        <TextField label="Amount" source="amount_native_currency" />
        <EnterprisePlanInvoiceMrrField label="MRR (USD)" />
        <CustomDateTimeField label="Start At" source="start_at" />
        <CustomDateTimeField label="End At" source="end_at" />
        <CustomDateTimeField label="Paid At" source="paid_at" />
        <TextField label="Kind" source="kind" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default EnterprisePlanInvoiceShow;
