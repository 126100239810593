import { BooleanField, TextField, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const inAppPurchaseFilters = [
  <TextInput label="App Store" source="app_store_id_like" alwaysOn />,
  <TextInput label="Description" source="description_like" alwaysOn />,
];

function InAppPurchaseList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.InAppPurchase}
      displayCreate={true}
      filters={inAppPurchaseFilters}
    >
      <TextField label="App Store Id" source="app_store_id" />
      <TextField label="Description" source="description" />
      <BooleanField label="Is Enabled" source="is_enabled" />
    </CustomList>
  );
}

export default InAppPurchaseList;
