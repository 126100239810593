import { SelectInput, SelectInputProps } from "react-admin";

import { Currency } from "../../../types";
import { componentUtils } from "../../component_utils";

const currencyChoices =
  componentUtils.generateUpperCaseSelectChoicesFromEnum(Currency);

function CurrencySelectInput(props: SelectInputProps) {
  return <SelectInput {...props} choices={currencyChoices} />;
}

export default CurrencySelectInput;
