/**
 * The purpose of this CustomDatagrid component is to
 * ensure that custom Datagrid settings are constant
 * across all index pages.
 */

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { MouseEvent } from "react";
import {
  Datagrid,
  Identifier,
  RaRecord,
  RowClickFunction,
  useCreatePath,
  useRecordContext,
  useResourceContext,
} from "react-admin";

import { CustomRowClickConfig } from "../../../types";

interface CustomDatagridProps {
  children: any;
  customRowClickConfig?: CustomRowClickConfig;
  disableRowClick?: boolean;
}

interface OpenInNewTabLinkProps {
  rowClickFn: RowClickFunction;
}

function CustomEmptyForDatagrid() {
  return (
    <Box
      sx={{
        padding: "24px",
        // Because the empty component is returned as a standalone component when
        // there are no records for the Datagrid to display, the size of the empty
        // component can, in some cases, be affected by its parent container.
        //
        // For example, a parent container which is a flex box in the row direction
        // will cause the empty component to not take up the full width of the parent
        // container. This is explained in a thread here:
        //
        // https://stackoverflow.com/questions/50455838/flexbox-not-full-width
        //
        // To ensure that the empty component always takes up the full width of its
        // parent container, whatever its parent container is, we set the width to 100%.
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: "fieldwire.gray.1",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        No records found.
      </Typography>
    </Box>
  );
}

/**
 * A "right-clickable" link that opens, in a new tab,
 * whichever page clicking on the row would have opened.
 */
function OpenInNewTabLink(props: OpenInNewTabLinkProps) {
  const record = useRecordContext();
  const resource = useResourceContext();

  if (record && resource) {
    return (
      <Link
        href={`#${props.rowClickFn(record.id, resource, record)}`}
        rel="noopener noreferrer"
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={(e: MouseEvent<HTMLAnchorElement>) =>
          // If we do not stop event propagation here, it will propagate to the
          // row as a whole. This means that if an admin user right-clicks or
          // outright clicks on this link, they will also be inadvertantly
          // taken, in the current tab, to whichever page the row click
          // would take them to.
          e.stopPropagation()
        }
      >
        <OpenInNewIcon
          // The smallest size that MUI Icons offers us is still
          // a bit too big so we manually set height/width here.
          sx={{ height: "18px", width: "18px" }}
        />
      </Link>
    );
  } else {
    return null;
  }
}

function CustomDatagrid(props: CustomDatagridProps) {
  const createPath = useCreatePath();

  const rowClickFn = (id: Identifier, resource: string, record: RaRecord) => {
    // Construct a custom show page path to route to for current record.
    if (props.customRowClickConfig) {
      // prettier-ignore
      return `/${props.customRowClickConfig.reference}/${record[props.customRowClickConfig.source]}/show`;
    }
    // Route to default show page for current record.
    return createPath({ resource, id, type: "show" });
  };

  return (
    <Datagrid
      empty={<CustomEmptyForDatagrid />}
      bulkActionButtons={false}
      isRowSelectable={(_record) => false}
      rowClick={props.disableRowClick ? undefined : rowClickFn}
      sx={{
        overflowX: "scroll",
        width: "100%",
        "& .RaDatagrid-headerCell": {
          borderBottomColor: "fieldwire.gray.5",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          color: "fieldwire.gray.1",
          fontWeight: 800,
          whiteSpace: "nowrap",
        },
        "& .RaDatagrid-rowCell": {
          borderBottomColor: "fieldwire.gray.5",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          color: "fieldwire.gray.1",
          whiteSpace: "nowrap",
        },
        // Ensure that all cells in the
        // last row don't have a border.
        " & .RaDatagrid-row": {
          "&:last-child": {
            "& .RaDatagrid-rowCell": {
              borderBottom: "none",
            },
          },
        },
      }}
    >
      {!props.disableRowClick && <OpenInNewTabLink rowClickFn={rowClickFn} />}
      {props.children}
    </Datagrid>
  );
}

export default CustomDatagrid;
