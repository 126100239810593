import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit, CustomNumberInput } from "../custom";

function SsoInfoEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.SsoInfo}>
      <CustomNumberInput label="Account ID" source="account_id" required />
      <TextInput label="Domain" source="domain" required />
      <TextInput
        label="Auth0 Connection Name"
        source="auth0_connection_name"
        required
      />
    </CustomEdit>
  );
}

export default SsoInfoEdit;
