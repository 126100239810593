/**
 * The purpose of this CustomList component is to reduce
 * boilerplate associated with the list pages and minimize
 * the chance of not including critical parts of the page
 * such as authorization and custom pagination settings.
 */
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { ReactElement } from "react";
import { FilterPayload, ListBase, useListContext } from "react-admin";

import { DEFAULT_SORT, PER_PAGE } from "../../../constants";
import {
  Action,
  CustomRowClickConfig,
  DataKind,
  SortPayload,
} from "../../../types";
import {
  Authorize,
  CustomActions,
  CustomDatagrid,
  CustomFilterForm,
  CustomPagination,
  TitleRow,
} from "..";

interface CustomListProps {
  checkPermissionFor: DataKind;
  children: any;
  displayCreate: boolean;
  customRowClickConfig?: CustomRowClickConfig;
  customTitle?: string;
  disableRowClick?: boolean;
  fieldwireActions?: ReactElement;
  filter?: FilterPayload;
  filters?: ReactElement[];
  // React Admin uses an ascending sort on id as its default for list pages.
  // We prefer the default sort to be descending on id so newer records are
  // shown first (set below). However, in some cases we would like the default
  // sort to be something other than descending on id, which is why this prop
  // exists. For example, the id of projects is a UUID, so a descending sort on
  // created_at would be more appropriate.
  defaultSort?: SortPayload;
  // If true, hides the pagination controls from the UI. It is important to
  // note, however, that this does not prevent the pagination query params
  // from being sent as part of the request. This would have to be done at
  // the data provider level and would not be worth the trouble as almost all
  // list endpoints are paginated. More importantly, the BE will ignore any
  // pagination query params that are sent from the FE for endpoints where
  // we don't want pagination.
  hidePaginationControl?: boolean;
}

function ListTitleRow(props: { actions: any; customTitle?: string }) {
  const { defaultTitle } = useListContext();
  const title = props.customTitle ?? defaultTitle;

  if (title) {
    return <TitleRow actions={props.actions} title={title} />;
  } else {
    return null;
  }
}

function CustomList(props: CustomListProps) {
  return (
    <Authorize action={Action.Read} dataKind={props.checkPermissionFor}>
      <ListBase
        filter={props.filter}
        perPage={PER_PAGE}
        sort={props.defaultSort ?? DEFAULT_SORT}
      >
        <ListTitleRow
          actions={
            <CustomActions
              checkPermissionFor={props.checkPermissionFor}
              displayCreate={props.displayCreate}
              displayDelete={false}
              displayEdit={false}
              displayShow={false}
              fieldwireActions={props.fieldwireActions}
            />
          }
          customTitle={props.customTitle}
        />
        {/* Do not render `FilterForm` if `props.filters` are undefined or empty. */}
        {props.filters && props.filters.length > 0 && (
          // We have to use `Box` because `FilterForm` does not expose the `sx` prop.
          <Box sx={{ marginBottom: "16px" }}>
            <CustomFilterForm filters={props.filters} />
          </Box>
        )}
        <Card>
          <CustomDatagrid
            customRowClickConfig={props.customRowClickConfig}
            disableRowClick={props.disableRowClick}
          >
            {props.children}
          </CustomDatagrid>
        </Card>
        {!props.hidePaginationControl && <CustomPagination />}
      </ListBase>
    </Authorize>
  );
}

export default CustomList;
