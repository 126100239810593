import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

function ApiKeyEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.ApiKey}>
      <TextInput label="Description" source="description" required />
    </CustomEdit>
  );
}

export default ApiKeyEdit;
