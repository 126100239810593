import { storage } from "../storage";
import { AdminUserRole } from "../types";

// For a few actions, for the sake of simplicity our permissions are setup
// in a way where even though backend allows for a wider set of roles to
// perform the action, our admin frontend adds constraints & lets through
// a stricter set of roles.
//
// Here is a good example for such a case:
//   - `account_manager`, `finance_operator` & `support` roles can edit accounts
//   - even though `primary_region` is a field on accounts, we only allow super
//     & support roles to edit those fields (only enforced on the FE)
//
// Notes:
//   - we should use this sparingly to keep our access control simple
//   - this is only effective to punch through the security on the frontend.
//     Ultimately whether this action succeeds or not depends on the checks
//     we have in the backend

const ROLES_FOR_ACCOUNT_PRIMARY_REGION_CHANGE = new Set<AdminUserRole>();
ROLES_FOR_ACCOUNT_PRIMARY_REGION_CHANGE.add(AdminUserRole.Super);
ROLES_FOR_ACCOUNT_PRIMARY_REGION_CHANGE.add(AdminUserRole.Support);

function isCurrentAdminUserOneOf(roles: Set<AdminUserRole>): boolean {
  const currentUserRoles = storage.loadAuthInfo()?.roles;
  return currentUserRoles != null && currentUserRoles.some((r) => roles.has(r));
}

const userAllowances = {
  canAdminUserChangeAccountPrimaryRegion: (): boolean => {
    return isCurrentAdminUserOneOf(ROLES_FOR_ACCOUNT_PRIMARY_REGION_CHANGE);
  },
};

export default userAllowances;
