// Enum that lists all the different kinds of data we deal with.
// These could correspond to:
//    - variants in our `Resource` enum in a region agnostic manner
//    - front end only data pages (ex: iOS release status)
//    - front end only resources for permission modelling (ex: consolidated projects)
//
// When adding a new variant here, please update the permissions
// defined in `Permissions.ts` to make sure the required roles are
// allowed to perform the required actions
enum DataKind {
  Account = "account",
  AccountOverageNotification = "account_overage_notification",
  AccountUserCount = "account_user_count",
  ActiveUserCount = "active_user_count",
  AdminRoleMembership = "admin_role_membership",
  AdminUser = "admin_user",
  ApiKey = "api_key",
  AuditLogProject = "audit_log_project",
  BoxToken = "box_token",
  CanceledEnterpriseOwner = "canceled_enterprise_owner",
  CanceledInAppPurchaseUser = "canceled_in_app_purchase_user",
  CanceledStripeOwner = "canceled_stripe_owner",
  Charge = "charge",
  Company = "company",
  ConfigVariable = "config_variable",
  ConsolidatedProject = "consolidated_project",
  ConsolidatedProjectUsers = "consolidated_project_users",
  DemoProjectTemplate = "demo_project_template",
  DemoProjectUser = "demo_project_user",
  DropboxToken = "dropbox_token",
  EnterprisePlan = "enterprise_plan",
  EnterprisePlanInvoice = "enterprise_plan_invoice",
  FeatureFlag = "feature_flag",
  InAppPurchase = "in_app_purchase",
  IosReleaseStatus = "ios_release_status",
  JobInfo = "job_info",
  ManualWorker = "manual_worker",
  MicrosoftToken = "microsoft_token",
  PayingEnterpriseOwner = "paying_enterprise_owner",
  PayingInAppPurchaseUser = "paying_in_app_purchase_user",
  PayingStripeOwner = "paying_stripe_owner",
  Project = "project",
  ProjectsUser = "projects_user",
  Sidekiq = "sidekiq",
  SsoInfo = "sso_info",
  StressTestingProject = "stress_testing_project",
  StripeSubscription = "stripe_subscription",
  SyncError = "sync_error",
  SystemStatus = "system_status",
  User = "user",
}

export { DataKind };
