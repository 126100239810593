const datetime = {
  /**
   * Given the current UTC date and time, return an instance
   * of `Date` representing the end of the day in one year.
   *
   * Please note that we define "one year" as today's date in
   * one year minus one day. Therefore, if today is 8/22/23,
   * then one year later would be 8/21/24.
   */
  getEndOfDayInOneYearUTC: () => {
    const endOfDayInOneYear = new Date();

    endOfDayInOneYear.setUTCHours(23, 59, 0, 0);
    endOfDayInOneYear.setUTCFullYear(endOfDayInOneYear.getUTCFullYear() + 1);
    endOfDayInOneYear.setUTCDate(endOfDayInOneYear.getUTCDate() - 1);

    return endOfDayInOneYear;
  },

  /**
   * Given the current UTC date and time, return an instance
   * of `Date` representing the start of the day.
   */
  getStartOfDayUTC: () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    return startOfDay;
  },
};

export default datetime;
