import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomCreate } from "../custom";

function DemoProjectTemplateCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.DemoProjectTemplate}>
      <TextInput label="Project Id" source="demo_project_id" required />
      <TextInput label="Demo Project Template Name" source="name" />
    </CustomCreate>
  );
}

export default DemoProjectTemplateCreate;
