import TextField from "@mui/material/TextField";
import { FormEvent } from "react";
import { Button, useDataProvider } from "react-admin";

import { useForm, useMutation } from "../../../hooks";
import { CustomDataProvider, Language, ModalProps } from "../../../types";
import { ActionButtonsBar, Form, LanguageSelectInput, Modal } from "..";

interface FormState {
  email: string;
  language: Language;
}

function InviteUserToFieldwireModal(props: ModalProps) {
  const defaultFormState: FormState = {
    email: "",
    language: Language.EN,
  };

  const { form, handleChangeForm, handleClearForm } = useForm(defaultFormState);

  const dataProvider = useDataProvider<CustomDataProvider>();
  const { mutate } = useMutation(
    (form: FormState) =>
      dataProvider.inviteUserToFieldwire(form.email, form.language),
    ["inviteUserToFieldwire", form],
    "User has been successfully invited! ✅"
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();
    props.handleClose();
    mutate(form);
  };

  return (
    <Modal open={props.modalOpen} title="New Invitation">
      <Form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          onChange={handleChangeForm}
          value={form.email}
          required
        />
        <LanguageSelectInput
          label="Language"
          name="language"
          onChange={handleChangeForm}
          value={form.language}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!form.email}
            label="Send Invitation"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default InviteUserToFieldwireModal;
