import { Pagination, useListContext } from "react-admin";

function CustomPagination() {
  const { data } = useListContext();
  return (
    // Setting `rowsPerPageOptions` to `[]` hides a dropdown that allows users to change page size.
    !data?.length ? null : <Pagination rowsPerPageOptions={[]} />
  );
}

export default CustomPagination;
