import ApartmentIcon from "@mui/icons-material/Apartment";
import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HandymanIcon from "@mui/icons-material/Handyman";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import TerminalIcon from "@mui/icons-material/Terminal";
import WorkIcon from "@mui/icons-material/Work";
import { Menu, MenuClasses, MenuProps } from "react-admin";

import { Action, DataKind, Resource } from "../../../types";
import {
  PATH_DASHBOARD,
  PATH_IOS_RELEASE_STATUS,
  PATH_SIDEKIQ,
  PATH_SYSTEM_STATUS,
  routes,
} from "../../../utils/Routes";
import {
  TopLevelMultiLevelMenuItem,
  TopLevelMultiRegionMenuItem,
  TopLevelSingleRegionMenuItem,
} from ".";

function CustomSideMenu(props: MenuProps) {
  return (
    <Menu
      {...props}
      sx={{
        [`&.${MenuClasses.closed}`]: {
          padding: "0",
        },
        [`&.${MenuClasses.open}`]: {
          padding: "0",
        },
      }}
    >
      {/*
        We use the ability to read active user counts as a proxy for viewing
        the dashboard page as everything on the page requires, at least, the
        ability to read active user counts. What does at least mean? We must
        differentiate between users who can and can't view the financial data
        portion of the active users counts table. This mirrors our BE which
        makes such a distinction. In order to accomplish this on the FE, we
        give such admin users both the ability to `Read` and `Update` active
        user count data (even though such data is never updated by an admin
        user). Therefore, reading active user counts is always required.
       */}
      <TopLevelSingleRegionMenuItem
        action={Action.Read}
        icon={<BarChartIcon />}
        primaryText="Dashboard"
        dataKind={DataKind.ActiveUserCount}
        tag="single_region"
        to={PATH_DASHBOARD}
      />

      <TopLevelSingleRegionMenuItem
        action={Action.Read}
        icon={<WorkIcon />}
        primaryText="Accounts"
        dataKind={DataKind.Account}
        tag="single_region"
        to={Resource.Account}
      />
      <TopLevelSingleRegionMenuItem
        action={Action.Read}
        icon={<ApartmentIcon />}
        primaryText="Companies"
        dataKind={DataKind.Company}
        tag="single_region"
        to={Resource.Company}
      />
      <TopLevelSingleRegionMenuItem
        action={Action.Read}
        icon={<ReceiptIcon />}
        primaryText="Enterprise Plans"
        dataKind={DataKind.EnterprisePlan}
        tag="single_region"
        to={Resource.EnterprisePlan}
      />
      <TopLevelMultiRegionMenuItem
        action={Action.Read}
        dataKind={DataKind.Project}
        icon={<HandymanIcon />}
        tag="multi_region"
        pathForEu={routes.computeListFor(Resource.EuProject)}
        pathForUs={routes.computeListFor(Resource.UsProject)}
        primaryText="Projects"
      />
      <TopLevelSingleRegionMenuItem
        action={Action.Read}
        icon={<PeopleIcon />}
        primaryText="Users"
        dataKind={DataKind.User}
        tag="single_region"
        to={Resource.User}
      />
      <TopLevelMultiLevelMenuItem
        icon={<PaidIcon />}
        primaryText="Payments"
        children={[
          {
            action: Action.Read,
            primaryText: "Charges",
            dataKind: DataKind.Charge,
            tag: "single_region",
            to: Resource.Charge,
          },
          {
            action: Action.Read,
            primaryText: "Enterprise Plan Invoices",
            dataKind: DataKind.EnterprisePlanInvoice,
            tag: "single_region",
            to: Resource.EnterprisePlanInvoice,
          },
          {
            action: Action.Read,
            primaryText: "In App Purchases",
            dataKind: DataKind.InAppPurchase,
            tag: "single_region",
            to: Resource.InAppPurchase,
          },
        ]}
      />
      <TopLevelMultiLevelMenuItem
        icon={<CreditCardIcon />}
        primaryText="Paying Users"
        children={[
          {
            action: Action.Read,
            primaryText: "Stripe",
            dataKind: DataKind.PayingStripeOwner,
            tag: "single_region",
            to: Resource.PayingStripeOwner,
          },
          {
            action: Action.Read,
            primaryText: "Stripe Canceled",
            dataKind: DataKind.CanceledStripeOwner,
            tag: "single_region",
            to: Resource.CanceledStripeOwner,
          },
          {
            action: Action.Read,
            primaryText: "Enterprise",
            dataKind: DataKind.PayingEnterpriseOwner,
            tag: "single_region",
            to: Resource.PayingEnterpriseOwner,
          },
          {
            action: Action.Read,
            primaryText: "Enterprise Canceled",
            dataKind: DataKind.CanceledEnterpriseOwner,
            tag: "single_region",
            to: Resource.CanceledEnterpriseOwner,
          },
          {
            action: Action.Read,
            primaryText: "In App",
            dataKind: DataKind.PayingInAppPurchaseUser,
            tag: "single_region",
            to: Resource.PayingInAppPurchaseUser,
          },
          {
            action: Action.Read,
            primaryText: "In App Canceled",
            dataKind: DataKind.CanceledInAppPurchaseUser,
            tag: "single_region",
            to: Resource.CanceledInAppPurchaseUser,
          },
        ]}
      />
      <TopLevelMultiLevelMenuItem
        icon={<SmartDisplayIcon />}
        primaryText="Demo Projects"
        children={[
          {
            action: Action.Read,
            dataKind: DataKind.DemoProjectTemplate,
            tag: "multi_region",
            pathForEu: routes.computeListFor(Resource.EuDemoProjectTemplate),
            pathForUs: routes.computeListFor(Resource.UsDemoProjectTemplate),
            primaryText: "Templates",
          },
          {
            action: Action.Read,
            dataKind: DataKind.DemoProjectUser,
            tag: "multi_region",
            pathForEu: routes.computeListFor(Resource.EuDemoProjectUser),
            pathForUs: routes.computeListFor(Resource.UsDemoProjectUser),
            primaryText: "Users",
          },
        ]}
      />
      <TopLevelMultiLevelMenuItem
        icon={<TerminalIcon />}
        primaryText="Advanced"
        children={[
          {
            action: Action.Read,
            primaryText: "Admin Users",
            dataKind: DataKind.AdminUser,
            tag: "single_region",
            to: Resource.AdminUser,
          },
          {
            action: Action.Read,
            primaryText: "Admin Roles",
            dataKind: DataKind.AdminRoleMembership,
            tag: "single_region",
            to: Resource.AdminRoleMembership,
          },
          {
            action: Action.Read,
            primaryText: "Api Keys",
            dataKind: DataKind.ApiKey,
            tag: "single_region",
            to: Resource.ApiKey,
          },
          {
            action: Action.Read,
            dataKind: DataKind.ConfigVariable,
            tag: "multi_region",
            pathForEu: routes.computeListFor(Resource.EuConfigVariable),
            pathForUs: routes.computeListFor(Resource.UsConfigVariable),
            primaryText: "Config Vars",
          },
          {
            action: Action.Read,
            primaryText: "iOS Release Status",
            dataKind: DataKind.IosReleaseStatus,
            tag: "single_region",
            to: PATH_IOS_RELEASE_STATUS,
          },
          {
            action: Action.Read,
            primaryText: "Job Infos",
            dataKind: DataKind.JobInfo,
            tag: "single_region",
            to: Resource.JobInfo,
          },
          {
            action: Action.Create,
            dataKind: DataKind.ManualWorker,
            tag: "multi_region",
            pathForEu: routes.computeCreateFor(Resource.EuManualWorker),
            pathForUs: routes.computeCreateFor(Resource.UsManualWorker),
            primaryText: "Manual Workers",
          },
          {
            action: Action.Create,
            dataKind: DataKind.StressTestingProject,
            tag: "multi_region",
            pathForEu: routes.computeCreateFor(Resource.EuStressTestingProject),
            pathForUs: routes.computeCreateFor(Resource.UsStressTestingProject),
            primaryText: "Project Scaffold",
          },
          {
            action: Action.Read,
            primaryText: "Sidekiq",
            dataKind: DataKind.Sidekiq,
            tag: "single_region",
            to: PATH_SIDEKIQ,
          },
          {
            action: Action.Read,
            dataKind: DataKind.FeatureFlag,
            tag: "multi_region",
            pathForEu: routes.computeListFor(Resource.EuFeatureFlag),
            pathForUs: routes.computeListFor(Resource.UsFeatureFlag),
            primaryText: "System Config",
          },
          {
            action: Action.Read,
            primaryText: "System Status",
            dataKind: DataKind.SystemStatus,
            tag: "single_region",
            to: PATH_SYSTEM_STATUS,
          },
          {
            action: Action.Read,
            primaryText: "SSO Infos",
            dataKind: DataKind.SsoInfo,
            tag: "single_region",
            to: Resource.SsoInfo,
          },
          {
            action: Action.Read,
            dataKind: DataKind.SyncError,
            tag: "multi_region",
            pathForEu: routes.computeListFor(Resource.EuSyncError),
            pathForUs: routes.computeListFor(Resource.UsSyncError),
            primaryText: "Sync Errors",
          },
        ]}
      />
    </Menu>
  );
}

export default CustomSideMenu;
