enum ProjectRole {
  Member = "member",
  Follower = "follower",
  Admin = "admin",
}

enum SalesAttribution {
  CompleteProcess = 0,
  Negotiated = 1,
  Closed = 2,
}

export { ProjectRole, SalesAttribution };
