import { Link } from "@mui/material";
import { FieldProps, TextField, useRecordContext } from "react-admin";

import { Resource } from "../../../types";
import { routes } from "../../../utils/Routes";
import { componentUtils } from "../../component_utils";

interface LinkablePlanInfoFieldProps extends FieldProps {
  // Enforce that label is defined.
  label: string;
}

function LinkablePlanInfoField(_props: LinkablePlanInfoFieldProps) {
  const record = useRecordContext();

  // If we don't have access to the record yet, or the record
  // doesn't contain the `plan_info` field, let's just return
  // null.
  if (!record.plan_info) {
    return null;
  }

  // If `plan_info` corresponds to an enterprise plan and `enterprise_plan_id`
  // is non-null, let's create a link to the show page for that enterprise plan.
  if (
    componentUtils.isPlanInfoEnterprisePlan(record.plan_info) &&
    record.enterprise_plan_id
  ) {
    return (
      <Link
        href={routes.computeShowFor(
          Resource.EnterprisePlan,
          record.enterprise_plan_id
        )}
        // If this field is used within a Datagrid, the click event will cause
        // the Datagrid to add the route for parent row to the router history.
        // That is not desired behavior.
        onClick={(e) => e.stopPropagation()}
      >
        {record.plan_info}
      </Link>
    );
  } else {
    return <TextField source="plan_info" />;
  }
}

export default LinkablePlanInfoField;
