import {
  NullableBooleanInput,
  SelectInput,
  TextInput,
  useRecordContext,
} from "react-admin";

import { userAllowances } from "../../abilities";
import { AccountResponse, DataKind, Market, Region } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomEdit, CustomNumberInput, LabelWithInfoTooltip } from "../custom";

const marketChoices =
  componentUtils.generateUpperCaseSelectChoicesFromEnum(Market);
const primaryRegionChoices =
  componentUtils.generateUpperCaseSelectChoicesFromEnum(Region);

function IsEuRegionEnabled() {
  const accountRecord = useRecordContext<AccountResponse>();
  if (accountRecord) {
    return (
      <NullableBooleanInput
        label={
          <LabelWithInfoTooltip
            labelText="Is EU Region Enabled"
            tooltipText="Once an account gets access to a region, it can't be reverted. So, this flag can't be disabled once enabled"
          />
        }
        source="is_eu_region_enabled"
        required
        disabled={accountRecord.is_eu_region_enabled}
      />
    );
  } else {
    return null;
  }
}

function AccountEdit() {
  // A FE only guard to prevent misuse. This is OK since we don't
  // expect our internal admin users to try to skirt around these
  // FE guards.
  const canChangePrimaryRegion =
    userAllowances.canAdminUserChangeAccountPrimaryRegion();

  const primaryRegionTooltip = canChangePrimaryRegion
    ? "Please make sure you are aware of and understand the regionalization project before changing this."
    : "Please ping in #support Slack channel if you would like to change the primary region of an account.";

  const transform = (data: AccountResponse) => ({
    ...data,
    hilti_customer_id: data.hilti_customer_id?.trim(),
    hilti_contact_id: data.hilti_contact_id?.trim(),
  });

  return (
    <CustomEdit checkPermissionFor={DataKind.Account} transform={transform}>
      <CustomNumberInput label="Owner User Id" source="owner_user_id" />
      <CustomNumberInput label="Company ID" source="company_id" />
      <TextInput label="Invoice Email" source="invoice_email" />
      <SelectInput
        source="primary_region"
        choices={primaryRegionChoices}
        required
        disabled={!canChangePrimaryRegion}
        label={
          <LabelWithInfoTooltip
            labelText="Primary Region"
            tooltipText={primaryRegionTooltip}
          />
        }
      />
      <NullableBooleanInput label="Is Churn" source="is_churn" required />
      <NullableBooleanInput
        label="Is Blockable"
        source="is_blockable"
        required
      />
      <CustomNumberInput label="Max Projects" source="max_projects" required />
      <CustomNumberInput label="Max Users" source="max_users" required />
      <CustomNumberInput label="Max Sheets" source="max_sheets" required />
      <NullableBooleanInput
        label="Is API Access Enabled"
        source="is_api_access_enabled"
      />
      <NullableBooleanInput
        label="Access Control Export URL Enabled"
        source="access_control_export_url_enabled"
      />
      <NullableBooleanInput
        label="Has Tasks Toggle"
        source="has_tasks_toggle"
      />
      <NullableBooleanInput
        label="Has Photo Attachments Toggle"
        source="has_photo_attachments_toggle"
      />
      <NullableBooleanInput
        label="Is Auto Charge Overage"
        source="is_auto_charge_overage"
        required
      />
      <NullableBooleanInput label="Is User Limit" source="is_user_limit" />
      <NullableBooleanInput
        label="Is 3D BIM Enabled"
        source="is_3d_bim_enabled"
      />
      <IsEuRegionEnabled />
      <NullableBooleanInput
        label="Are Forms Enabled"
        source="is_forms_enabled"
      />
      <NullableBooleanInput
        label="Are Specifications Enabled"
        source="has_specs"
        required
      />
      <NullableBooleanInput
        label="Is Plan Text Search Enabled"
        source="is_plan_text_search_enabled"
      />
      {/*
        Why the mismatch between label and source? It's because the is_pm_enabled
        flag at the DB level really just controls whether or not RFIs are enabled.
        It's better to be specific with the label so the human using the admin site
        understands what the toggle is referring to. Especially since PM could refer
        to any one of a number of modules that we have!

        Also, see how the old admin site uses the same labeling scheme:
        https://github.com/Fieldwire/fieldwire_api/blob/develop/lib/admin/account.rb#L159
        https://github.com/Fieldwire/fieldwire_api/blob/develop/lib/admin/account.rb#L341
      */}
      <NullableBooleanInput label="Are RFIs Enabled" source="is_pm_enabled" />
      <NullableBooleanInput
        label="Are Submittals Enabled"
        source="is_submittals_enabled"
      />
      <NullableBooleanInput
        label="Are Change Orders Enabled"
        source="is_change_orders_enabled"
        required
      />
      <NullableBooleanInput
        label="Is Budget Enabled"
        source="is_budget_enabled"
        required
      />
      <TextInput label="Customer ID" source="customer_id" />
      <TextInput
        label="Hilti Customer Id (For Hilti Contracts Only)"
        source="hilti_customer_id"
      />
      <TextInput
        label="Hilti Contact Id (For Hilti Contracts Only)"
        source="hilti_contact_id"
      />
      <SelectInput label="Market" source="market" choices={marketChoices} />
      <TextInput label="Invoice Company Address" source="company_address" />
      <NullableBooleanInput
        label="Daily Report Active"
        source="daily_report_active"
        required
      />
      <NullableBooleanInput label="Has Workato" source="has_workato" required />
    </CustomEdit>
  );
}

export default AccountEdit;
