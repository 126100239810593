import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, StackedAreaChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function SignupsWithPlansBySourceChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      30
    );

    return (
      <ChartContainer title="Signups with Plans by Source">
        <StackedAreaChart
          labels={processedData.map(
            (d: ActiveUserCountsForMarketingResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_from_android_unknown_source
              ),
              label: "Android - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_ipad_unknown_source
              ),
              label: "iPad - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_ipad_paid_source
              ),
              label: "iPad - Paid",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_iphone_unknown_source
              ),
              label: "iPhone - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_iphone_paid_source
              ),
              label: "iPhone - Paid",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_web_unknown_source
              ),
              label: "Web - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.signups_with_floorplan_web_paid_source
              ),
              label: "Web - Paid",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default SignupsWithPlansBySourceChart;
