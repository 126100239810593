import qs from "qs";
import { SortPayload, UpdateParams } from "react-admin";

import { HttpClientError } from "../../types";

const apiUtils = {
  /**
   * Map an object of filters to an array of individual filter objects.
   *
   * ```
   *  Input: { a: b, c: d }
   *  Output: [ { a: b }, { c: d } ]
   * ```
   */
  formatFilters: (filters: any) => {
    return Object.keys(filters).map((key: string) => ({
      [key]: filters[key],
    }));
  },

  /**
   * Map a `SortPayload` object to an array with that single sort object.
   *
   * ```
   *  Input: { field: name, order: "ASC" }
   *  Output: [ { name: "asc" } ]
   * ```
   *
   * Note that the `order` is made lower-case in the output.
   */
  formatSorts: (sorts: SortPayload) => {
    return [{ [sorts.field]: sorts.order.toLowerCase() }];
  },

  /**
   * Return a copy of `data` with "empty" key-value pairs removed.
   * Empty key-values pairs consist of those pairs whose values are
   * `undefined`, `null` or `""`.
   *
   * ```
   *  Input: { account_id: 1, company_domain: "", name: "Fieldwire" }
   *  Output: { account_id: 1, name: "Fieldwire" } }
   * ```
   */
  generateDataForCreate: (data: { [key: string]: any }) => {
    const dataWithNullEmptyVals = apiUtils.transformEmptyValuesToNull(data);

    const dataForCreate: any = {};
    for (let key of Object.keys(dataWithNullEmptyVals)) {
      const val = dataWithNullEmptyVals[key];
      if (val !== null) {
        dataForCreate[key] = val;
      }
    }

    return dataForCreate;
  },

  /**
   * Return a copy of `UpdateParams` data with unchanged key-value pairs removed.
   *
   * ```
   *  Input: { id: 1, data: { name: "User Name" }, previousData: { name: "Test Name" } }
   *  Output: { name: "User Name" }
   * ```
   *
   * This is a shallow difference because we expect the values we are comparing in `data` and
   * `previousData` to be primitive types. If any values take on a non-primitive type, `Object`
   * for example, the shallow difference will behave unexpectedly.
   */
  generateDataForUpdate: (params: UpdateParams) => {
    const dataWithNullEmptyVals = apiUtils.transformEmptyValuesToNull(
      params.data
    );

    const dataForUpdate: any = {};
    for (let key of Object.keys(dataWithNullEmptyVals)) {
      const keyInPreviousData = key in params.previousData;
      const newVal = dataWithNullEmptyVals[key];

      // There are two situations in which we would want to add a key-value
      // pair to `dataForUpdate`. The first is when the value in `data` does
      // not match the value in `previousData`. For example:
      //
      // previousData: { a: "Foo", ... }
      // data:         { a: "Bar", ... }
      //
      // previousData: { a: null, ... }
      // data:         { a: "Bar", ... }
      //
      // previousData: { a: "Foo", ... }
      // data:         { a: null, ... }
      //
      // The second situation  is when a non-null key-value pair exists in `data`
      // that doesn't exist in `previousData`. A canonical example of this situation
      // is the password field. We don't fetch the encrypted password and show it on
      // the edit page. Therefore, password will never be in `previousData`.
      //
      // previousData: { ... }
      // data:         { password: "1234", ... }
      if (
        (keyInPreviousData && newVal !== params.previousData[key]) ||
        (!keyInPreviousData && newVal !== null)
      ) {
        dataForUpdate[key] = newVal;
      }
    }

    return dataForUpdate;
  },

  /**
   * Return an error message generated from an `HttpClientError` object.
   *
   * Note that we attempt to access the more specific error message(s) if
   * they exist, otherwise we use the highest level error message.
   *
   * For example, a more specific error message might be ⬇️
   * "invalid email or password"
   *
   * while a high level error message might be ⬇️
   * "Request failed with status code 422".
   */
  generateErrorMessage: (err: HttpClientError): string => {
    const message =
      err.response?.data?.errors
        ?.map((error) => error.message)
        ?.filter((message) => message !== null || message !== undefined)
        ?.join("\n") ?? err.message;

    return message;
  },

  /**
   * Stringify query parameters using array formatting and value
   * encoding. This is to be inline with what our BE expects.
   *
   * ```
   *  Input: { filters: [ { owner_email_like: '@building.com'} ], page: 1, per_page: 50, sorts: [ {id: 'asc'} ] }
   *  Output: "filters[0][owner_email_like]=%40building.com&page=1&per_page=50&sorts[0][id]=asc"
   * ```
   */
  queryParametersStringify: (params: any) => {
    return qs.stringify(params, {
      encodeValuesOnly: true,
    });
  },

  /**
   * Return a copy of `data` with `undefined` or `""` values set to `null`.
   *
   * ```
   *  Input: { a: "", b: undefined, c: null, d: 4, e: "foo" }
   *  Output: { a: null, b: null, c: null, d: 4, e: "foo" }
   * ```
   */
  transformEmptyValuesToNull: (data: { [key: string]: any }) => {
    // Make a copy of `data`.
    const transformed = Object.assign({}, data);

    // For each key value pair in `data`, if the value
    // is `undefined` or `""`, set the value to `null`.
    for (let key of Object.keys(data)) {
      if (data[key] === undefined || data[key] === "") {
        transformed[key] = null;
      }
    }

    return transformed;
  },
};

export default apiUtils;
