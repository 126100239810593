import Box from "@mui/material/Box";

import { Action, DataKind } from "../../../types";
import { Authorize, TitleRow } from "../../custom";
import {
  ActiveUsersChart,
  CrInvitesSevenDayTrailingChart,
  CrUsersSignupsChart,
  CrUsersSignupsSevenDayTrailingChart,
  InvitesSevenDayTrailingChart,
  MrrChart,
  PayingUsersChart,
  ProjectedEnterprisePlanOveragesMrrChart,
  ProjectsChart,
  ProofOfConceptMrrChart,
  SignupsByCountryChart,
  SignupsBySourceChart,
  SignupsEmailVerificationChart,
  SignupsWithPlansBySourceChart,
} from "./charts";

function Dashboard() {
  return (
    <Authorize action={Action.Read} dataKind={DataKind.ActiveUserCount}>
      <TitleRow title="Dashboard" />
      {/* Grid layout with three charts per line and 16px gaps. */}
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <SignupsEmailVerificationChart />
        <CrUsersSignupsChart />
        <CrUsersSignupsSevenDayTrailingChart />
        <SignupsBySourceChart />
        <SignupsWithPlansBySourceChart />
        <SignupsByCountryChart />
        <InvitesSevenDayTrailingChart />
        <CrInvitesSevenDayTrailingChart />
        <ActiveUsersChart />
        {/*
          Note that an admin user will never actually update active user count
          data. This is simply a representation used to differentiate between
          admin users who can and can't view the financial data portion of the
          active users counts table. This mirrors our BE which makes such a
          distinction.
        */}
        <Authorize
          action={Action.Update}
          dataKind={DataKind.ActiveUserCount}
          disableUnauthorizedMessage
        >
          <PayingUsersChart />
          <MrrChart />
          <ProjectsChart />
          <ProofOfConceptMrrChart />
          <ProjectedEnterprisePlanOveragesMrrChart />
        </Authorize>
      </Box>
    </Authorize>
  );
}

export default Dashboard;
