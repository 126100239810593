import { TextInput, useNotify, ValidateForm } from "react-admin";
import { FieldValues } from "react-hook-form";

import { DataKind, Region } from "../../types";
import validators from "../../utils/Validators";
import { CustomCreate } from "../custom";

interface OnSuccessParams {
  id: string;
}

function ManualWorkerCreate(props: { region: Region }) {
  const notify = useNotify();

  const validateForm: ValidateForm = (values: FieldValues) => {
    return validators.validateJsonPayload(values.payload);
  };

  const onSuccess = (data: OnSuccessParams) => {
    notify(`Successfully enqueued, log id: ${data.id}`);
  };

  return (
    <CustomCreate
      checkPermissionFor={DataKind.ManualWorker}
      // We do not redirect
      redirect="#"
      validate={validateForm}
      mutationOptions={{ onSuccess }}
    >
      <TextInput
        label="Worker Name"
        source="worker_name"
        placeholder="i.e MyManualWorker"
        required
      />
      <TextInput label="JSON Payload" source="payload" />
    </CustomCreate>
  );
}

export default ManualWorkerCreate;
