import { Line } from "react-chartjs-2";

import { CHART_COLORS, stackedAreaChartOptions } from "../../config";
import { ChartProps, DataSet } from "../../types";

function StackedAreaChart(props: ChartProps) {
  return (
    <Line
      options={stackedAreaChartOptions}
      data={{
        labels: props.labels,
        datasets: props.datasets.map((dataset: DataSet, index: number) => {
          return {
            borderColor: CHART_COLORS[index],
            // `+ "80` effectively sets the opacity to 0.5.
            backgroundColor: CHART_COLORS[index] + "80",
            data: dataset.data,
            fill: "stack",
            label: dataset.label,
          };
        }),
      }}
    />
  );
}

export default StackedAreaChart;
