/**
 * The purpose of this CustomShow component is to reduce
 * boilerplate associated with the show pages and minimize
 * the chance of not including critical parts of the page
 * such as authorization and the actions toolbar.
 */
import { ReactElement } from "react";
import { ShowBase, useShowContext } from "react-admin";

import { Action, DataKind } from "../../../types";
import { Authorize, CustomActions, TitleRow } from "..";

interface CustomShowProps {
  checkPermissionFor: DataKind;
  children: any;
  displayDelete: boolean;
  displayEdit: boolean;
  fieldwireActions?: ReactElement;
}

function ShowTitleRow(props: { actions: any }) {
  const { defaultTitle } = useShowContext();

  if (defaultTitle) {
    return <TitleRow actions={props.actions} title={defaultTitle} />;
  } else {
    return null;
  }
}

function CustomShow(props: CustomShowProps) {
  return (
    <Authorize action={Action.Read} dataKind={props.checkPermissionFor}>
      <ShowBase>
        {/*
          We use the empty tags (also known as "Fragments") to group the
          `TitleRow` and `Card` components as `ShowBase` expects only a
          single child.
        */}
        <>
          <ShowTitleRow
            actions={
              <CustomActions
                checkPermissionFor={props.checkPermissionFor}
                displayCreate={false}
                displayDelete={props.displayDelete}
                displayEdit={props.displayEdit}
                displayShow={false}
                fieldwireActions={props.fieldwireActions}
              />
            }
          />
          {props.children}
        </>
      </ShowBase>
    </Authorize>
  );
}

export default CustomShow;
