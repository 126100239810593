/**
 * A wrapper around React Admin's `NumberInput` that ensures that the number
 * value in the input does not change when the current admin user scrolls
 * while the input is focused. Please see the following issue for more
 * information:
 *
 * https://github.com/mui/material-ui/issues/7960
 */

import { NumberInput, NumberInputProps } from "react-admin";

function CustomNumberInput(props: NumberInputProps) {
  return (
    <NumberInput
      {...props}
      // @ts-expect-error
      onWheel={(e) => e.target.blur()}
    />
  );
}

export default CustomNumberInput;
