import { TextField } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomReferenceField,
  CustomShow,
  ShowTable,
} from "../custom";

function ConfigVariableShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.ConfigVariable}
      displayDelete={true}
      displayEdit={true}
    >
      <ShowTable title="Config Variable Details">
        <TextField label="Id" source="id" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        <CustomReferenceField
          label="Last Editor Admin User"
          source="last_editor_admin_user_id"
          reference={Resource.AdminUser}
        >
          <TextField source="email" />
        </CustomReferenceField>
        <TextField label="Name" source="name" />
        <TextField label="Value" source="value" />
      </ShowTable>
    </CustomShow>
  );
}

export default ConfigVariableShow;
