/**
 * A custom hook that returns the following primitives for interacting with forms:
 *
 *  1) `form`: An object used to represent the internal state of the form. The
 *      internal state of the form refers to the current value of each of its
 *      form fields.
 *  2) `handleClearForm`: A function that sets the internal state of the form
 *      to its default state.
 *  3) `handleChangeForm`: A function used to alter the internal state of the
 *     form in response to user change events on the form. Examples of user
 *     change events include, but are not limited to: typing into a text input,
 *     selecting a field from a dropdown, etc.
 */

import { ChangeEvent, useState } from "react";

// Most forms in our codebase only use `TextField`s where `target.value` will
// give us the element's value. So, by default let's do that while giving the
// flexibility to the callsite to override this (ex: to get checked value of a
// `Checkbox`)
type ValueExtractor = (e: ChangeEvent<HTMLInputElement>) => any;
const stringValueExtractor: ValueExtractor = (e) => e.target.value;

function useForm<T>(
  defaultFormState: T,
  valueExtractor: ValueExtractor = stringValueExtractor
) {
  const [form, setForm] = useState(defaultFormState);

  const handleClearForm = () => {
    setForm(defaultFormState);
  };

  const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      ...{ [e.target.name]: valueExtractor(e) },
    });
  };

  return { form, handleClearForm, handleChangeForm };
}

export default useForm;
