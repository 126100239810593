/**
 * The purpose of this CustomEdit component is to reduce
 * boilerplate associated with the edit pages and minimize
 * the chance of not including critical parts of the page
 * such as authorization and the custom edit form which
 * only includes the save button.
 */
import { EditBase, useEditContext, ValidateForm } from "react-admin";

import { Action, DataKind } from "../../../types";
import {
  Authorize,
  CardForCreateOrEdit,
  CustomActions,
  CustomSaveButton,
  CustomSimpleFormForCreateOrEdit,
  CustomToolbar,
  TitleRow,
} from "..";

interface CustomEditProps {
  checkPermissionFor: DataKind;
  children: any;
  transform?: (data: any) => any;
  validate?: ValidateForm;
}

function EditTitleRow(props: { actions: any }) {
  const { defaultTitle } = useEditContext();

  if (defaultTitle) {
    return <TitleRow actions={props.actions} title={defaultTitle} />;
  } else {
    return null;
  }
}

function CustomEdit(props: CustomEditProps) {
  return (
    <Authorize action={Action.Update} dataKind={props.checkPermissionFor}>
      <EditBase
        redirect="show"
        // The default mutation mode is "undoable" which React Admin describes as follows:
        //
        //    "The mutation is applied locally and the side effects are executed immediately.
        //     Then a notification is shown with an undo button. If the user clicks on undo,
        //     the mutation is never sent to the dataProvider, and the page is refreshed.
        //     Otherwise, after a 5 seconds delay, the mutation is passed to the dataProvider.
        //     If the dataProvider returns successfully, nothing happens (as the mutation was
        //     already applied locally). If the dataProvider returns in error, the page is
        //     refreshed and an error notification is shown."
        //
        // We prefer the "pessimistic" mode because the mutation is passed to the dataProvider
        // right away, and if the mutation is performed successfully, the mutation is then applied
        // locally and side effects are executed. This ensures that the FE and BE are always in sync.
        mutationMode="pessimistic"
        transform={props.transform}
      >
        <EditTitleRow
          actions={
            <CustomActions
              checkPermissionFor={props.checkPermissionFor}
              displayCreate={false}
              displayDelete={false}
              displayEdit={false}
              displayShow={true}
            />
          }
        />
        <CardForCreateOrEdit>
          <CustomSimpleFormForCreateOrEdit
            validate={props.validate}
            toolbar={
              <CustomToolbar>
                <CustomSaveButton />
              </CustomToolbar>
            }
          >
            {props.children}
          </CustomSimpleFormForCreateOrEdit>
        </CardForCreateOrEdit>
      </EditBase>
    </Authorize>
  );
}

export default CustomEdit;
