import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function ActiveUsersChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      30
    );

    return (
      <ChartContainer title="Active Users">
        <LineChart
          labels={processedData.map(
            (d: ActiveUserCountsForMarketingResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) => d.last_day_total
              ),
              label: "Active",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) => d.last_3_days_total
              ),
              label: "Active 3 Day",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) => d.last_7_days_total
              ),
              label: "Active 7 Day",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default ActiveUsersChart;
