import { SelectInput } from "react-admin";

import { DataKind, JobInfoStatus } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomEdit } from "../custom";

const statusChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(JobInfoStatus);

function JobInfoEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.JobInfo}>
      <SelectInput
        label="Status"
        source="status"
        choices={statusChoices}
        required
      />
    </CustomEdit>
  );
}

export default JobInfoEdit;
