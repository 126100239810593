const validators = {
  validateDateRange(
    startDate: string,
    endDate: string
  ): Record<string, string> {
    const errors: Record<string, string> = {};

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      const startDay = Date.UTC(
        startDateObj.getUTCFullYear(),
        startDateObj.getUTCMonth(),
        startDateObj.getUTCDate()
      );
      const endDay = Date.UTC(
        endDateObj.getUTCFullYear(),
        endDateObj.getUTCMonth(),
        endDateObj.getUTCDate()
      );

      if (!isNaN(startDay) && !isNaN(endDay) && endDay <= startDay) {
        errors["end_at"] = "End date must be after the day of start date";
      }
    }

    return errors;
  },

  validateJsonPayload(payload: string): Record<string, string> {
    const errors: Record<string, string> = {};

    if (payload) {
      try {
        JSON.parse(payload);
      } catch {
        errors["payload"] = "Invalid JSON found";
      }
    }
    return errors;
  },
};

export default validators;
