/**
 * The purpose of this CustomReferenceManyField component is to
 * to provide consistent pagination logic, where applicable.
 */
import { ReferenceManyField } from "react-admin";

import { DEFAULT_SORT, PER_PAGE } from "../../../constants";
import { Resource, SortPayload } from "../../../types";
import { CustomPagination } from "..";

interface CustomReferenceManyFieldProps {
  children: any;
  reference: Resource;
  target: Resource;
  // Determines whether or not pagination controls are shown. This will
  // be true for most custom reference many fields. Currently, however,
  // we have a few such fields that do not support pagination (ex: account
  // table on user show page), and as such, we need to know when to hide
  // the controls.
  displayPagination: boolean;
  // We never actually display the label for the CustomReferenceManyField
  // component as the component occurs as a standalone table on show pages.
  // However, React Admin will still add an empty Label component, which
  // causes style issues for us. Therefore, to ensure that React Admin does
  // not do this, we force the CustomReferenceManyField to only take the label
  // prop as false.
  label: false;
  // React Admin uses a descending sort on id as its default for this field.
  // In case React Admin ever inadvertantly changes this on us, we enforce it
  // below. In some cases, however, we would like the default sort to not be
  // descending on id, which is why this prop exists. For example, the id of
  // projects is a UUID, so a descending sort on created_at would be more
  // appropriate.
  defaultSort?: SortPayload;
}

function CustomReferenceManyField(props: CustomReferenceManyFieldProps) {
  return (
    <ReferenceManyField
      label={props.label}
      pagination={props.displayPagination ? <CustomPagination /> : undefined}
      // It is OK if `perPage` is defined even if pagination is disabled for the
      // field. This is because the value is only passed to the data provider and
      // the data provider should disregard the value for endpoints that do not
      // support pagination.
      perPage={PER_PAGE}
      reference={props.reference}
      sort={props.defaultSort ?? DEFAULT_SORT}
      target={props.target}
    >
      {props.children}
    </ReferenceManyField>
  );
}

export default CustomReferenceManyField;
