import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomCreate } from "../custom";

function InAppPurchaseCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.InAppPurchase}>
      <TextInput label="App Store ID" source="app_store_id" required />
      <TextInput label="Description" source="description" required />
    </CustomCreate>
  );
}

export default InAppPurchaseCreate;
