import { NumberField, TextField, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const chargeFilters = [
  <TextInput label="Account ID" source="account_id_eq" alwaysOn />,
  <TextInput label="Premium Plan ID" source="premium_plan_id_eq" alwaysOn />,
  <TextInput
    label="Stripe Charge ID"
    source="stripe_charge_id_like"
    alwaysOn
  />,
];

function ChargeList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.Charge}
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={false}
      filters={chargeFilters}
    >
      <TextField label="Id" source="id" />
      <TextField
        label="Invoice S3 File Id"
        source="invoice_s3_file_id"
        sortable={false}
      />
      <TextField
        label="Stripe Plan Id"
        source="stripe_plan_id"
        sortable={false}
      />
      <TextField
        label="Premium Plan Id"
        source="premium_plan_id"
        sortable={false}
      />
      <TextField label="Premium Plan Type" source="premium_plan_type" />
      <TextField label="Stripe Charge Id" source="stripe_charge_id" />
      <TextField label="Category" source="category" />
      <TextField label="State" source="state" />
      <TextField label="Card Type" source="card_type" sortable={false} />
      <TextField label="Account ID" source="account_id" sortable={false} />
      <NumberField label="Last Four" source="last_four" sortable={false} />
      <NumberField label="Quantity" source="quantity" />
      <NumberField label="Covered Charged" source="covered_charged" />
      <NumberField label="Deductions" source="deductions" />
      <NumberField label="Overage Charged" source="overage_charged" />
      <NumberField label="Starting Balance" source="starting_balance" />
      <NumberField label="Total Charged" source="total_charged" />
      <CustomDateTimeField
        label="Charged Date"
        source="charged_date"
        sortable={false}
      />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default ChargeList;
