import TextField from "@mui/material/TextField";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider, useNotify } from "react-admin";

import {
  useForm,
  useGetStripeCreditCardToken,
  useMutation,
  useUpdateAccountInvoiceAddressInformation,
} from "../../../hooks";
import { requiresState } from "../../../iso";
import { getStripePromise } from "../../../stripe";
import {
  AccountResponse,
  CountryCodeRequiringState,
  CustomDataProvider,
  ModalProps,
  UpdateCreditCardRequest,
} from "../../../types";
import {
  ActionButtonsBar,
  CountrySelectField,
  Form,
  Modal,
  StateSelectField,
  StripeCreditCardTextField,
} from "..";

// Typically we order properties in alphabetical order. However, the
// form state here is more complex so we've ordered properties using
// the order in which their associated inputs appear in the update
// credit card modal.
interface FormState {
  invoice_country_iso2: string;
  company_address: string;
  invoice_city: string;
  invoice_state_iso2: string;
  invoice_postal_code: string;
}

interface UpdateCreditCardModalProps extends ModalProps {
  account: AccountResponse;
}

interface UpdateCreditCardModalContentProps
  extends UpdateCreditCardModalProps {}

interface UpdateCreditCardMutationVariables {
  accountId: Identifier;
  request: UpdateCreditCardRequest;
}

// The purpose of this layer is to 1) ensure that Stripe is lazily loaded
// and 2) wrap the downstream components in the Elements provider so that
// we can use Stripe and its hooks.
function UpdateCreditCardModal(props: UpdateCreditCardModalProps) {
  if (props.modalOpen) {
    return (
      <Elements stripe={getStripePromise()}>
        <UpdateCreditCardContentModal {...props} />
      </Elements>
    );
  } else {
    return null;
  }
}

function UpdateCreditCardContentModal(
  props: UpdateCreditCardModalContentProps
) {
  const defaultFormState: FormState = {
    invoice_country_iso2: props.account.invoice_country_iso2 ?? "",
    company_address: props.account.company_address ?? "",
    invoice_city: props.account.invoice_city ?? "",
    // While the following field is technically optional, we need to set
    // an initial value for it so React doesn't warn us about switching
    // between uncontrolled and controlled inputs.
    invoice_state_iso2: props.account.invoice_state_iso2 ?? "",
    invoice_postal_code: props.account.invoice_postal_code ?? "",
  };

  const { form, handleClearForm, handleChangeForm } = useForm(defaultFormState);

  // Ensure that Stripe is loaded and that the required fields are filled
  // out before the current admin user can perform the credit card update.
  //
  // Note that we don't check if the credit card info has been filled out.
  // This is because it is a bit trickier to validate. If the current admin
  // user does leave this field blank or incomplete we catch the error and
  // ask them to verify that they've entered the credit card information
  // correctly.
  const canUpdateCreditCard = () => {
    return (
      stripe &&
      form.invoice_country_iso2 &&
      form.company_address &&
      form.invoice_city &&
      form.invoice_postal_code &&
      // Ensure that either state isn't required, or if it is, that it is present.
      (!requiresState(form.invoice_country_iso2) || form.invoice_state_iso2)
    );
  };

  const dataProvider = useDataProvider<CustomDataProvider>();
  const { mutate } = useMutation(
    (variables: UpdateCreditCardMutationVariables) =>
      dataProvider.updateCreditCard(variables.accountId, variables.request),
    ["updateCreditCard", { accountId: props.account.id }],
    "Updated credit card successfully! ✅"
  );

  const notify = useNotify();
  const stripe = useStripe();
  const stripeElements = useElements();
  const getStripeCreditCardToken = useGetStripeCreditCardToken(
    stripe,
    stripeElements
  );
  const updateCreditCard = () => {
    getStripeCreditCardToken()
      .then((creditCardToken: string) => {
        mutate({
          accountId: props.account.id,
          request: {
            credit_card_token: creditCardToken,
          },
        });
      })
      .catch(() => {
        // TODO [Nice-to-Have] [REG-1480]: Extract static text into a single place.
        notify(
          "Something went wrong while preparing to update the credit card. Please \
          ensure that you've entered the credit card information correctly or \
          contact engineering."
        );
      })
      .finally(() => {
        // We call `handleClose` here because `react-stripe-js` requires that
        // the `CardElement` remain mounted in order to get the info contained
        // within it.
        props.handleClose();
      });
  };

  const updateAccount = useUpdateAccountInvoiceAddressInformation(
    props.account,
    {
      company_address: form.company_address,
      invoice_country_iso2: form.invoice_country_iso2,
      invoice_city: form.invoice_city,
      invoice_state_iso2: form.invoice_state_iso2,
      invoice_postal_code: form.invoice_postal_code,
    },
    updateCreditCard
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();

    // We normally call `handleClose` here. However, for this modal we can't do
    // that until we've fetched the credit card token from Stripe. This is b/c
    // `react-stripe-js` requires that the `CardElement` remain mounted in order
    // to get the information contained within it. Therefore, we call `handleClose`
    // above after the promise for fetching the credit card token from Stripe has
    // settled.

    // Update account and then update credit card upon successful account update.
    updateAccount();
  };

  return (
    <Modal open={props.modalOpen} title="Update Credit Card">
      <Form onSubmit={handleSubmit}>
        <StripeCreditCardTextField />
        <CountrySelectField
          label="Country"
          name="invoice_country_iso2"
          onChange={handleChangeForm}
          value={form.invoice_country_iso2}
          required
        />
        <TextField
          label="Company Address"
          name="company_address"
          onChange={handleChangeForm}
          value={form.company_address}
          required
        />
        <TextField
          label="City"
          name="invoice_city"
          onChange={handleChangeForm}
          value={form.invoice_city}
          required
        />
        {requiresState(form.invoice_country_iso2) && (
          <StateSelectField
            countryCode={form.invoice_country_iso2 as CountryCodeRequiringState}
            label="State"
            name="invoice_state_iso2"
            onChange={handleChangeForm}
            value={form.invoice_state_iso2}
            required
          />
        )}
        <TextField
          label="Postal Code"
          name="invoice_postal_code"
          onChange={handleChangeForm}
          value={form.invoice_postal_code}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!canUpdateCreditCard()}
            label="Update Card"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default UpdateCreditCardModal;
