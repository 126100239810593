import {
  Identifier,
  NumberField,
  TextField,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import {
  Action,
  ChargeResponse,
  CustomDataProvider,
  DataKind,
} from "../../types";
import { componentUtils } from "../component_utils";
import {
  ActionMenu,
  CustomDateTimeField,
  CustomShow,
  MutationActionMenuItem,
  ShowTable,
} from "../custom";

interface RetryChargeMutationFnVariables {
  id: Identifier;
}

function RetryCharge() {
  const record = useRecordContext<ChargeResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<void, RetryChargeMutationFnVariables> = (
      variables: RetryChargeMutationFnVariables
    ) => {
      return dataProvider.retryCharge(variables.id);
    };
    const mutationKey: MutationKey = ["retryCharge", record.id];
    const successMessage = "Retried charge successfully! ✅";

    return (
      <MutationActionMenuItem
        disabled={!componentUtils.isChargeRetriable(record)}
        label="Retry Charge"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

const fieldwireActions = [
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.Charge,
    element: <RetryCharge />,
  },
];

function ChargeShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.Charge}
      displayDelete={false}
      displayEdit={false}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      <ShowTable title="Charge Details">
        {/* NOTE: Using all of the same fields as list right now. */}
        <TextField label="Id" source="id" />
        <TextField label="Invoice S3 File Id" source="invoice_s3_file_id" />
        <TextField label="Stripe Plan Id" source="stripe_plan_id" />
        <TextField label="Premium Plan Id" source="premium_plan_id" />
        <TextField label="Premium Plan Type" source="premium_plan_type" />
        <TextField label="Stripe Charge Id" source="stripe_charge_id" />
        <TextField label="Category" source="category" />
        <TextField label="State" source="state" />
        <TextField label="Card Type" source="card_type" />
        <TextField label="Account ID" source="account_id" />
        <NumberField label="Last Four" source="last_four" />
        <NumberField label="Quantity" source="quantity" />
        <NumberField label="Covered Charged" source="covered_charged" />
        <NumberField label="Deductions" source="deductions" />
        <NumberField label="Overage Charged" source="overage_charged" />
        <NumberField label="Starting Balance" source="starting_balance" />
        <NumberField label="Total Charged" source="total_charged" />
        <CustomDateTimeField label="Charged Date" source="charged_date" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default ChargeShow;
