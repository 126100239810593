/**
 * The `ShowTable` serves as a wrapper component for a standalone
 * table on a resource's show page. The `ShowTable` adds a title
 * for the table and applies styles to the layout of the table.
 */

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  LabeledClasses,
  SimpleShowLayout,
  SimpleShowLayoutClasses,
} from "react-admin";

interface ShowTableProps {
  children: any;
  title: string;
  // This is useful for tables that are composed of a single reference
  // many field (ex: account users table on an account show page). This
  // is because the reference many field is considered a single row and
  // the pagination controls (where applicable) need to be placed under
  // the datagrid rather than next to it. Specifying the flex direction
  // as "column" allows us to do this.
  rowFlexDirection?: "column";
}

function ShowTable(props: ShowTableProps) {
  return (
    <Card sx={{ marginBottom: "16px" }}>
      <Box
        sx={{
          borderBottomColor: "fieldwire.gray.5",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          padding: "16px 24px",
        }}
      >
        <Typography
          component="span"
          sx={{ color: "fieldwire.gray.1", fontSize: "18px" }}
        >
          {props.title}
        </Typography>
      </Box>
      <SimpleShowLayout
        sx={{
          padding: "0",
          [`& .${SimpleShowLayoutClasses.row}`]: {
            borderBottomColor: "fieldwire.gray.5",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            display: "flex",
            flexDirection: `${props.rowFlexDirection ?? "row"}`,
            margin: "0",
            padding: "16px 24px",
            "&:last-child": {
              border: "none",
            },
            // Styles for field label.
            [`& .${LabeledClasses.label}`]: {
              fontSize: "14px",
              fontWeight: "700",
              margin: "0",
              // This value was chosen so that longer labels
              // don't overflow to a second line.
              width: "340px",
            },
            // Applies to field label and field value.
            span: {
              color: "fieldwire.gray.1",
            },
          },
        }}
      >
        {props.children}
      </SimpleShowLayout>
    </Card>
  );
}

export default ShowTable;
