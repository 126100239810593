import { TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceFieldLazy,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const configVariableFilters = [
  <TextInput label="Name" source="name_like" alwaysOn />,
  <TextInput label="Value" source="value_like" alwaysOn />,
  <TextInput
    label="Last Editor Admin User Id"
    source="last_editor_admin_user_id_eq"
    alwaysOn
  />,
];

function ConfigVariableList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.ConfigVariable}
      displayCreate={true}
      filters={configVariableFilters}
    >
      <TextField label="Id" source="id" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
      <CustomReferenceFieldLazy
        label="Last Editor Admin User"
        source="last_editor_admin_user_id"
        reference={Resource.AdminUser}
      />
      <TextField label="Name" source="name" />
      <TextField label="Value" source="value" />
    </CustomList>
  );
}

export default ConfigVariableList;
