enum Resource {
  // Represents a DB table or combination of DB tables in super service
  Account = "accounts",
  AccountOverageNotification = "account_overage_notifications",
  AccountUserCount = "account_user_counts",
  AdminRoleMembership = "admin_role_memberships",
  AdminUser = "admin_users",
  ApiKey = "api_keys",
  CanceledEnterpriseOwner = "canceled_enterprise_users",
  CanceledInAppPurchaseUser = "canceled_in_app_purchase_users",
  CanceledStripeOwner = "canceled_stripe_users",
  Charge = "charges",
  Company = "companies",
  EnterprisePlan = "enterprise_plans",
  EnterprisePlanInvoice = "enterprise_plan_invoices",
  InAppPurchase = "in_app_purchases",
  JobInfo = "job_infos",
  PayingEnterpriseOwner = "paying_enterprise_users",
  PayingInAppPurchaseUser = "paying_in_app_purchase_users",
  PayingStripeOwner = "paying_stripe_users",
  SsoInfo = "sso_infos",
  StripeSubscription = "stripe_subscriptions",
  User = "users",

  // Represents a DB table or combination of DB tables in EU regional services
  EuBoxToken = "eu/box_tokens",
  EuConfigVariable = "eu/config_variables",
  EuDemoProjectTemplate = "eu/demo_project_templates",
  EuDemoProjectUser = "eu/demo_project_users",
  EuDropboxToken = "eu/dropbox_tokens",
  EuFeatureFlag = "eu/feature_flags",
  EuManualWorker = "eu/manual_workers",
  EuMicrosoftToken = "eu/microsoft_tokens",
  EuProject = "eu/projects",
  EuProjectsUser = "eu/projects_users",
  EuStressTestingProject = "eu/stress_testing/generate_project",
  EuSyncError = "eu/sync_errors",

  // Audit log will be eventually added to EU too but the infra isn't
  // setup yet. Still, leaving it here to document the reason for it
  // not being available right now
  //
  // EuAuditLogProject = "eu/audit_logs/projects",

  // Represents a DB table or combination of DB tables valid only
  // in US regional service (reason - not all analytics run in EU)
  UsActiveUserCount = "us/active_user_counts",

  // Represents a DB table or combination of DB tables in US regional services
  UsAuditLogProject = "us/audit_logs/projects",
  UsBoxToken = "us/box_tokens",
  UsConfigVariable = "us/config_variables",
  UsDemoProjectTemplate = "us/demo_project_templates",
  UsDemoProjectUser = "us/demo_project_users",
  UsDropboxToken = "us/dropbox_tokens",
  UsFeatureFlag = "us/feature_flags",
  UsManualWorker = "us/manual_workers",
  UsMicrosoftToken = "us/microsoft_tokens",
  UsProject = "us/projects",
  UsProjectsUser = "us/projects_users",
  UsStressTestingProject = "us/stress_testing/generate_project",
  UsSyncError = "us/sync_errors",
}

export { Resource };
