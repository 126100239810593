enum EventAction {
  Created = "created",
  Deleted = "deleted",
  DeletionQueued = "deletion_queued",
  Merged = "merged",
  Rescanned = "rescanned",
  RestorationQueued = "restoration_queued",
  Restored = "restored",
  Rotated = "rotated",
  Tagged = "tagged",
  Transferred = "transferred",
  Updated = "updated",
}

enum EventEntityType {
  Attachment = "attachment",
  EntityTag = "entity_tag",
  EntityTagging = "entity_tagging",
  Floorplan = "floorplan",
  Form = "form",
  Hyperlink = "hyperlink",
  Markup = "markup",
  MultiHyperlink = "multi_hyperlink",
  Project = "project",
  ProjectResource = "project_resource",
  Sheet = "sheet",
  SheetUpload = "sheet_upload",
  TaskCheckItem = "task_check_item",
  Task = "task",
  TaskRelation = "task_relation",
}

export { EventAction, EventEntityType };
