import { getName, getNames, registerLocale } from "i18n-iso-countries";
import enJson from "i18n-iso-countries/langs/en.json";

import { Country, CountryCodeRequiringState } from "../types";

interface CountryCache {
  countries?: Country[];
}

const countryCache: CountryCache = {};

function computeCountries(): Country[] {
  // Because we are using the package in a browser environment, we have
  // to register the language we want to use to minimize the file size.
  registerLocale(enJson);

  // Get an object that looks like the following:
  // {
  //    AD: "Andorra",
  //    AE: "United Arab Emirates",
  //    ...
  //    ZW: "Zimbabwe"
  // }
  const countryCodeToName = getNames("en");

  // Map the `countryCodeToName` object to a list of country objects that
  // look like the following:
  // [
  //    {
  //      code: "AD",
  //      name: "Andorra"
  //    },
  //    {
  //      code: "AE",
  //      name: "United Arab Emirates"
  //    },
  //    ...
  //    {
  //      code: "ZW",
  //      name: "Zimbabwe"
  //    }
  // ]
  const countries = Object.keys(countryCodeToName).map(
    (countryCode: string) => {
      return {
        code: countryCode,
        name: countryCodeToName[countryCode],
      };
    }
  );

  // Sort the list of country objects in alphabetical order by name. A list of
  // objects is easier for the end component to consume and the alphabetical
  // order makes it easier for the human to consume.
  countries.sort((a: Country, b: Country) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });

  return countries;
}

function getCountries(): Country[] {
  // Check if we've already computed the countries list. If we have,
  // return it, otherwise proceed with computing the country list.
  if (countryCache.countries) {
    return countryCache.countries;
  }

  // Compute the list of countries and save it to the country cache.
  countryCache.countries = computeCountries();

  return countryCache.countries;
}

/**
 * Checks whether a given country, represented by a country code, requires
 * corresponding state information. Used by the Checkout and UpdateCreditCard
 * modals to gather invoice address.
 */
function requiresState(countryCode: string) {
  return Object.values<string>(CountryCodeRequiringState).includes(countryCode);
}

export { getCountries, getName, requiresState };
