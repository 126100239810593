import Typography from "@mui/material/Typography";

function MadeWithLove() {
  return (
    <Typography
      component="p"
      sx={{ color: "fieldwire.gray.1", fontSize: "14px" }}
    >
      Built with ❤️ by the Fieldwire team.
    </Typography>
  );
}

export default MadeWithLove;
