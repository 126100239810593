import { FieldProps, FunctionField, RaRecord } from "react-admin";

interface RoundedPriceFieldProps extends FieldProps {
  // Enforce that source is defined.
  source: string;
}

function RoundedPriceField(props: RoundedPriceFieldProps) {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const value = record[props.source];
        if (typeof value !== "string") {
          return value;
        }

        const parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
          return value;
        }

        return parsedValue.toFixed(2);
      }}
    />
  );
}

export default RoundedPriceField;
