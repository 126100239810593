/**
 * Custom hook that returns functions and variables for handling the position
 * and state of the menu component.
 *
 * This is useful as we use the menu within multiple components throughout the
 * app (ex: action menu, user dropdown menu) and this custom hook allows us to
 * only have to write such logic a single time.
 */

import { useState } from "react";

function useMenuState() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  // Sets the anchor element to the element (e.g. button) that has been clicked.
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Unsets the anchor element when the menu component has been closed.
  const onClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, open, handleClick, onClose };
}

export default useMenuState;
