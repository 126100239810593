import {
  BooleanField,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import {
  EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT,
  ROUNDED_PRICE_TOOLTIP_TEXT,
} from "../../constants";
import {
  DataKind,
  EnterprisePlanCategory,
  EnterprisePlanKind,
  Resource,
} from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  LabelWithInfoTooltip,
  RoundedPriceField,
} from "../custom";

const categoryChoices = componentUtils.generateCapitalCaseSelectChoicesFromEnum(
  EnterprisePlanCategory
);

const kindChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(EnterprisePlanKind);

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const enterprisePlanFilters = [
  <TextInput label="Name" source="name_like" alwaysOn />,
  <SelectInput
    choices={categoryChoices}
    label="Category"
    source="category_eq"
    alwaysOn
  />,
  <SelectInput choices={kindChoices} label="Kind" source="kind_eq" alwaysOn />,
  <TextInput
    label={
      <LabelWithInfoTooltip
        labelText="Account Owner Email"
        tooltipText={EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT}
      />
    }
    source="owner_email_like"
    alwaysOn
  />,
  <TextInput label="Company Domain" source="company_domain_like" alwaysOn />,
  <TextInput label="Company Name" source="company_name_like" alwaysOn />,
  <TextInput
    label="Hilti Subscription ID"
    source="hilti_subscription_id_eq"
    alwaysOn
  />,
];

function EnterprisePlanList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.EnterprisePlan}
      displayCreate={true}
      filters={enterprisePlanFilters}
    >
      <CustomReferenceField
        label="Account Owner"
        source="account_id"
        reference={Resource.Account}
        sortable={false}
      >
        <TextField source="owner_email" />
      </CustomReferenceField>
      <TextField label="Name" source="name" />
      <TextField
        label="Currency"
        source="currency"
        sx={{ textTransform: "uppercase" }}
        sortable={false}
      />
      <RoundedPriceField
        label={
          <LabelWithInfoTooltip
            labelText="Price"
            tooltipText={ROUNDED_PRICE_TOOLTIP_TEXT}
          />
        }
        source="price_native_currency"
        sortable={false}
      />
      <TextField
        label="Overage Price"
        source="overage_price_native_currency"
        sortable={false}
      />
      {/* MISSING: Last User Count */}
      <NumberField label="Max User Count" source="max_user_count" />
      <TextField label="Company Name" source="company_name" />
      <TextField label="Company Domain" source="company_domain" />
      <TextField
        label="Hilti Subscription ID"
        source="hilti_subscription_id"
        sortable={false}
      />
      <CustomReferenceField
        label="Account"
        source="account_id"
        reference={Resource.Account}
      >
        <TextField source="id" />
      </CustomReferenceField>
      <CustomDateTimeField label="Start At" source="start_at" />
      <CustomDateTimeField label="End At" source="end_at" />
      <BooleanField label="Is Temporary" source="is_temporary" />
      <BooleanField label="Is Poc" source="is_poc" />
      {/* MISSING: Is Auto Charge Overage */}
      {/* MISSING: Is User Limit */}
      <TextField label="Category" source="category" />
      <TextField label="Kind" source="kind" />
      {/* MISSING: Account History */}
      {/* MISSING: Invoices */}
    </CustomList>
  );
}

export default EnterprisePlanList;
