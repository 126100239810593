/**
 * The UI that the user sees when an unhandled client-side exception occurs.
 * This component also sends the exception to Sentry, which is the 3rd party
 * monitoring and error tracking software that we use.
 */

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Button, useResetErrorBoundaryOnLocationChange } from "react-admin";

import jobsiteJeff from "../../assets/jobsite-jeff.png";
import { errorTracker } from "../../utils";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback(props: ErrorFallbackProps) {
  // Send exception to Sentry a single time whenever `props.error` changes.
  useEffect(() => {
    errorTracker.captureException(props.error);
  }, [props.error]);

  // Resets the error boundary state whenever the location changes. In
  // other words, clears the error screen when a user navigates to a
  // different page.
  useResetErrorBoundaryOnLocationChange(props.resetErrorBoundary);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <img src={jobsiteJeff} alt="Jobsite Jeff" />
      <Typography
        component="h1"
        sx={{
          color: "fieldwire.gray.1",
          fontSize: "26px",
          fontWeight: "700",
          lineHeight: "1",
        }}
      >
        An error has occurred.
      </Typography>
      <Typography component="p" sx={{ color: "fieldwire.gray.1" }}>
        Jeff is looking into it. In the meantime, try heading back.
      </Typography>
      <Button
        label="Back"
        onClick={() => {
          window.history.go(-1);
        }}
        variant="fieldwire-primary"
      />
    </Box>
  );
}

export default ErrorFallback;
