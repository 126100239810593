import { Box, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  BooleanField,
  FunctionField,
  Identifier,
  NumberField,
  RecordContextProvider,
  TextField,
  useDataProvider,
  useListContext,
  useRecordContext,
  useReference,
  useShowContext,
  WrapperField,
} from "react-admin";
import { MutationFunction, MutationKey, useQuery } from "react-query";

import { EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT } from "../../constants";
import { useModalState, useMutation } from "../../hooks";
import {
  AccountResponse,
  Action,
  CustomDataProvider,
  DataKind,
  Region,
  Resource,
  UserAccountResponse,
  UserResponse,
  UserSyncTokensInfoResponse,
} from "../../types";
import { errorTracker, resourceUtils } from "../../utils";
import { routes } from "../../utils/Routes";
import { componentUtils } from "../component_utils";
import {
  ActionMenu,
  Authorize,
  CheckoutModal,
  ConfirmationModal,
  CreateDemoProjectModal,
  CustomDatagrid,
  CustomDateTimeField,
  CustomMenuItem,
  CustomReferenceField,
  CustomReferenceManyField,
  CustomShow,
  ForceUnblockAccountMenuItem,
  LabelWithInfoTooltip,
  LinkablePlanInfoField,
  MutationActionMenuItem,
  ShowTable,
  UnblockAccountMenuItem,
} from "../custom";

interface RemoveUserFromAllProjectsMutationFnVariables {
  id: Identifier;
}

interface UnlockUserMutationFnVariables {
  id: Identifier;
}

// Custom Actions

function Checkout() {
  const Checkout = (props: { userRecord: UserResponse }) => {
    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: userRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      return (
        <CheckoutButton
          accountRecord={accountRecord}
          userRecord={props.userRecord}
        />
      );
    } else {
      return null;
    }
  };

  const userRecord = useRecordContext<UserResponse>();

  if (userRecord) {
    return <Checkout userRecord={userRecord} />;
  } else {
    return null;
  }
}

function CheckoutButton(props: {
  accountRecord: AccountResponse;
  userRecord: UserResponse;
}) {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  const isAccountBasic = componentUtils.isAccountBasic(props.accountRecord);

  return (
    <>
      <CustomMenuItem
        onClick={handleOpen}
        // Disable modal button if user's account isn't basic.
        disabled={!isAccountBasic}
      >
        Checkout
      </CustomMenuItem>
      <CheckoutModal
        account={props.accountRecord}
        handleClose={handleClose}
        modalOpen={modalOpen}
        user={props.userRecord}
      />
    </>
  );
}

function CreateDemoProject(props: { region: Region }) {
  const record = useRecordContext<UserResponse>();

  if (record) {
    return <CreateDemoProjectButton region={props.region} user={record} />;
  } else {
    return null;
  }
}

function CreateDemoProjectButton(props: {
  region: Region;
  user: UserResponse;
}) {
  const title = `New Demo Project - ${props.region.toUpperCase()}`;
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    <>
      <CustomMenuItem onClick={handleOpen}>{title}</CustomMenuItem>
      <CreateDemoProjectModal
        handleClose={handleClose}
        modalOpen={modalOpen}
        region={props.region}
        user={props.user}
      />
    </>
  );
}

function ForceUnblockAccount() {
  const ForceUnblockAccount = (props: { userRecord: UserResponse }) => {
    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.userRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      return <ForceUnblockAccountMenuItem accountRecord={accountRecord} />;
    } else {
      return null;
    }
  };

  const userRecord = useRecordContext<UserResponse>();

  if (userRecord) {
    return <ForceUnblockAccount userRecord={userRecord} />;
  } else {
    return null;
  }
}

function MarkUserForDeletion() {
  const userRecord = useRecordContext<UserResponse>();

  if (userRecord) {
    return <MarkUserForDeletionButton userRecord={userRecord} />;
  } else {
    return null;
  }
}

function MarkUserForDeletionButton(props: { userRecord: UserResponse }) {
  const { handleClose, handleOpen, modalOpen } = useModalState();
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { mutate } = useMutation(
    (userId: Identifier) => dataProvider.markUserForPendingDeletion(userId),
    ["markUserForPendingDeletion", { userId: props.userRecord.id }],
    "User has been successfully marked for deletion! ✅"
  );

  const handleDelete = () => {
    handleClose();
    mutate(props.userRecord.id);
  };

  return (
    <>
      <CustomMenuItem onClick={handleOpen}>
        Mark User For Deletion
      </CustomMenuItem>
      <ConfirmationModal
        cancelHandler={handleClose}
        confirmHandler={handleDelete}
        confirmButtonText="Delete"
        confirmButtonVariant="fieldwire-danger"
        description={
          "Alert: Proceeding will erase the user's entire profile and cannot be undone."
        }
        modalOpen={modalOpen}
        title="Mark User For Deletion"
      />
    </>
  );
}

function RemoveUserFromAllProjects() {
  const record = useRecordContext<UserResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<
      void[],
      RemoveUserFromAllProjectsMutationFnVariables
    > = (variables: RemoveUserFromAllProjectsMutationFnVariables) => {
      const promiseFns = Object.values(Region).map((region) => {
        return dataProvider.removeUserFromAllProjects(region, variables.id);
      });

      return Promise.all(promiseFns);
    };

    const mutationKey: MutationKey = ["removeUserFromAllProjects", record.id];
    const successMessage =
      "Removed user from all projects successfully. Please allow a few minutes for this to take effect! ✅";

    return (
      <MutationActionMenuItem
        label="Remove User From All Projects"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function UnblockAccount() {
  const UnblockAccount = (props: { userRecord: UserResponse }) => {
    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.userRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      return <UnblockAccountMenuItem accountRecord={accountRecord} />;
    } else {
      return null;
    }
  };

  const userRecord = useRecordContext<UserResponse>();

  if (userRecord) {
    return <UnblockAccount userRecord={userRecord} />;
  } else {
    return null;
  }
}

function UnlockUser() {
  const record = useRecordContext<UserResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<void, UnlockUserMutationFnVariables> = (
      variables: UnlockUserMutationFnVariables
    ) => {
      return dataProvider.unlockUser(variables.id);
    };
    const mutationKey: MutationKey = ["unlockUser", record.id];
    const successMessage = "Unlocked user successfully! ✅";

    return (
      <MutationActionMenuItem
        disabled={record.locked_at ? false : true}
        label="Unlock User"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

// Custom Fields

// We never actually display the label for the CustomReferenceManyField component as
// the component occurs as a standalone table. However, React Admin will still add an
// empty Label component, which causes style issues for us. To ensure that React Admin
// does not do this, we force the top level component, which in this case is the
// OverLimitNotifications component, to only take the label prop as false.
function OverLimitNotifications(_props: { label: false }) {
  const record = useRecordContext<UserResponse>();

  if (record) {
    return (
      <RecordContextProvider value={{ id: record.account_id }}>
        <CustomReferenceManyField
          label={false}
          displayPagination={true}
          reference={Resource.AccountOverageNotification}
          target={Resource.Account}
        >
          <CustomDatagrid disableRowClick={true}>
            <TextField label="Plan" source="plan_name" sortable={false} />
            <CustomDateTimeField
              label="Created At"
              source="created_at"
              sortable={false}
            />
            <NumberField
              label="Days Remaining"
              source="days_remaining"
              sortable={false}
            />
          </CustomDatagrid>
        </CustomReferenceManyField>
      </RecordContextProvider>
    );
  } else {
    return null;
  }
}

function TokenField(props: {
  label: string;
  region: Region;
  resource: Resource;
  source: string;
}) {
  const TokenField = (props: {
    record: UserResponse;
    region: Region;
    resource: Resource;
    source: string;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { data } = useQuery<{ data: UserSyncTokensInfoResponse }>(
      ["getUserSyncTokensInfo", { id: props.record.id, region: props.region }],
      () => dataProvider.getUserSyncTokensInfo(props.region, props.record.id)
    );

    if (data) {
      const tokenId = data.data[props.source];
      return (
        <Link href={routes.computeShowFor(props.resource, tokenId)}>
          {tokenId}
        </Link>
      );
    } else {
      return null;
    }
  };

  const record = useRecordContext<UserResponse>();

  if (record) {
    return (
      <TokenField
        record={record}
        region={props.region}
        resource={props.resource}
        source={props.source}
      />
    );
  } else {
    return null;
  }
}

const fieldwireActions = [
  {
    // If the admin user can see the user show page
    // then they can create a new demo projects...
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <CreateDemoProject region={Region.Eu} />,
  },
  {
    // If the admin user can see the user show page
    // then they can create a new demo projects...
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <CreateDemoProject region={Region.Us} />,
  },

  // The following three actions can be performed by all admin
  // users. Therefore, we use the combo of `Read` and `User`
  // as the permission check. This is because the default
  // role can read users and all admin users have, at
  // least, the default role.
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <UnlockUser />,
  },
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <UnblockAccount />,
  },
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <ForceUnblockAccount />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.StripeSubscription,
    element: <Checkout />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.User,
    element: <RemoveUserFromAllProjects />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.User,
    element: <MarkUserForDeletion />,
  },
];

// The reason we have to split the account section into a standalone component
// is so that we can fetch the user's account record from the list context and
// properly hide or show the following fields depending on if the account is
// premium and potentially enterprise: max_projects, max_users, max_sheets,
// and checkout_user_count.
//
// A more detailed explanation of why this is necessary can be found here:
// https://marmelab.com/react-admin/Fields.html#hiding-a-field-based-on-the-value-of-another
function Account() {
  const { data } = useListContext<UserAccountResponse>();
  const { record: userRecord } = useShowContext<UserResponse>();

  // Data hasn't loaded yet.
  if (!data || !userRecord) {
    return null;
    // Single account record for user's account.
  } else if (data.length === 1) {
    const accountRecord = data[0];
    return (
      <CustomDatagrid>
        <TextField source="id" sortable={false} />
        <CustomDateTimeField
          label="Blocked At"
          source="blocked_at"
          sortable={false}
        />
        <TextField label="Owner" source="owner_email" sortable={false} />
        <LinkablePlanInfoField label="Plan" sortable={false} />
        {!accountRecord.is_enterprise && (
          <NumberField
            label="Checkout User Count"
            source="checkout_user_count"
            sortable={false}
          />
        )}
        <NumberField
          label="Projects"
          source="active_payable_projects"
          sortable={false}
        />
        {!accountRecord.is_premium && (
          <NumberField
            label="Max Projects"
            source="max_projects"
            sortable={false}
          />
        )}
        <NumberField
          label="Users"
          source="current_user_count"
          sortable={false}
        />
        {!accountRecord.is_premium && (
          <NumberField label="Max Users" source="max_users" sortable={false} />
        )}
        <NumberField
          label="Sheets"
          source="active_payable_sheets"
          sortable={false}
        />
        {!accountRecord.is_premium && (
          <NumberField
            label="Max Sheets"
            source="max_sheets"
            sortable={false}
          />
        )}
      </CustomDatagrid>
    );
    // Theoretically, the BE should prevent us from ever returning anything but a
    // single account record. Unfortunately, the FE doesn't know this as it's using
    // customReferenceMany. Therefore, if there is zero or more than one account
    // record for user's account, then we log an error to Sentry and display an
    // error message in its place.
  } else {
    return (
      <AccountErrorMessage
        errorMessage={`Should have received exactly one account record for user ${userRecord.id} account. Received ${data.length} instead.`}
      />
    );
  }
}

function AccountErrorMessage(props: { errorMessage: string }) {
  // Send exception to Sentry a single time whenever `props.errorMessage` changes.
  useEffect(() => {
    errorTracker.captureException(new Error(props.errorMessage));
  }, [props.errorMessage]);

  return (
    <Box
      sx={{
        padding: "24px",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: "fieldwire.gray.1",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        Something went wrong! Engineering has been notified.
      </Typography>
    </Box>
  );
}

function UserProjects(props: { region: Region }) {
  const title = `User Projects - ${props.region.toUpperCase()}`;
  const projectResource = resourceUtils.projectResourceFor(props.region);

  return (
    <Authorize
      action={Action.Read}
      dataKind={DataKind.Project}
      disableUnauthorizedMessage
    >
      <ShowTable rowFlexDirection="column" title={title}>
        <CustomReferenceManyField
          label={false}
          defaultSort={{ field: "created_at", order: "DESC" }}
          displayPagination={true}
          reference={projectResource}
          target={Resource.User}
        >
          {/*
            All of the following fields have sortable set to false because our
            BE either 1) does not support sorting them outright or 2) the name
            given to the column by the serializer does not match the name of
            the column in the DB, which causes unexpected behavior.
          */}
          <CustomDatagrid>
            <CustomReferenceField
              label="Plan Name"
              source="account_id"
              reference={Resource.Account}
              sortable={false}
            >
              <TextField source="plan_info" />
            </CustomReferenceField>
            <CustomReferenceField
              label="Owner Email"
              source="account_id"
              reference={Resource.Account}
              sortable={false}
            >
              <TextField source="owner_email" />
            </CustomReferenceField>
            <BooleanField
              label="Is User Approved for Project"
              source="is_approved"
              sortable={false}
            />
            <TextField label="Role" source="role" sortable={false} />
            <TextField label="Name" source="name" sortable={false} />
            <CustomDateTimeField
              label="Created At"
              source="created_at"
              sortable={false}
            />
            <NumberField
              label="Task Count"
              source="tasks_sequence_counter"
              sortable={false}
            />
            <BooleanField
              label={
                <LabelWithInfoTooltip
                  labelText="Is Sample Project"
                  tooltipText="Sample projects are not included in analytics."
                />
              }
              source="is_sample_project"
              sortable={false}
            />
            <CustomDateTimeField
              label="Archived At"
              source="archived_at"
              sortable={false}
            />
            <CustomDateTimeField
              label="Deleted At"
              source="deleted_at"
              sortable={false}
            />
            <CustomDateTimeField
              label="User Created At"
              source="user_created_at"
              sortable={false}
            />
            <CustomDateTimeField
              label="User Updated At"
              source="user_updated_at"
              sortable={false}
            />
            <CustomDateTimeField
              label="User Deleted At"
              source="user_deleted_at"
              sortable={false}
            />
            {/* MISSING: Folder Structure */}
          </CustomDatagrid>
        </CustomReferenceManyField>
      </ShowTable>
    </Authorize>
  );
}

function UserShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.User}
      displayDelete={false}
      displayEdit={true}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF ACCOUNT */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.Account}
        disableUnauthorizedMessage
      >
        <ShowTable title="Account">
          <CustomReferenceManyField
            label={false}
            displayPagination={false}
            reference={Resource.Account}
            target={Resource.User}
          >
            {/*
              To understand why we need to have Account as a standalone
              component, please read the comment above its definition.
            */}
            <Account />
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF OF ACCOUNT */}

      {/* START OF OVER LIMIT NOTIFICATIONS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.AccountOverageNotification}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Over Limit Notifications">
          <OverLimitNotifications label={false} />
        </ShowTable>
      </Authorize>
      {/* END OF OF OVER LIMIT NOTIFICATIONS  */}

      {/* START OF USER DETAILS */}
      <ShowTable title="User Details">
        <TextField label="Id" source="id" />
        <BooleanField label="Is Approved" source="is_approved" />
        <TextField label="First Name" source="first_name" />
        <TextField label="Last Name" source="last_name" />
        <TextField label="Email" source="email" />
        <NumberField label="Lead Score" source="lead_score" />
        <NumberField label="Sign In Count" source="sign_in_count" />
        <CustomDateTimeField
          label="Current Sign In At"
          source="current_sign_in_at"
        />
        <CustomDateTimeField label="Last Sign In At" source="last_sign_in_at" />
        <TextField label="Current Sign In IP" source="current_sign_in_ip" />
        <TextField label="Last Sign In IP" source="last_sign_in_ip" />
        {/* MISSING: Email Api Verified */}
        {/* MISSING: Email User Verified */}
        <NumberField label="Failed Attempts" source="failed_attempts" />
        <CustomDateTimeField label="Locked At" source="locked_at" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        <FunctionField
          label={
            <LabelWithInfoTooltip
              labelText="Email Confirmation Status"
              tooltipText={EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT}
            />
          }
          render={(userRecord: UserResponse) =>
            componentUtils.snakeCaseToCapitalCase(
              userRecord.email_confirmation_status
            )
          }
        />
        <CustomDateTimeField
          label="Email Confirmation Sent At"
          source="confirmation_sent_at"
        />
        <CustomDateTimeField label="Email Confirmed At" source="confirmed_at" />
        <CustomDateTimeField
          label="Invitation Created At"
          source="invitation_created_at"
        />
        <CustomDateTimeField
          label="Invitation Sent At"
          source="invitation_sent_at"
        />
        <CustomDateTimeField
          label="Invitation Accepted At"
          source="invitation_accepted_at"
        />
        {/* MISSING: Invitation Limit */}
        <CustomReferenceField
          label="Invited By"
          source="invited_by_id"
          reference={Resource.User}
        >
          <TextField source="first_name" />
        </CustomReferenceField>
        <TextField label="Invited By Type" source="invited_by_type" />
        <TextField label="Phone Number" source="phone_number" />
        <TextField label="Photo URL" source="photo_url" />
        <TextField label="Company" source="company" />
        <WrapperField label="Number Of Employees">
          <NumberField source="min_employees" />
          <>-</>
          <NumberField source="max_employees" />
        </WrapperField>
        <BooleanField
          label="Is Email Notifications Enabled"
          source="is_email_notifications_enabled"
        />
        <CustomDateTimeField
          label="In App Purchase End At"
          source="in_app_purchase_end_at"
        />
        {/* MISSING: Is Paid For */}
        <BooleanField label="Is High Value" source="is_high_value" />
        <TextField label="City" source="city" />
        <TextField label="Region" source="region" />
        <TextField label="Country Code" source="country_code" />
        {/*
        We had to create a custom field in order to handle the tokens and
        and their associated links to their show pages. This is because the
        general reference field approaches we normally take do not work here.

        The two main problems are 1) we don't have a clean resource to reference
        and 2) React Admin typically uses the id of the referenced resource to
        construct a path to the referenced resources' show page. The id in the
        response we get from the BE here is the user id.
      */}

        <TokenField
          label="Microsoft Token - EU"
          region={Region.Eu}
          resource={Resource.EuMicrosoftToken}
          source={"microsoft_token"}
        />
        <TokenField
          label="Microsoft Token - US"
          region={Region.Us}
          resource={Resource.UsMicrosoftToken}
          source={"microsoft_token"}
        />
        <TokenField
          label="Dropbox Token - EU"
          region={Region.Eu}
          resource={Resource.EuDropboxToken}
          source={"dropbox_token"}
        />
        <TokenField
          label="Dropbox Token - US"
          region={Region.Us}
          resource={Resource.UsDropboxToken}
          source={"dropbox_token"}
        />
        <TokenField
          label="Box Token - EU"
          region={Region.Eu}
          resource={Resource.EuBoxToken}
          source={"box_token"}
        />
        <TokenField
          label="Box Token - US"
          region={Region.Us}
          resource={Resource.UsBoxToken}
          source={"box_token"}
        />
        <TextField label="Utm Campaign" source="utm_campaign" />
        <TextField label="Utm Content" source="utm_content" />
        <TextField label="Utm Medium" source="utm_medium" />
        <TextField label="Utm Source" source="utm_source" />
        <TextField label="Utm Term" source="utm_term" />
        {/* MISSING: Sso Domains */}
      </ShowTable>
      {/* END OF OF USER DETAILS */}

      {/* START OF PROJECTS */}
      {/*
        Showing US before EU since vast majority of our users are
        US based & we would like to reduce scrolling as much as possible.
      */}
      <UserProjects region={Region.Us} />
      <UserProjects region={Region.Eu} />
      {/* END OF PROJECTS */}
    </CustomShow>
  );
}

export default UserShow;
