import {
  NullableBooleanInput,
  PasswordInput,
  TextInput,
  useRecordContext,
} from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

// Custom input that appears if the admin user is locked.
// Allows current admin user to unlock locked admin user.
function UnlockInput() {
  const record = useRecordContext();
  return (
    <>
      {record?.locked_at && (
        <NullableBooleanInput label="Unlock User" source="unlock" />
      )}
    </>
  );
}

function AdminUserEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.AdminUser}>
      <TextInput label="Email" source="email" required />
      <PasswordInput label="Password" source="password" />
      <UnlockInput />
    </CustomEdit>
  );
}

export default AdminUserEdit;
