import Link from "@mui/material/Link";
import { FunctionField, NumberField, TextField } from "react-admin";

import {
  Action,
  DataKind,
  Region,
  Resource,
  SyncErrorResponse,
} from "../../types";
import { resourceUtils } from "../../utils";
import {
  Authorize,
  CustomDateTimeField,
  CustomReferenceField,
  CustomShow,
  ProjectsForSync,
  ShowTable,
} from "../custom";

function SyncErrorShow(props: { region: Region }) {
  return (
    <CustomShow
      checkPermissionFor={DataKind.SyncError}
      displayDelete={false}
      displayEdit={false}
    >
      {/* START OF SYNC ERROR DETAILS */}
      <ShowTable title="Sync Error Details">
        <CustomDateTimeField label="Last Occurred" source="updated_at" />
        <CustomDateTimeField label="First Occurred" source="created_at" />
        <CustomReferenceField
          label="Sync Owner"
          reference={Resource.User}
          source="sync_owner_id"
        >
          <TextField source="email" />
        </CustomReferenceField>
        <NumberField label="Count" source="count" />
        <TextField label="Entity Type" source="entity_type" />
        <TextField label="Entity Name" source="entity_name" />
        <TextField label="Entity Id" source="entity_id" />
        <FunctionField
          label="Error (May Link to Rollbar)"
          render={(record: SyncErrorResponse) =>
            record.error_url ? (
              <Link
                href={record.error_url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {record.error_message}
              </Link>
            ) : (
              record.error_message
            )
          }
        />
      </ShowTable>
      {/* END OF SYNC ERROR DETAILS */}

      {/* START OF PROJECTS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.SyncError}
        disableUnauthorizedMessage
      >
        <ProjectsForSync
          region={props.region}
          target={resourceUtils.syncErrorResourceFor(props.region)}
        />
      </Authorize>
      {/* END OF PROJECTS */}
    </CustomShow>
  );
}

export default SyncErrorShow;
