import Typography from "@mui/material/Typography";

interface TitleProps {
  title: string;
}

function Title(props: TitleProps) {
  return (
    <Typography
      component="h1"
      sx={{
        color: "fieldwire.gray.1",
        fontSize: "26px",
        fontWeight: "700",
      }}
    >
      {props.title}
    </Typography>
  );
}

export default Title;
