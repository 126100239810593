import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Button,
  FunctionField,
  Identifier,
  ResourceContextProvider,
  SelectInput,
  SortPayload,
  TextField,
  useDataProvider,
  useListContext,
  useNotify,
} from "react-admin";
import { MutationFunction, MutationKey } from "react-query";
import { useParams } from "react-router-dom";

import { storage } from "../../storage";
import {
  CustomDataProvider,
  DataKind,
  EventEntityType,
  Resource,
  UserResponse,
} from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  CustomReferenceFieldLazy,
  CustomUtcDateTimeInput,
  MutationActionButton,
} from "../custom";

interface ExportAuditLogsForProjectMutationFnVariables {
  adminUserEmail: string;
  projectId: Identifier;
  filter: any;
  sort: SortPayload;
}

const auditLogProjectsTitle = "Project Audit Log";

const eventEntityTypeChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(EventEntityType);

const auditLogProjectsFilters = [
  <CustomUtcDateTimeInput
    label="Start"
    source="event_timestamp_gte"
    alwaysOn
  />,
  <CustomUtcDateTimeInput label="End" source="event_timestamp_lte" alwaysOn />,
  <SelectInput
    choices={eventEntityTypeChoices}
    label="Event Entity Type"
    source="event_entity_type_like"
    alwaysOn
  />,
];

const MAX_NUM_DAYS_FOR_EXPORT = 90;
const NUM_MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
const MAX_NUM_MILLISECONDS_FOR_EXPORT =
  MAX_NUM_DAYS_FOR_EXPORT * NUM_MILLISECONDS_PER_DAY;

function isDateRangeValid(
  eventTimestampGte: Date,
  eventTimestampLte: Date
): boolean {
  // Both start and end dates must be provided
  if (!eventTimestampGte || !eventTimestampLte) {
    return false;
  }

  const startTimeMs = eventTimestampGte.getTime();
  const endTimeMs = eventTimestampLte.getTime();
  const differenceStartAndEndTimeMs = endTimeMs - startTimeMs;

  // End date must come after start date and should not exceed 90 days in length
  return (
    endTimeMs > startTimeMs &&
    differenceStartAndEndTimeMs <= MAX_NUM_MILLISECONDS_FOR_EXPORT
  );
}

function ExportAuditLogsForProject() {
  // Pull out the project ID, filters, and sorting params
  const { project_id: projectId } = useParams();
  const { filterValues: filter, sort } = useListContext();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const notify = useNotify();

  const adminUserEmail = storage.loadAuthInfo()?.admin_user.email;
  const eventTimestampGte =
    filter["event_timestamp_gte"] && new Date(filter["event_timestamp_gte"]);
  const eventTimestampLte =
    filter["event_timestamp_lte"] && new Date(filter["event_timestamp_lte"]);
  const label = "Export Log";
  const startIcon = <MailOutlineIcon />;

  if (!adminUserEmail) {
    return (
      <Button
        onClick={() => {
          notify(
            "Current admin user email not found. Please log out and log in again."
          );
        }}
        label={label}
        startIcon={startIcon}
        variant="fieldwire-primary"
      />
    );
  } else if (!isDateRangeValid(eventTimestampGte, eventTimestampLte)) {
    return (
      <Button
        onClick={() => {
          notify(
            "A date range must be provided and cannot exceed 90 days in length. Please adjust the start and end dates and try again."
          );
        }}
        label={label}
        startIcon={startIcon}
        variant="fieldwire-primary"
      />
    );
  } else if (projectId) {
    const mutationFn: MutationFunction<
      void,
      ExportAuditLogsForProjectMutationFnVariables
    > = (variables: ExportAuditLogsForProjectMutationFnVariables) => {
      return dataProvider.exportAuditLogsForProject(
        variables.projectId,
        variables.adminUserEmail,
        variables.filter,
        variables.sort
      );
    };

    const mutationKey: MutationKey = [
      "exportAuditLogsForProject",
      adminUserEmail,
    ];
    const successMessage = `Audit Log Export is being sent to ${adminUserEmail} ✅`;

    return (
      <MutationActionButton
        label={label}
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{
          adminUserEmail,
          projectId,
          filter,
          sort,
        }}
        successMessage={successMessage}
        startIcon={startIcon}
        skipPageRefresh={true}
      />
    );
  } else {
    return null;
  }
}

function AuditLogsProjects() {
  const { project_id } = useParams();
  if (!project_id) {
    // TODO [Nice-to-Have] [REG-1881]: Use reusable "error" here instead!
    return <p>Error: Project ID not found!</p>;
  }

  return (
    <ResourceContextProvider value={Resource.UsAuditLogProject}>
      <CustomList
        checkPermissionFor={DataKind.Project}
        customTitle={auditLogProjectsTitle}
        defaultSort={{ field: "id", order: "DESC" }}
        displayCreate={false}
        disableRowClick={true}
        fieldwireActions={<ExportAuditLogsForProject />}
        filter={{ project_id_eq: project_id }}
        filters={auditLogProjectsFilters}
      >
        <TextField label="Id" source="id" sortable={false} />
        <CustomReferenceField
          source="user_id"
          reference={Resource.User}
          sortable={false}
        >
          <FunctionField
            render={(record: UserResponse) =>
              `${record.first_name} ${record.last_name}`
            }
          />
        </CustomReferenceField>
        <CustomReferenceFieldLazy
          source="account_id"
          reference={Resource.Account}
          sortable={false}
        />
        <CustomDateTimeField label="Event Timestamp" source="event_timestamp" />
        <TextField
          label="Event Action"
          source="event.action"
          sortable={false}
        />
        <TextField
          label="Event Entity Type"
          source="event.entity_type"
          sortable={false}
        />
        <TextField
          label="Event Attributes"
          source="event.attributes"
          sortable={false}
        />
        <TextField label="Entity" source="entity" sortable={false} />
        {/* MISSING: Is Covered */}
      </CustomList>
    </ResourceContextProvider>
  );
}

export default AuditLogsProjects;
