/**
 * A custom link field meant to be used either on the company list or show
 * page which automatically takes the current admin user to the Salesforce
 * page (in a new tab) for the current company record.
 */

import Link from "@mui/material/Link";
import { MouseEvent } from "react";
import { useRecordContext } from "react-admin";

import { CompanyResponse } from "../../../types";

const SALESFORCE_LINK = "https://fieldwire.my.salesforce.com";

interface SalesforceLinkProps {
  label: string;
}

function SalesforceLink(_props: SalesforceLinkProps) {
  const companyRecord = useRecordContext<CompanyResponse>();

  if (companyRecord) {
    return (
      <Link
        href={`${SALESFORCE_LINK}/${companyRecord.salesforce_id}`}
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          // If this field is used within a Datagrid, the click event will
          // cause the Datagrid to route the current admin user to the show
          // page for the current company record while a new tab is opened
          // taking the current admin user to the Salesforce page. We stop
          // event propagation to prevent the former from occuring.
          e.stopPropagation();
        }}
      >
        {companyRecord.salesforce_id}
      </Link>
    );
  } else {
    return null;
  }
}

export default SalesforceLink;
