import { TextField } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomList } from "../custom";

function DemoProjectTemplateList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.DemoProjectTemplate}
      displayCreate={true}
    >
      <TextField label="Id" source="id" sortable={false} />
      <TextField
        label="Demo Project Id"
        source="demo_project_id"
        sortable={false}
      />
      <TextField label="Name" source="name" sortable={false} />
      <CustomDateTimeField
        label="Created At"
        source="created_at"
        sortable={false}
      />
      <CustomDateTimeField
        label="Updated At"
        source="updated_at"
        sortable={false}
      />
    </CustomList>
  );
}

export default DemoProjectTemplateList;
