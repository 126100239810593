import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  CustomDataProvider,
  Signup,
  SignupEmailVerificationDistributionResponse,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";

function SignupsEmailVerificationChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: SignupEmailVerificationDistributionResponse;
  }>(["getSignupEmailVerificationDistribution"], () =>
    dataProvider.getSignupEmailVerificationDistribution()
  );

  if (data?.data) {
    return (
      <ChartContainer title="Fresh Signups">
        <LineChart
          // NOTE: We assume that all signups have the same dates present.
          labels={data.data.total_signups.map((d: Signup) => d.date)}
          datasets={[
            {
              data: data.data.total_signups.map((d: Signup) => d.count),
              label: "Signups",
            },
            {
              data: data.data.verified_signups.map((d: Signup) => d.count),
              label: "Verified Users",
            },
            {
              data: data.data.unverified_signups.map((d: Signup) => d.count),
              label: "Unverified Users",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default SignupsEmailVerificationChart;
