import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForFinancesResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function ProofOfConceptMrrChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForFinancesResponse[];
  }>(["getActiveUserCountsForFinances"], () =>
    dataProvider.getActiveUserCountsForFinances()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForFinancesResponse(),
      30
    );

    return (
      <ChartContainer title="Proof of Concept (MRR)">
        <LineChart
          labels={processedData.map(
            (d: ActiveUserCountsForFinancesResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.mrr_enterprise_poc
              ),
              label: "Enterprise (POC)",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default ProofOfConceptMrrChart;
