import { MenuItemLinkClasses } from "react-admin";

import { Action, DataKind } from "../../../types";

const sxForActiveOrHover = {
  // #3173FF (fieldwire.blue.3) at 8% opacity.
  bgcolor: "#3173FF14",
  // Refers to menu item icon.
  "& svg": {
    color: "fieldwire.gray.1",
  },
};

const menuItemSxForActiveOrHover = {
  // Refers to the currently selected menu item.
  [`&.${MenuItemLinkClasses.active}`]: {
    ...sxForActiveOrHover,
    // Without this style a dark background appears
    // on the currently selected menu item until it
    // loses focus.
    "&::after": {
      ...sxForActiveOrHover,
    },
  },
  // Refers to the currently hovered menu item.
  "&:hover": {
    ...sxForActiveOrHover,
  },
};

const menuItemSxForBorder = {
  borderBottomColor: "fieldwire.gray.5",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  borderRightColor: "fieldwire.gray.5",
  borderRightStyle: "solid",
  borderRightWidth: "1px",
};

const menuItemSxForLeftIcon = {
  [`& .${MenuItemLinkClasses.icon}`]: {
    minWidth: "26px",
  },

  // Refers to any SVGs (icons) within the menu item.
  "& svg": {
    color: "fieldwire.gray.3",
    height: "18px",
    width: "18px",
  },
};

const menuItemSxForText = {
  color: "fieldwire.gray.1",
  fontSize: "14px",
};

const menuItemLinkSx = {
  ...menuItemSxForActiveOrHover,
  ...menuItemSxForBorder,
  ...menuItemSxForLeftIcon,
  ...menuItemSxForText,

  height: "44px",
  padding: "0px 16px",
};

interface MultiRegionMenuItemProps {
  action: Action;
  dataKind: DataKind;
  tag: "multi_region";
  primaryText: string;
  pathForEu: string;
  pathForUs: string;
}

interface SingleRegionMenuItemProps {
  action: Action;
  primaryText: string;
  dataKind: DataKind;
  tag: "single_region";
  to: string;
}

export type { MultiRegionMenuItemProps, SingleRegionMenuItemProps };

export {
  menuItemLinkSx,
  menuItemSxForActiveOrHover,
  menuItemSxForBorder,
  menuItemSxForLeftIcon,
  menuItemSxForText,
};
