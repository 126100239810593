import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";

interface ChartContainerProps {
  children: any;
  // A title may be of type ReactElement if it needs
  // to have a bit of functionality (ex: showing a
  // tooltip when hovering over certain numbers).
  title: string | ReactElement;
}

function ChartContainer(props: ChartContainerProps) {
  return (
    <Box
      sx={{
        bgcolor: "fieldwire.white.1",
        borderColor: "fieldwire.gray.5",
        borderRadius: "3px",
        borderStyle: "solid",
        borderWidth: "1px",
        // This is needed due to the bug noted below. Namely, the
        // chart has trouble responding to decreases in container
        // size.
        //
        // https://github.com/chartjs/Chart.js/issues/4156#issuecomment-345770012
        minWidth: "0",
      }}
    >
      <Box
        sx={{
          borderBottomColor: "fieldwire.gray.5",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          padding: "16px 24px",
        }}
      >
        <Typography
          sx={{
            color: "fieldwire.gray.1",
            fontSize: "14px",
            fontWeight: "400",
          }}
          variant="h2"
        >
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{
          boxSizing: "content-box",
          // This height is arbitrary and could be anything we want!
          height: "315px",
          padding: "20px",
          position: "relative",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default ChartContainer;
