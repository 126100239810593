import { PasswordInput, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomCreate } from "../custom";

function AdminUserCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.AdminUser}>
      <TextInput label="Email" source="email" required />
      <PasswordInput label="Password" source="password" required />
    </CustomCreate>
  );
}

export default AdminUserCreate;
