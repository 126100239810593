import { textFieldClasses } from "@mui/material";
import Box from "@mui/material/Box";
import { FormEvent } from "react";

interface FormProps {
  children: any;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

function Form(props: FormProps) {
  return (
    <Box
      component="form"
      onSubmit={props.onSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        // All inputs within the form should be 480px wide.
        // We use MUI's `TextField` component for inputs.
        [`& .${textFieldClasses.root}`]: { width: "480px" },
      }}
    >
      {props.children}
    </Box>
  );
}

export default Form;
