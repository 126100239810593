/**
 * A wrapper around Material UI's Card component specifically used
 * for the create or edit page. This is different from other uses
 * of Material UI's Card component as this one has a transparent
 * background and a set width of 480px.
 */

import Card from "@mui/material/Card";

interface CardForCreateOrEditProps {
  children: any;
}

function CardForCreateOrEdit(props: CardForCreateOrEditProps) {
  return (
    <Card
      sx={{
        bgcolor: "rgba(0, 0, 0, 0)", // transparent
        border: "none",
        width: "480px",
        overflow: "visible", // prevents boolean input toggles from being clipped
      }}
    >
      {props.children}
    </Card>
  );
}

export default CardForCreateOrEdit;
