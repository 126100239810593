import { SelectInput } from "react-admin";

import { AdminUserRole, DataKind } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomCreate, CustomNumberInput } from "../custom";

const roleChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(AdminUserRole);

function AdminRoleMembershipCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.AdminRoleMembership}>
      <CustomNumberInput
        label="Admin User ID"
        source="admin_user_id"
        required
      />
      <SelectInput label="Role" source="role" choices={roleChoices} required />
    </CustomCreate>
  );
}

export default AdminRoleMembershipCreate;
