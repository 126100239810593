import { SelectInput, TextField, TextInput } from "react-admin";

import { AdminUserRole, DataKind, Resource } from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
} from "../custom";

const roleChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(AdminUserRole);

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const adminRoleMembershipFilters = [
  <TextInput label="Admin User ID" source="admin_user_id_eq" alwaysOn />,
  <SelectInput choices={roleChoices} label="Role" source="role_eq" alwaysOn />,
];

function AdminRoleMembershipList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.AdminRoleMembership}
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={true}
      filters={adminRoleMembershipFilters}
    >
      <TextField label="Id" source="id" />
      <CustomReferenceField
        label="Admin User"
        source="admin_user_id"
        reference={Resource.AdminUser}
      >
        <TextField source="email" />
      </CustomReferenceField>
      <TextField label="Role" source="role" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default AdminRoleMembershipList;
