import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { KeyboardEvent } from "react";
import { Button } from "react-admin";

import { adminAbility } from "../../abilities";
import { useMenuState } from "../../hooks";
import { FieldwireAction } from "../../types";
import { Authorize, CustomMenu } from ".";

interface ActionMenuProps {
  fieldwireActions: FieldwireAction[];
}

function ActionMenu(props: ActionMenuProps) {
  const { anchorEl, open, handleClick, onClose } = useMenuState();

  // Extract action, data kind pairs from Fieldwire actions.
  const actionDataKindPairs = props.fieldwireActions.map(
    (fieldwireAction: FieldwireAction) => ({
      action: fieldwireAction.checkPermissionForAction,
      dataKind: fieldwireAction.checkPermissionForDataKind,
    })
  );

  // Return nothing (null) if there are no Fieldwire actions specified or
  // if the current admin user does not have the permission to perform any
  // of the Fieldwire actions in the action menu.
  if (
    !props.fieldwireActions.length ||
    !adminAbility.canAdminUserPerformAny(actionDataKindPairs)
  ) {
    return null;
  }

  return (
    <>
      <Button
        label="Actions"
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handleClick}
        variant="fieldwire-secondary"
      />
      <CustomMenu
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        // Text input fields within the action menu (ex: input field within a form
        // within a modal) can lose focus when typing characters that match text in
        // the menu item(s) themselves. Stopping propagation of these keyboard events
        // prevents the inadvertent loss of focus. A similar issue was documented here:
        //
        // https://github.com/mui/material-ui/issues/19096
        onKeyDownCapture={(e: KeyboardEvent) => e.stopPropagation()}
      >
        {props.fieldwireActions.map(
          (fieldwireAction: FieldwireAction, index: number) => {
            return (
              <Authorize
                action={fieldwireAction.checkPermissionForAction}
                dataKind={fieldwireAction.checkPermissionForDataKind}
                // Normally, we would not use the index as a key. However,
                // because the items are not reordered, it is OK here.
                key={index}
                disableUnauthorizedMessage
              >
                {fieldwireAction.element}
              </Authorize>
            );
          }
        )}
      </CustomMenu>
    </>
  );
}

export default ActionMenu;
