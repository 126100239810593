import axios, { AxiosRequestConfig } from "axios";

import { storage } from "../../storage";
import { accessTokenRefresher } from ".";

const requestConfig: AxiosRequestConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

// Create a HTTP client with a specified base URL and
// headers. The base URL will be changed when we land
// in production.
const httpClient = axios.create(requestConfig);

// If authentication credentials exist in local storage, fetch
// them and set the access token to be included in any request
// from the client.
const authInfo = storage.loadAuthInfo();
if (authInfo?.access_token) {
  // https://axios-http.com/docs/config_defaults
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authInfo.access_token}`;
}

accessTokenRefresher.setRefreshMechanism(httpClient);

export default httpClient;
