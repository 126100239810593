import { Identifier, useDataProvider } from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import { AccountResponse, CustomDataProvider } from "../../../types";
import { LabelWithInfoTooltip, MutationActionMenuItem } from "..";

interface ForceUnblockAccountMutationFnVariables {
  id: Identifier;
}

interface ForceUnblockAccountMenuItemProps {
  accountRecord: AccountResponse;
}

function ForceUnblockAccountMenuItem(props: ForceUnblockAccountMenuItemProps) {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const mutationFn: MutationFunction<
    { data: AccountResponse },
    ForceUnblockAccountMutationFnVariables
  > = (variables: ForceUnblockAccountMutationFnVariables) => {
    return dataProvider.forceUnblockAccount(variables.id);
  };
  const mutationKey: MutationKey = [
    "forceUnblockAccount",
    props.accountRecord.id,
  ];
  const successMessage = "Unblocked account successfully! ✅";

  return (
    <MutationActionMenuItem
      disabled={props.accountRecord.blocked_at ? false : true}
      label={
        <LabelWithInfoTooltip
          labelText="Force Unblock Account"
          tooltipText="Unblocks regardless of whether account is over or under limit."
        />
      }
      mutationFn={mutationFn}
      mutationKey={mutationKey}
      mutationVariables={{ id: props.accountRecord.id }}
      successMessage={successMessage}
    />
  );
}

export default ForceUnblockAccountMenuItem;
