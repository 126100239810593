import mixpanelBrowser, { Mixpanel } from "mixpanel-browser";

import { storage } from "../storage";
import env from "./Env";

const TOKEN = env.mixpanel.fetch_token();

if (!TOKEN) {
  console.warn(
    "Mixpanel token is not set! Analytics won't be sent to mixpanel.com"
  );
}

// We are not able to cleanly initialize the Mixpanel instance
// with an undefined token like we are with Sentry. Therefore
// we must keep track of whether or not an instance has been
// initialized and check that before calling any Mixpanel
// methods.
let mixpanelBrowserInstance: Mixpanel | null = null;

const mixpanel = {
  init: () => {
    if (!TOKEN) {
      return;
    }

    mixpanelBrowserInstance = mixpanelBrowser.init(
      TOKEN,
      {},
      "fieldwire-admin-mixpanel-browser-instance"
    );
  },

  trackPageview: (page: string) => {
    if (!mixpanelBrowserInstance) {
      return;
    }

    const authInfo = storage.loadAuthInfo();

    mixpanelBrowserInstance.track_pageview({
      Page: page,
      "User Email": authInfo?.admin_user.email,
      "User Id": authInfo?.admin_user.id,
    });
  },
};

export default mixpanel;
