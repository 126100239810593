/**
 * This component is primarily a wrapper around React Admin's `Actions` component. The
 * name "CustomActions" is somewhat confusing as this component is mainly concerned with
 * rendering buttons for native React Admin actions such as updating, creating, etc that
 * appear at the top of CRUD pages. However this is the convention we have used for all
 * of our components that wrap React Admin components. Note that this component also
 * renders whichever React element is passed in as `fieldwireActions`. Fieldwire actions
 * are "true" custom actions such as such as unlocking a user, exporting an account, etc.
 * The React element passed in for `fieldwireActions` could be a single button, an action
 * menu, multiple buttons wrapped together, etc.
 */

import { ReactElement } from "react";
import { TopToolbar } from "react-admin";

import { Action, DataKind } from "../../../types";
import {
  Authorize,
  CustomCreateButton,
  CustomDeleteButton,
  CustomEditButton,
  CustomShowButton,
} from "..";

function CustomActions(props: {
  checkPermissionFor: DataKind;
  displayCreate: boolean;
  displayDelete: boolean;
  displayEdit: boolean;
  displayShow: boolean;
  fieldwireActions?: ReactElement;
}) {
  return (
    <TopToolbar
      sx={{
        // TODO [Nice-to-Have] [REG-1150]: Investigate if we can remove these directives.
        // Note: Without the `!important` directive this style would be
        // overriden by defaults coming from React Admin/Material UI.
        minHeight: "0 !important",
        padding: "0 !important",
      }}
    >
      {props.fieldwireActions}
      {props.displayCreate && (
        <Authorize
          action={Action.Create}
          dataKind={props.checkPermissionFor}
          disableUnauthorizedMessage
        >
          <CustomCreateButton />
        </Authorize>
      )}
      {props.displayDelete && (
        <Authorize
          action={Action.Delete}
          dataKind={props.checkPermissionFor}
          disableUnauthorizedMessage
        >
          <CustomDeleteButton />
        </Authorize>
      )}
      {props.displayEdit && (
        <Authorize
          action={Action.Update}
          dataKind={props.checkPermissionFor}
          disableUnauthorizedMessage
        >
          <CustomEditButton />
        </Authorize>
      )}
      {props.displayShow && (
        <Authorize
          action={Action.Read}
          dataKind={props.checkPermissionFor}
          disableUnauthorizedMessage
        >
          <CustomShowButton />
        </Authorize>
      )}
    </TopToolbar>
  );
}

export default CustomActions;
