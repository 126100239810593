enum Language {
  EN = "en",
  EN_GB = "en-gb",
  ZH = "zh",
  FR = "fr",
  DE = "de",
  NL = "nl",
  IT = "it",
  JA = "ja",
  KO = "ko",
  NO = "no",
  PL = "pl",
  PT_BR = "pt-br",
  RU = "ru",
  ES = "es",
  SV = "sv",
  SK = "sk",
  VI = "vi",
  EL = "el",
  CS = "cs",
  HU = "hu",
}

export { Language };
