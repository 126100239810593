import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit, CustomNumberInput } from "../custom";

function CompanyEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.Company}>
      <TextInput label="Name" source="name" required />
      <TextInput label="Email Domain" source="email_domain" required />
      <CustomNumberInput
        label="Fieldwire Rep Admin User ID"
        source="fw_rep_admin_user_id"
      />
      <TextInput label="Salesforce ID" source="salesforce_id" />
    </CustomEdit>
  );
}

export default CompanyEdit;
