import { AccessToken, AdminSignInResponse, AdminUserRole } from "../types";

const FW_AUTH_KEY = "fw_auth";

type AuthInfo = AdminSignInResponse;

const storage = {
  /**
   * Gets authentication information from local storage if it exists.
   */
  loadAuthInfo: (): AuthInfo | null => {
    const authInfoString = localStorage.getItem(FW_AUTH_KEY);
    if (authInfoString) {
      try {
        const authInfo = JSON.parse(authInfoString);
        return authInfo;
      } catch {
        // Looks like we couldn't properly parse the authInfoString!
        console.log("Could not properly parse authInfoString.");
      }
    }
    return null;
  },

  /**
   * Gets current admin user's roles from local storage if they exist.
   */
  loadRoles: (): AdminUserRole[] => {
    return storage.loadAuthInfo()?.roles ?? [];
  },

  /**
   * Removes authentication information from local storage if it exists.
   */
  removeAuthInfo: (): void => {
    localStorage.removeItem(FW_AUTH_KEY);
  },

  /**
   * Sets authentication information in local storage.
   */
  storeAuthInfo: (authObject: AuthInfo): void => {
    try {
      localStorage.setItem(FW_AUTH_KEY, JSON.stringify(authObject));
    } catch {
      // Looks like we couldn't set ${FW_AUTH_KEY} information in local storage.
      console.log(`Could not set ${FW_AUTH_KEY} info in local storage.`);
    }
  },

  /**
   * Updates access token in local storage.
   */
  updateAccessToken: (accessToken: AccessToken): void => {
    updateAuthInfoValue("access_token", accessToken);
  },

  /**
   * Updates roles in local storage.
   */
  updateRoles: (roles: AdminUserRole[]): void => {
    updateAuthInfoValue("roles", roles);
  },
};

/**
 * Update a given key-value pair of auth info in local storage.
 */
const updateAuthInfoValue = (key: keyof AuthInfo, value: any) => {
  try {
    const authInfo = storage.loadAuthInfo();
    if (authInfo) {
      const authInfoWithUpdatedValue = {
        ...authInfo,
        [key]: value,
      };
      storage.storeAuthInfo(authInfoWithUpdatedValue);
    }
  } catch {
    console.log(
      `Could not update ${key} with value ${value} in local storage.`
    );
  }
};

export default storage;
