import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  CustomDataProvider,
  UsersSignupsCountryDistributionResponse,
  WeekCount,
} from "../../../../types";
import { ChartContainer, StackedAreaChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function SignupsByCountryChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: UsersSignupsCountryDistributionResponse[];
  }>(["getUsersSignupsCountryDistribution"], () =>
    dataProvider.getUsersSignupsCountryDistribution()
  );

  // This is a special chart where we do the processing on the BE
  // to reduce the data that is shuttled around. Therefore, we do
  // not performing any processing here on the FE.

  if (data?.data) {
    return (
      <ChartContainer
        title={`Signups by Country (${analyticsUtils.calculateTotalSignupsByCountriesLastWeek(
          data.data
        )})`}
      >
        <StackedAreaChart
          // NOTE: We assume that all countries have the same dates present.
          labels={data.data[0].week_counts.map((d: WeekCount) => d.week_start)}
          // NOTE: We assume that the BE returns data for 11 countries.
          datasets={data.data.map(
            (d: UsersSignupsCountryDistributionResponse) => ({
              data: d.week_counts.map((d: WeekCount) => d.week_count),
              label: d.country_code,
            })
          )}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default SignupsByCountryChart;
