/**
 * We generally prefer to set styles, props, and other configurations directly
 * at the component level. However, there are certain cases in which that isn't
 * possible or is generally inefficient.
 */

import {
  buttonClasses,
  inputClasses,
  inputLabelClasses,
  tableRowClasses,
} from "@mui/material";
import { defaultTheme, ReferenceFieldClasses } from "react-admin";

// This module augmentation makes the TypeScript compiler "aware" of the
// custom variants for buttons we define in our theme below, and therefore
// makes them available for use in MUI buttons throughout our codebase.
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "fieldwire-danger": true;
    "fieldwire-primary": true;
    "fieldwire-secondary": true;
    "fieldwire-link": true;
  }
}

const fieldwireColors = {
  blue: {
    1: "#0037A8",
    2: "#0547CF",
    3: "#0D5BFF",
  },
  gray: {
    1: "#303234",
    2: "#696D74",
    3: "#9A9CA1",
    4: "#CFD1D6",
    5: "#EDEEEF",
    6: "#F5F6F7",
  },
  red: {
    1: "#7A0000",
    2: "#B10000",
    3: "#D2051E",
  },
  transparent: {
    1: "#FFFFFF00",
  },
  white: {
    1: "#FFFFFF",
  },
  yellow: {
    1: "#C58C36",
    2: "#FBBC64",
    3: "#FFCF8B",
    4: "#FFCD11",
  },
};

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        margin: "dense" as const,
        variant: "outlined" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense" as const,
        variant: "outlined" as const,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: fieldwireColors.gray[2],
          [`& .${inputLabelClasses.asterisk}`]: {
            color: fieldwireColors.red[3],
          },
          [`&.${inputLabelClasses.focused}`]: {
            color: fieldwireColors.blue[3],
          },
          // MUI sets a fixed max width which causes some
          // of our longer labels to get truncated. This
          // change allows all of our labels to take as
          // much width as they need.
          maxWidth: "initial",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
      },
      styleOverrides: {
        root: {
          color: fieldwireColors.gray[1],
          "& fieldset": {
            borderColor: fieldwireColors.gray[2],
          },
          [`&.${inputClasses.focused} fieldset`]: {
            // TODO [Nice-to-Have] [REG-1150]: Investigate if we can remove this directive.
            // Note: Without the `!important` directive this style would be
            // overriden by defaults coming from React Admin/Material UI.
            borderColor: fieldwireColors.blue[3] + " !important",
          },
          "& svg": {
            color: fieldwireColors.gray[1],
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontSize: "13px",
          minWidth: "80px",
          padding: "6px 12px",
          textTransform: "none" as const,
          [`.${buttonClasses.endIcon}`]: {
            marginLeft: "0",
            marginRight: "-5px",
          },
          ":hover": {
            boxShadow: "none",
          },
        },
      },
      variants: [
        {
          props: { variant: "fieldwire-primary" as const },
          style: {
            backgroundColor: fieldwireColors.blue[3],
            borderColor: fieldwireColors.blue[2],
            borderStyle: "solid",
            borderWidth: "1px",
            color: fieldwireColors.white[1],
            ":hover": {
              backgroundColor: fieldwireColors.blue[2],
              borderColor: fieldwireColors.blue[1],
            },
            // Use default border color when button is disabled.
            [`&.${buttonClasses.disabled}`]: {
              // fieldwire.blue.3 at 60% opacity.
              backgroundColor: fieldwireColors.blue[3] + "99",
              // fieldwire.blue.2 at 60% opacity.
              borderColor: fieldwireColors.blue[2] + "99",
              color: fieldwireColors.white[1],
            },
          },
        },
        {
          props: { variant: "fieldwire-secondary" as const },
          style: {
            backgroundColor: fieldwireColors.white[1],
            borderColor: fieldwireColors.gray[4],
            borderStyle: "solid",
            borderWidth: "1px",
            color: fieldwireColors.gray[1],
            ":hover": {
              backgroundColor: fieldwireColors.gray[5],
              borderColor: fieldwireColors.gray[3],
            },
          },
        },
        {
          props: { variant: "fieldwire-danger" as const },
          style: {
            backgroundColor: fieldwireColors.red[3],
            borderColor: fieldwireColors.red[2],
            borderStyle: "solid",
            borderWidth: "1px",
            color: fieldwireColors.white[1],
            ":hover": {
              backgroundColor: fieldwireColors.red[2],
              borderColor: fieldwireColors.red[1],
            },
          },
        },
        {
          props: { variant: "fieldwire-link" as const },
          style: {
            background: "none",
            border: "none",
            color: fieldwireColors.blue[3],
            minWidth: "0",
            padding: "0",
            ":hover": {
              color: fieldwireColors.blue[2],
              background: "none",
              border: "none",
              textDecoration: "underline",
            },
            [`&.${buttonClasses.disabled}`]: {
              // 65% opacity.
              color: fieldwireColors.blue[3] + "A6",
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: fieldwireColors.gray[5],
          borderRadius: "3px",
          borderStyle: "solid",
          borderWidth: "1px",
          boxShadow: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: fieldwireColors.blue[3],
          fontSize: "14px",
          textDecoration: "none",
          ":hover": {
            color: fieldwireColors.blue[2],
            textDecoration: "underline",
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // We put this style here instead of in the Datagrid
          // component so that we don't have to use the !important
          // directive as the default style from the MuiTableRow
          // would override the style in the Datagrid component.
          [`&.${tableRowClasses.hover}:hover`]: {
            // 8% opacity.
            backgroundColor: fieldwireColors.blue[3] + "14",
          },
        },
      },
    },
    RaReferenceField: {
      styleOverrides: {
        root: {
          [`& .${ReferenceFieldClasses.link}>*`]: {
            // TODO [Nice-to-Have] [REG-1150]: Investigate if we can remove this directive.
            // Ensures that all text within a ReferenceField component is always the proper
            // shade of blue.
            color: fieldwireColors.blue[3] + " !important",
            ":hover": {
              // TODO [Nice-to-Have] [REG-1150]: Investigate if we can remove this directive.
              color: fieldwireColors.blue[2] + " !important",
              textDecoration: "underline",
            },
          },
        },
      },
    },
    RaMenu: {
      styleOverrides: {
        root: {
          margin: "0",
        },
      },
    },
  },
  palette: {
    ...defaultTheme.palette,
    // Colors per the Fieldwire design system here:
    // https://www.figma.com/file/nIMMrRok4pqAK7FsTkHQCr/Fieldwire-Design-System?node-id=2%3A85
    fieldwire: {
      blue: {
        1: fieldwireColors.blue[1],
        2: fieldwireColors.blue[2],
        3: fieldwireColors.blue[3],
      },
      gray: {
        1: fieldwireColors.gray[1],
        2: fieldwireColors.gray[2],
        3: fieldwireColors.gray[3],
        4: fieldwireColors.gray[4],
        5: fieldwireColors.gray[5],
        6: fieldwireColors.gray[6],
      },
      red: {
        1: fieldwireColors.red[1],
        2: fieldwireColors.red[2],
        3: fieldwireColors.red[3],
      },
      white: {
        1: fieldwireColors.white[1],
      },
      yellow: {
        1: fieldwireColors.yellow[1],
        2: fieldwireColors.yellow[2],
        3: fieldwireColors.yellow[3],
        4: fieldwireColors.yellow[4],
      },
    },
  },
  sidebar: {
    // Ensures that the side menu collapses completely when toggled close.
    closedWidth: "0",
    // `256px` is chosen so the text for longest menu item isn't cut off.
    width: "256px",
  },
};

export default theme;
