import { Region, Resource } from "../types";

const resourceUtils = {
  boxTokenResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuBoxToken;
      case Region.Us:
        return Resource.UsBoxToken;
    }
  },

  demoProjectTemplateResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuDemoProjectTemplate;
      case Region.Us:
        return Resource.UsDemoProjectTemplate;
    }
  },

  dropboxTokenResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuDropboxToken;
      case Region.Us:
        return Resource.UsDropboxToken;
    }
  },

  manualWorkerResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuManualWorker;
      case Region.Us:
        return Resource.UsManualWorker;
    }
  },

  microsoftTokenResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuMicrosoftToken;
      case Region.Us:
        return Resource.UsMicrosoftToken;
    }
  },

  projectResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuProject;
      case Region.Us:
        return Resource.UsProject;
    }
  },

  syncErrorResourceFor: (region: Region) => {
    switch (region) {
      case Region.Eu:
        return Resource.EuSyncError;
      case Region.Us:
        return Resource.UsSyncError;
    }
  },
};

export default resourceUtils;
