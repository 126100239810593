import { MenuItem, menuItemClasses, MenuItemProps } from "@mui/material";

function CustomMenuItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      sx={{
        borderBottomColor: "fieldwire.gray.5",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        color: "fieldwire.gray.1",
        fontSize: "13px",
        padding: "6px 12px",
        ":last-child": {
          border: "none",
        },
        [`&.${menuItemClasses.disabled}`]: {
          color: "fieldwire.gray.4",
          opacity: 1,
          svg: {
            // For icons.
            color: "fieldwire.gray.4",
          },
        },
      }}
    >
      {props.children}
    </MenuItem>
  );
}

export default CustomMenuItem;
