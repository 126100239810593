enum EnterprisePlanCategory {
  PerUser = "per_user",
  ProjectUnlimited = "project_unlimited",
  RegionalUnlimited = "regional_unlimited",
  CompanyUnlimited = "company_unlimited",
  PerUserNoOverages = "per_user_no_overages",
}

enum EnterprisePlanKind {
  PremierPlan = "premier_plan",
  ProPlan = "pro_plan",
  BusinessPlan = "business_plan",
  BusinessPlusPlan = "business_plus_plan",
}

export { EnterprisePlanCategory, EnterprisePlanKind };
