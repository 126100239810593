import { ListItemIcon, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { ReactElement, useState } from "react";
import { MenuItemLinkClasses } from "react-admin";
import { useMatch } from "react-router-dom";

import euFlagColor from "../../../assets/flag-eu-color.svg";
import euFlagGray from "../../../assets/flag-eu-gray.svg";
import usFlagColor from "../../../assets/flag-us-color.svg";
import usFlagGray from "../../../assets/flag-us-gray.svg";
import {
  menuItemLinkSx as defaultMenuItemLinkSx,
  menuItemSxForActiveOrHover,
  menuItemSxForBorder,
  menuItemSxForLeftIcon,
  menuItemSxForText,
} from "./Common";

const flagContainerSx = {
  // Take enough space from parent to do a job as a click target
  height: "100%",
  width: "64px",

  // Helps aligning contained items centrally
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  // Refers to the currently hovered menu item.
  ...menuItemSxForActiveOrHover,

  // Refers to any img tags with the menu item
  "& img": {
    height: "13px",
    width: "20px",
  },
};

const menuItemLinkSx = {
  // Replicate required menu items styles for the container
  // taking care of clicks outside of the flags
  ...menuItemSxForLeftIcon,
  ...menuItemSxForText,

  // Setup dimensions & layout
  height: "100%",
  width: "100%",

  // Menu item isn't clickable (only the flags are)
  cursor: "default",

  "&:hover": {
    backgroundColor: "transparent",
  },
};

const outerContainerSx = {
  // Replicate required menu items styles for the outermost
  // container
  ...menuItemSxForActiveOrHover,
  ...menuItemSxForBorder,

  // Setup dimensions & layout
  height: defaultMenuItemLinkSx.height,
  display: "flex",
};

interface MultiRegionMenuItemLinkProps {
  leftIcon?: ReactElement;
  pathForEu: string;
  pathForUs: string;
  primaryText: string;
}

function MultiRegionMenuItemLink(props: MultiRegionMenuItemLinkProps) {
  const { leftIcon, pathForEu, pathForUs, primaryText } = props;

  // Matching wants `#` prefix to be removed. Using `false` for end
  // so the menu will be highlighted for all CRUD actions
  const matchEu = useMatch({ path: pathForEu.substring(1), end: false });
  const matchUs = useMatch({ path: pathForUs.substring(1), end: false });

  // On hover we want to show the colored versions of the flag. Let's
  // setup state to track that. For hover, instead of using CSS, we are
  // using event handlers so all flag coloring decisions can live together
  const [isEuHover, setIsEuHover] = useState(false);
  const [isUsHover, setIsUsHover] = useState(false);

  // Use colored flag for active region & grayscale for the other
  const euFlag = isEuHover || matchEu ? euFlagColor : euFlagGray;
  const usFlag = isUsHover || matchUs ? usFlagColor : usFlagGray;

  return (
    <Box
      className={clsx({
        // Mark the whole menu as active if either of the regions match
        [MenuItemLinkClasses.active]: !!matchEu || !!matchUs,
      })}
      sx={outerContainerSx}
    >
      <MenuItem sx={menuItemLinkSx}>
        {/* Use the same style as what react-admin uses for `MenuItemLink` */}
        {leftIcon && (
          <ListItemIcon className={MenuItemLinkClasses.icon}>
            {leftIcon}
          </ListItemIcon>
        )}
        {primaryText}
      </MenuItem>
      <Link
        href={pathForUs}
        sx={flagContainerSx}
        onMouseEnter={() => setIsUsHover(true)}
        onMouseLeave={() => setIsUsHover(false)}
      >
        <img src={usFlag} alt="US flag" />
      </Link>
      <Link
        href={pathForEu}
        sx={flagContainerSx}
        onMouseEnter={() => setIsEuHover(true)}
        onMouseLeave={() => setIsEuHover(false)}
      >
        <img src={euFlag} alt="EU flag" />
      </Link>
    </Box>
  );
}

export default MultiRegionMenuItemLink;
