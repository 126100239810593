import add from "date-fns/add";
import format from "date-fns/format";
import isEqual from "date-fns/isEqual";
import parseISO from "date-fns/parseISO";

import {
  ActiveUserCountsForFinancesResponse,
  ActiveUserCountsForMarketingResponse,
  UsersSignupsCountryDistributionResponse,
} from "../../../types";

interface WithDate {
  date: string;
}

const analyticsUtils = {
  /**
   * Given an array of numbers, where null values are treated as zero,
   * calculate the seven period trailing sum for each number. For the
   * first six numbers, the seven period trailing sum is simply the
   * cumulative sum up until, and including, that point.
   *
   * ```
   *  Input: [null, 1, 2, null, null, 3, null, 4, 5, 6, null]
   *  Output: [0, 1, 3, 3, 3, 6, 6, 10, 14, 18, 18]
   * ```
   */
  calculateSevenPeriodTrailingSum: (array: (number | null)[]) => {
    const period = 7;
    const trailingSums: number[] = [];
    let trailingSum = 0;

    // For i < 7, the seven period trailing sum of array[i]
    // is equal to array[0] + array[1] + ... + array[i]. In
    // other words, it is just the cumulative sum up until,
    // and including, that point.
    for (let i = 0; i < Math.min(period, array.length); i++) {
      trailingSum += array[i] ?? 0;
      trailingSums.push(trailingSum);
    }

    // Using the sliding window technique, update the sum
    // by subtracting the old leftmost element and adding
    // the new rightmost element.
    for (let i = period; i < array.length; i++) {
      trailingSum -= array[i - period] ?? 0;
      trailingSum += array[i] ?? 0;
      trailingSums.push(trailingSum);
    }

    return trailingSums;
  },

  /**
   * Given an array of ActiveUserCountsForFinancesResponse objects,
   * calculate the total MRR value for the nth day. If n is negative,
   * we start from the back (-1 is the last day, -2 is the second to
   * last day, ...). If a field used to calculate the value is null,
   * use 0 in its place. If the array is empty, return 0. Finally,
   * we expect the array to be ordered by date.
   */
  calculateTotalMrrNthDay(
    orderedActiveUserCountsForFinances: ActiveUserCountsForFinancesResponse[],
    n: number
  ) {
    const nthDay = orderedActiveUserCountsForFinances.at(n);
    let totalMrrNthDay = 0;

    if (nthDay) {
      totalMrrNthDay +=
        (nthDay.mrr_pro ?? 0) +
        (nthDay.mrr_business ?? 0) +
        (nthDay.mrr_business_plus ?? 0) +
        (nthDay.mrr_enterprise_per_user ?? 0) +
        (nthDay.mrr_enterprise_per_project ?? 0);
    }

    return totalMrrNthDay;
  },

  /**
   * Given an array of ActiveUserCountsForFinancesResponse objects,
   * calculate the total paying users value for the last day. If a
   * field used to calculate the value is null, use 0 in its place.
   * If the array is empty, return 0. Finally, we expect the array
   * to be ordered by date.
   */
  calculateTotalPayingUsersLastDay(
    orderedActiveUserCountsForFinances: ActiveUserCountsForFinancesResponse[]
  ) {
    const lastDay = orderedActiveUserCountsForFinances.at(-1);
    let totalPayingUsersLastDay = 0;

    if (lastDay) {
      totalPayingUsersLastDay +=
        (lastDay.paying_pro ?? 0) +
        (lastDay.paying_business ?? 0) +
        (lastDay.paying_business_plus ?? 0) +
        (lastDay.paying_enterprise ?? 0);
    }

    return totalPayingUsersLastDay;
  },

  /**
   * Given an array of UsersSignupsCountryDistributionResponse objects,
   * calculate the total signups by all countries value for the last week.
   * If a field used to calculate the value is null, use 0 in its place.
   * If the array is empty, return 0.
   */
  calculateTotalSignupsByCountriesLastWeek(
    usersSignupsCountryDistributions: UsersSignupsCountryDistributionResponse[]
  ) {
    let totalSignupsByCountriesLastWeek = 0;

    usersSignupsCountryDistributions.forEach(
      (d: UsersSignupsCountryDistributionResponse) => {
        totalSignupsByCountriesLastWeek +=
          d.week_counts.at(-1)?.week_count ?? 0;
      }
    );

    return totalSignupsByCountriesLastWeek;
  },

  /**
   * Given an array of ActiveUserCountsForMarketingResponse objects,
   * calculate the total signups by source value for the last day. If
   * a field used to calculate the value is null, use 0 in its place.
   * If the array is empty, return 0. Finally, we expect the array to
   * be ordered by date.
   */
  calculateTotalSignupsBySourceLastDay(
    orderedActiveUserCountsForMarketing: ActiveUserCountsForMarketingResponse[]
  ) {
    const lastDay = orderedActiveUserCountsForMarketing.at(-1);
    let totalSignupsBySourceLastDay = 0;

    if (lastDay) {
      totalSignupsBySourceLastDay +=
        (lastDay.android_signups_unknown_source ?? 0) +
        (lastDay.ipad_signups_unknown_source ?? 0) +
        (lastDay.ipad_signups_paid_source ?? 0) +
        (lastDay.iphone_signups_unknown_source ?? 0) +
        (lastDay.iphone_signups_paid_source ?? 0) +
        (lastDay.web_signups_unknown_source ?? 0) +
        (lastDay.web_signups_paid_source ?? 0);
    }

    return totalSignupsBySourceLastDay;
  },

  /**
   * Given an array of objects of type `T`, and a desired empty object
   * state, create a copy of the array and sort it by date in ascending
   * order. Then for each missing date, insert { date: `d`, `...` } where
   * `d` is the missing date and `...` are the key/value pairs of the empty
   * object state.
   *
   * The reason why we require an empty object state is so all objects
   * of the returned array are of type `T`.
   *
   * For example, suppose we have the following array:
   *
   * ```
   * [
   *  { date: "2022-08-10", count: 3 },
   *  { date: "2022-08-12", count: 4 }
   * ]
   * ```
   *
   * and our empty object state is:
   *
   * ```
   * { date: "", count: null }
   * ```
   *
   * We would return:
   *
   * ```
   * [
   *  { date: "2022-08-10", count: 3 },
   *  { date: "2022-08-11", count: null },
   *  { date: "2022-08-12", count: 4 }
   * ]
   * ```
   */
  fillForMissingDates: <T extends WithDate>(array: T[], emptyObject: T) => {
    // Sort array in case it isn't already sorted.
    const sortedArray = analyticsUtils.sortByDate(array);

    const filled: T[] = [];

    for (let item of sortedArray) {
      if (filled.length === 0) {
        filled.push(item);
      } else {
        // We've checked if `filled` is empty through the `if`
        // block. Therefore, the last item should always exist.
        let previousDate = parseISO(filled.at(-1)!.date);

        // NOTE: It is very important that `parseISO` is used
        // to create date objects, both on this line and the
        // previous one, otherwise we will run into issues
        // with timezones.
        const currentDate = parseISO(item.date);

        // While the previous and current dates are not a day
        // apart (AKA there is a missing date), insert missing
        // date along with empty state key/values.
        while (!isEqual(add(previousDate, { days: 1 }), currentDate)) {
          previousDate = add(previousDate, { days: 1 });
          const formattedDate = format(previousDate, "yyyy-MM-dd");
          const newEmptyObject = Object.assign({}, emptyObject);
          newEmptyObject.date = formattedDate;
          filled.push(newEmptyObject);
        }

        filled.push(item);
      }
    }

    return filled;
  },

  /**
   * Returns copy of array's last `n` items. If `n`
   * is less than 1 or the array is empty, a copy
   * of the entire array is returned.
   */
  getLastNItems: (array: any[], n: number) => {
    if (n < 1) {
      return array.slice();
    }

    return array.slice(-n);
  },

  /**
   * Returns `a` * `b` if both `a` and `b` are non-null, otherwise return null.
   */
  multiplyOrNull: (a: number | null, b: number | null) => {
    if (a === null || b === null) {
      return null;
    }

    return a * b;
  },

  /**
   * Calls, in order, the following methods on `array`:
   *
   * 1. `analyticsUtils.sortByDate`
   *
   * 2. `analyticsUtils.fillForMissingDates`
   *
   * 3. `analyticsUtils.getLastNItems`
   */
  processData: <T extends WithDate>(array: T[], emptyObject: T, n: number) => {
    // Sort the data by date in ascending order, in case the BE doesn't sort it.
    let processedData = analyticsUtils.sortByDate(array);

    // For each missing date `d`, insert { date: `d`, `...` } in its place
    // where `d` is the missing date and `...` are the key/value pairs of
    // the empty object state. `fillForMissingDates` also sorts by date
    // in case the array we pass to it isn't already sorted!
    processedData = analyticsUtils.fillForMissingDates(
      processedData,
      emptyObject
    );

    // Only consider the last `n` days of data, in case the BE returns more.
    processedData = analyticsUtils.getLastNItems(processedData, n);

    return processedData;
  },

  /**
   * Returns copy of array sorted in **ascending** order by date.
   */
  sortByDate: <T extends WithDate>(array: T[]) => {
    const arrayCopy = array.slice();

    arrayCopy.sort((a: WithDate, b: WithDate) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();

      return aDate - bDate;
    });

    return arrayCopy;
  },
};

export default analyticsUtils;
