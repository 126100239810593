/**
 * Renders some plain text followed by an info icon.
 *
 * The info icon renders a tooltip when the current admin user hovers above it.
 *
 * Both the plain text and the tooltip text are set by the user of the component.
 */

import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

interface LabelWithInfoTooltipProps {
  labelText: string;
  tooltipText: string;
}

function LabelWithInfoTooltip(props: LabelWithInfoTooltipProps) {
  return (
    <Box
      sx={{ alignItems: "center", display: "inline-flex", gap: "5px" }}
      // We specify the underlying component as <span> because the LabelWithInfoTooltip
      // component is used in a variety of inline settings and <span> is the most generic,
      // and inoffensive, inline container.
      component="span"
    >
      <span>{props.labelText}</span>
      <Tooltip title={props.tooltipText}>
        <InfoIcon
          sx={{ color: "fieldwire.gray.3" }}
          // We specify the font size as "inherit" so the size of the info icon matches
          // the size of the text in the context which it is used. Otherwise, the info
          // icon could be bigger or smaller than its surrounding text.
          fontSize="inherit"
        />
      </Tooltip>
    </Box>
  );
}

export default LabelWithInfoTooltip;
