import {
  RecordContextProvider,
  TextField,
  useRecordContext,
} from "react-admin";

import { EnterprisePlanInvoiceResponse } from "../../../types";
import { componentUtils } from "../../component_utils";

interface EnterprisePlanInvoiceMrrFieldProps {
  label: string;
  // Technically speaking, this sortable prop is not necessary. Due to the way
  // React Admin works, and the way this custom field is constructed, the field
  // will never be sortable. However, we allow the sortable prop to indicate that
  // a field, generally speaking, should be considered sortable unless the sortable
  // prop is set to false. This is the behavior that React Admin enforces for its
  // native fields.
  sortable?: false;
}

function EnterprisePlanInvoiceMrrField(
  _props: EnterprisePlanInvoiceMrrFieldProps
) {
  const record = useRecordContext<EnterprisePlanInvoiceResponse>();

  if (record) {
    return (
      <RecordContextProvider
        value={{
          id: record.id,
          mrr: componentUtils
            .calculateMrrForEnterprisePlanInvoice(record)
            .toFixed(2),
        }}
      >
        <TextField source="mrr" />
      </RecordContextProvider>
    );
  } else {
    return null;
  }
}

export default EnterprisePlanInvoiceMrrField;
