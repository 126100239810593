import { useEffect } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route, useLocation } from "react-router-dom";

import { authProvider, dataProvider, queryClient } from "./api";
import { AccountHistory } from "./components/account-history";
import { Dashboard } from "./components/analytics";
import { AuditLogsProjects } from "./components/audit-logs";
import {
  AdminRoleMembershipCreate,
  AdminUserCreate,
  CompanyCreate,
  ConfigVariableCreate,
  DemoProjectTemplateCreate,
  DemoProjectUserCreate,
  EnterprisePlanCreate,
  EnterprisePlanInvoiceCreate,
  InAppPurchaseCreate,
  JobInfoCreate,
  ManualWorkerCreate,
  SsoInfoCreate,
  StressTestingProjectCreate,
} from "./components/create";
import { CustomLayout, CustomLogin, NotFound } from "./components/custom";
import {
  AccountEdit,
  AdminUserEdit,
  ApiKeyEdit,
  CompanyEdit,
  ConfigVariableEdit,
  DemoProjectTemplateEdit,
  DemoProjectUserEdit,
  EnterprisePlanEdit,
  EnterprisePlanInvoiceEdit,
  InAppPurchaseEdit,
  JobInfoEdit,
  ProjectEdit,
  SsoInfoEdit,
  UserEdit,
} from "./components/edit";
import { IosReleaseStatus } from "./components/ios-release-status";
import {
  AccountList,
  AdminRoleMembershipList,
  AdminUserList,
  ApiKeyList,
  CanceledEnterpriseOwnerList,
  CanceledInAppPurchaseUserList,
  CanceledStripeOwnerList,
  ChargeList,
  CompanyList,
  ConfigVariableList,
  DemoProjectTemplateList,
  DemoProjectUserList,
  EnterprisePlanInvoiceList,
  EnterprisePlanList,
  InAppPurchaseList,
  JobInfoList,
  PayingEnterpriseOwnerList,
  PayingInAppPurchaseUserList,
  PayingStripeOwnerList,
  ProjectList,
  SsoInfoList,
  SyncErrorList,
  SystemConfigurationList,
  UserList,
} from "./components/list";
import {
  AccountShow,
  AdminRoleMembershipShow,
  AdminUserShow,
  ApiKeyShow,
  BoxTokenShow,
  ChargeShow,
  CompanyShow,
  ConfigVariableShow,
  DemoProjectTemplateShow,
  DemoProjectUserShow,
  DropboxTokenShow,
  EnterprisePlanInvoiceShow,
  EnterprisePlanShow,
  InAppPurchaseShow,
  JobInfoShow,
  MicrosoftTokenShow,
  ProjectShow,
  SsoInfoShow,
  SyncErrorShow,
  UserShow,
} from "./components/show";
import { Sidekiq } from "./components/sidekiq";
import { SystemStatus } from "./components/system-status";
import { theme } from "./config";
import { store } from "./store";
import { Region, Resource as FwResource } from "./types";
import {
  PATH_DASHBOARD,
  PATH_IOS_RELEASE_STATUS,
  PATH_SIDEKIQ,
  PATH_SYSTEM_STATUS,
  PATH_TEMPLATE_AUDIT_LOG_FOR_US_PROJECT,
  PATH_TEMPLATE_US_HISTORY_FOR_ACCOUNT,
} from "./utils/Routes";

function App() {
  // We are allowed to utilize the `useLocation` hook here
  // because `App` is wrapped in a `HashRouter` in index.tsx.
  const { pathname } = useLocation();

  // Scroll to the top of the page when the path changes.
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      queryClient={queryClient}
      catchAll={NotFound}
      layout={CustomLayout}
      loginPage={CustomLogin}
      store={store}
      theme={theme}
      disableTelemetry
      requireAuth
    >
      {/*
        NOTE: React Admin uses the first <Resource /> as the default list page
        when someone first visits the application. Therefore, we put the Users
        resource first so it is the default. This also allows us to be in line
        with Admin V1.
      */}
      <Resource
        name={FwResource.User}
        options={{ label: "Users" }}
        edit={UserEdit}
        list={UserList}
        show={UserShow}
      />
      <Resource
        name={FwResource.Account}
        options={{ label: "Accounts" }}
        edit={AccountEdit}
        list={AccountList}
        show={AccountShow}
      />
      <Resource
        name={FwResource.AdminRoleMembership}
        options={{ label: "Admin Role Memberships" }}
        create={AdminRoleMembershipCreate}
        list={AdminRoleMembershipList}
        show={AdminRoleMembershipShow}
      />
      <Resource
        name={FwResource.AdminUser}
        options={{ label: "Admin Users" }}
        create={AdminUserCreate}
        edit={AdminUserEdit}
        list={AdminUserList}
        show={AdminUserShow}
      />
      <Resource
        name={FwResource.ApiKey}
        options={{ label: "Api Keys" }}
        edit={ApiKeyEdit}
        list={ApiKeyList}
        show={ApiKeyShow}
      />
      <Resource
        name={FwResource.Charge}
        options={{ label: "Charges" }}
        list={ChargeList}
        show={ChargeShow}
      />
      <Resource
        name={FwResource.Company}
        options={{ label: "Companies" }}
        create={CompanyCreate}
        edit={CompanyEdit}
        list={CompanyList}
        show={CompanyShow}
      />
      <Resource
        name={FwResource.EuConfigVariable}
        options={{ label: "Config Variables - EU" }}
        create={ConfigVariableCreate}
        edit={ConfigVariableEdit}
        list={ConfigVariableList}
        show={ConfigVariableShow}
      />
      <Resource
        name={FwResource.UsConfigVariable}
        options={{ label: "Config Variables - US" }}
        create={ConfigVariableCreate}
        edit={ConfigVariableEdit}
        list={ConfigVariableList}
        show={ConfigVariableShow}
      />
      <Resource
        name={FwResource.EuDemoProjectTemplate}
        options={{ label: "Demo Project Templates - EU" }}
        create={DemoProjectTemplateCreate}
        edit={DemoProjectTemplateEdit}
        list={DemoProjectTemplateList}
        show={DemoProjectTemplateShow}
      />
      <Resource
        name={FwResource.UsDemoProjectTemplate}
        options={{ label: "Demo Project Templates - US" }}
        create={DemoProjectTemplateCreate}
        edit={DemoProjectTemplateEdit}
        list={DemoProjectTemplateList}
        show={DemoProjectTemplateShow}
      />
      <Resource
        name={FwResource.EuDemoProjectUser}
        options={{ label: "Demo Project Users - EU" }}
        create={DemoProjectUserCreate}
        edit={DemoProjectUserEdit}
        list={DemoProjectUserList}
        show={DemoProjectUserShow}
      />
      <Resource
        name={FwResource.UsDemoProjectUser}
        options={{ label: "Demo Project Users - US" }}
        create={DemoProjectUserCreate}
        edit={DemoProjectUserEdit}
        list={DemoProjectUserList}
        show={DemoProjectUserShow}
      />
      <Resource
        name={FwResource.EnterprisePlanInvoice}
        options={{ label: "Enterprise Plan Invoices" }}
        create={EnterprisePlanInvoiceCreate}
        edit={EnterprisePlanInvoiceEdit}
        list={EnterprisePlanInvoiceList}
        show={EnterprisePlanInvoiceShow}
      />
      <Resource
        name={FwResource.EnterprisePlan}
        options={{ label: "Enterprise Plans" }}
        create={EnterprisePlanCreate}
        edit={EnterprisePlanEdit}
        list={EnterprisePlanList}
        show={EnterprisePlanShow}
      />
      <Resource
        name={FwResource.EuFeatureFlag}
        options={{ label: "System Configuration - EU" }}
        list={SystemConfigurationList}
      />
      <Resource
        name={FwResource.UsFeatureFlag}
        options={{ label: "System Configuration - US" }}
        list={SystemConfigurationList}
      />
      <Resource
        name={FwResource.InAppPurchase}
        options={{ label: "In App Purchases" }}
        create={InAppPurchaseCreate}
        edit={InAppPurchaseEdit}
        list={InAppPurchaseList}
        show={InAppPurchaseShow}
      />
      <Resource
        name={FwResource.JobInfo}
        options={{ label: "Job Infos" }}
        create={JobInfoCreate}
        edit={JobInfoEdit}
        list={JobInfoList}
        show={JobInfoShow}
      />
      <Resource
        name={FwResource.UsManualWorker}
        options={{ label: "Manual Workers - US" }}
        create={<ManualWorkerCreate region={Region.Us} />}
      />
      <Resource
        name={FwResource.EuManualWorker}
        options={{ label: "Manual Workers - EU" }}
        create={<ManualWorkerCreate region={Region.Eu} />}
      />
      <Resource
        name={FwResource.EuProject}
        options={{ label: "Projects - EU" }}
        edit={ProjectEdit}
        list={ProjectList}
        show={<ProjectShow region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsProject}
        options={{ label: "Projects - US" }}
        edit={ProjectEdit}
        list={ProjectList}
        show={<ProjectShow region={Region.Us} />}
      />
      <Resource
        name={FwResource.EuStressTestingProject}
        options={{ label: "Project for Stress Testing - EU" }}
        create={<StressTestingProjectCreate region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsStressTestingProject}
        options={{ label: "Project for Stress Testing - US" }}
        create={<StressTestingProjectCreate region={Region.Us} />}
      />
      <Resource
        name={FwResource.SsoInfo}
        options={{ label: "Sso Infos" }}
        create={SsoInfoCreate}
        edit={SsoInfoEdit}
        list={SsoInfoList}
        show={SsoInfoShow}
      />
      <Resource
        name={FwResource.EuSyncError}
        options={{ label: "Sync Errors - EU" }}
        list={SyncErrorList}
        show={<SyncErrorShow region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsSyncError}
        options={{ label: "Sync Errors - US" }}
        list={SyncErrorList}
        show={<SyncErrorShow region={Region.Us} />}
      />
      <Resource
        name={FwResource.PayingStripeOwner}
        options={{ label: "Paying Users (Stripe)" }}
        list={PayingStripeOwnerList}
      />
      <Resource
        name={FwResource.CanceledStripeOwner}
        options={{ label: "Paying Users (Stripe Canceled)" }}
        list={CanceledStripeOwnerList}
      />
      <Resource
        name={FwResource.PayingEnterpriseOwner}
        options={{ label: "Enterprise Accounts" }}
        list={PayingEnterpriseOwnerList}
      />
      <Resource
        name={FwResource.CanceledEnterpriseOwner}
        options={{ label: "Enterprise Accounts (Canceled)" }}
        list={CanceledEnterpriseOwnerList}
      />
      <Resource
        name={FwResource.PayingInAppPurchaseUser}
        options={{ label: "Paying Users (In App)" }}
        list={PayingInAppPurchaseUserList}
      />
      <Resource
        name={FwResource.CanceledInAppPurchaseUser}
        options={{ label: "Paying Users (In App Canceled)" }}
        list={CanceledInAppPurchaseUserList}
      />
      {/* NOTE: Will not show in side menu as resource doesn't have a list page. */}
      <Resource
        name={FwResource.EuBoxToken}
        options={{ label: "Box Tokens - EU" }}
        show={<BoxTokenShow region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsBoxToken}
        options={{ label: "Box Tokens - US" }}
        show={<BoxTokenShow region={Region.Us} />}
      />
      <Resource
        name={FwResource.EuDropboxToken}
        options={{ label: "Dropbox Tokens - EU" }}
        show={<DropboxTokenShow region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsDropboxToken}
        options={{ label: "Dropbox Tokens - US" }}
        show={<DropboxTokenShow region={Region.Us} />}
      />
      <Resource
        name={FwResource.EuMicrosoftToken}
        options={{ label: "Microsoft Tokens - EU" }}
        show={<MicrosoftTokenShow region={Region.Eu} />}
      />
      <Resource
        name={FwResource.UsMicrosoftToken}
        options={{ label: "Microsoft Tokens - US" }}
        show={<MicrosoftTokenShow region={Region.Us} />}
      />
      {/* End of resources without list page. */}
      <CustomRoutes>
        <Route
          path={PATH_TEMPLATE_US_HISTORY_FOR_ACCOUNT}
          element={<AccountHistory />}
        />
        <Route
          path={PATH_TEMPLATE_AUDIT_LOG_FOR_US_PROJECT}
          element={<AuditLogsProjects />}
        />
        <Route path={PATH_DASHBOARD} element={<Dashboard />} />
        <Route path={PATH_IOS_RELEASE_STATUS} element={<IosReleaseStatus />} />
        <Route path={PATH_SIDEKIQ} element={<Sidekiq />} />
        <Route path={PATH_SYSTEM_STATUS} element={<SystemStatus />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
