import { country, CountryInfo, subdivision } from "iso-3166-2";

import { CountryCodeRequiringState, State } from "../types";

type StateCache = {
  [key in CountryCodeRequiringState]?: State[];
};

const stateCache: StateCache = {};

function computeStates(countryCode: CountryCodeRequiringState): State[] {
  // Get an object that looks like the following:
  // {
  //  code: "US",
  //  name: "United States"
  //  sub: [
  //     "US-AK": {
  //        type: "State",
  //        name: "Alaska"
  //     },
  //     "US-AL": {
  //        type: "State",
  //        name: "Alabama"
  //     },
  //     ...
  //     "US-WY": {
  //        type: "State",
  //        name: "Wyoming"
  //     }
  //  ]
  // }
  const countryInfo = country(countryCode);

  // Map the country info object to a list of state objects that
  // are sorted by name. The list looks like the following:
  // [
  //    {
  //      code: "US-AL",
  //      name: "Alabama"
  //    },
  //    {
  //      code: "US-AK",
  //      name: "Alaska"
  //    },
  //    ...
  //    {
  //      code: "US-WY",
  //      name: "Wyoming"
  //    }
  // ]
  const states = generateSortedStatesArray(countryInfo);

  return states;
}

function generateSortedStatesArray(
  countryInfo: CountryInfo.Full | null
): State[] {
  if (!countryInfo) {
    return [];
  }

  const countryCodePrefix = countryInfo.code + "-";
  const statesArray = Object.keys(countryInfo.sub).map((stateCode: string) => {
    return {
      name: countryInfo.sub[stateCode].name,
      // Remove country code prefix (ex: "US-") from state code if applicable.
      code: stateCode.startsWith(countryCodePrefix)
        ? stateCode.slice(countryCodePrefix.length)
        : stateCode,
    };
  });

  statesArray.sort((a: State, b: State) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });

  return statesArray;
}

function getStates(countryCode: CountryCodeRequiringState): State[] {
  if (stateCache[countryCode]) {
    // @ts-expect-error
    return stateCache[countryCode];
  }

  stateCache[countryCode] = computeStates(countryCode);

  // @ts-expect-error
  return stateCache[countryCode];
}

export { getStates, subdivision };
