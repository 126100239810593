import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {
  Button,
  HttpError as ReactAdminHttpError,
  useDataProvider,
  useNotify,
} from "react-admin";

import { urlBuilder } from "../../api";
import { Action, CustomDataProvider, DataKind, Region } from "../../types";
import { Authorize, TitleRow } from "../custom";

interface OpenSidekiqProps {
  region: Region;
}

function OpenSidekiqButton(props: OpenSidekiqProps) {
  const dataProvider = useDataProvider<CustomDataProvider>();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .getAuthCookieForSidekiq(props.region)
      .then(() => {
        let sidekiqUrl = urlBuilder.regional(props.region, "sidekiq");
        window.open(sidekiqUrl, "_blank");
      })
      .catch((e: ReactAdminHttpError) => {
        notify(e.message, { type: "warning" });
      });
  };

  return (
    <Button
      label={`Open Sidekiq - ${props.region.toUpperCase()}`}
      onClick={handleClick}
      variant="fieldwire-primary"
    />
  );
}

function Sidekiq() {
  const spacing = "16px";

  return (
    // We don't have much control over the sidekiq UI & so we depend on
    // our backend to disallow any write actions for roles that have
    // rights only to read sidekiq data
    <Authorize action={Action.Read} dataKind={DataKind.Sidekiq}>
      <Box>
        <TitleRow title="Sidekiq" />
        <Box sx={{ display: "flex", flexDirection: "column", gap: spacing }}>
          <Alert severity="warning">
            <b>
              You are very close to Sidekiq! However, before you proceed there
              are two things you should know:
            </b>
            <ol>
              <li>
                Due to technical limitations, your session will last a maximum
                of 8 hours. When your session expires, please come back to this
                tab and click the above button again.
              </li>
              <li>
                Please ensure that third-party cookies are enabled. This is
                needed to ensure that authentication for Sidekiq works properly.
              </li>
            </ol>
          </Alert>
          <Box sx={{ display: "flex", flexDirection: "row", gap: spacing }}>
            <OpenSidekiqButton region={Region.Eu} />
            <OpenSidekiqButton region={Region.Us} />
          </Box>
        </Box>
      </Box>
    </Authorize>
  );
}

export default Sidekiq;
