import { AppBar } from "react-admin";

import fieldwireLogo from "../../../assets/fieldwire-logo.svg";
import { CustomUserMenu } from "..";

function CustomAppBar() {
  return (
    <AppBar
      sx={{
        bgcolor: "fieldwire.gray.1",
        boxShadow: "none",
      }}
      userMenu={<CustomUserMenu />}
    >
      <img src={fieldwireLogo} alt="Fieldwire logo" />
      {/*
        This spacing moves the refresh button and the
        profile info to the right side of the app bar.
      */}
      <span style={{ width: "100%" }} />
    </AppBar>
  );
}

export default CustomAppBar;
