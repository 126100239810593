import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface ModalProps {
  children: any;
  open: boolean;
  title: string;
}

function Modal(props: ModalProps) {
  return (
    <Dialog open={props.open}>
      <DialogContent
        sx={{
          borderColor: "fieldwire.gray.5",
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "24px",
          width: "480px",
        }}
      >
        <DialogTitle
          sx={{
            color: "fieldwire.gray.1",
            fontSize: "26px",
            fontWeight: "700",
            marginBottom: "24px",
            padding: "0",
          }}
        >
          {props.title}
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          {props.children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
