/**
 * The purpose of this CustomDateTimeField component is to
 * ensure that time is always shown without the developer
 * having to worry about remembering to pass `showTime`.
 */
import { DateField } from "react-admin";

interface CustomDateTimeFieldProps {
  label: string;
  source: string;
  sortable?: boolean;
}

function CustomDateTimeField(props: CustomDateTimeFieldProps) {
  const sortable = props.sortable ?? true;
  return (
    <DateField
      label={props.label}
      options={{
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
      }}
      source={props.source}
      showTime
      sortable={sortable}
    />
  );
}

export default CustomDateTimeField;
