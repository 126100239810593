import { Menu, menuClasses, MenuProps, paperClasses } from "@mui/material";

interface CustomMenuProps extends MenuProps {
  // The following three props all exist in
  // MenuProps as optional props. We specify
  // them again here to make them not optional.
  anchorEl: HTMLElement | null;
  children: any;
  onClose: () => void;
}

const CustomMenu = (props: CustomMenuProps) => {
  return (
    <Menu
      {...props}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{
        [`& .${menuClasses.list}`]: {
          borderColor: "fieldwire.gray.4",
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "0",
        },
        [`& .${paperClasses.root}`]: {
          // This identical to what we have in the web app.
          boxShadow: "0 6px 12px rgb(0 0 0 / 18%)",
          marginTop: "5px",
          minWidth: "150px",
        },
      }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    />
  );
};

export default CustomMenu;
