enum AdminUserRole {
  AccountManager = "account_manager",
  ConfigVarsManager = "config_vars_manager",
  DebugInfoViewer = "debug_info_viewer",
  Default = "default",
  DemoTemplatesManager = "demo_templates_manager",
  FinanceOperator = "finance_operator",
  FinanceViewer = "finance_viewer",
  Marketer = "marketer",
  JobsManager = "jobs_manager",
  SsoInfoManager = "sso_info_manager",
  Super = "super",
  Support = "support",
}

export default AdminUserRole;
