import { SortPayload, StripePlanExternalId } from "../types";

// Sort lists of records in descending order on id so that
// newer records are shown first. Currently used to set the
// default sort order for list (index) pages and secondary
// tables on show pages (ex: user projects on user show page).
const DEFAULT_SORT: SortPayload = { field: "id", order: "DESC" };

// Used in the manual checkout action on the user show page.
const DEFAULT_STRIPE_PLAN = StripePlanExternalId.ProYearlyV7;

// Used to provide more information about email verification/confirmation
// status, particularly for "pending" users.
const EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT =
  "Indicates if the user has verified/confirmed their email. Pending status \
  applies to users that existed before the launch of email verification.";

// Used to provide more information about the owner email filter
// on the enterprise plan and enterprise plan invoices list page.
const EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT =
  "The owner email on the account to which the enterprise plan belongs. Note that \
  this might be different from the now obsolete, owner email on the enterprise plan itself.";

// This specifies both the maximum number of items that we
// display per page, and fetch from our API at any one time.
// Currently used for list (index) pages and secondary tables
// on show pages (ex: account users on account show page).
const PER_PAGE = 50;

// Used to notify admin users that we round "Price" on the EP list
// page and "Amount" on the EPI list page to two decimal places.
const ROUNDED_PRICE_TOOLTIP_TEXT =
  "We round to two decimal places here for display purposes. The actual amount stored on our servers may be more precise.";

export {
  DEFAULT_SORT,
  DEFAULT_STRIPE_PLAN,
  EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT,
  EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT,
  PER_PAGE,
  ROUNDED_PRICE_TOOLTIP_TEXT,
};
