import { TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import { CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const canceledInAppPurchaseUserFilters = [
  <TextInput label="Email" source="email_like" alwaysOn />,
];

function CanceledInAppPurchaseUserList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.CanceledInAppPurchaseUser}
      displayCreate={false}
      filters={canceledInAppPurchaseUserFilters}
      customRowClickConfig={{ reference: Resource.User, source: "id" }}
    >
      <TextField label="User Id" source="id" sortable={false} />
      <TextField label="Email" source="email" sortable={false} />
      <TextField label="Company" source="company" sortable={false} />
    </CustomList>
  );
}

export default CanceledInAppPurchaseUserList;
