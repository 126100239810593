import { NumberField, SelectInput, TextField, TextInput } from "react-admin";

import { DataKind, JobInfoKind, JobInfoStatus } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomDateTimeField, CustomList } from "../custom";

const kindChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(JobInfoKind);

const statusChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(JobInfoStatus);

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const jobInfoFilters = [
  <SelectInput choices={kindChoices} label="Kind" source="kind_eq" alwaysOn />,
  <SelectInput
    choices={statusChoices}
    label="Status"
    source="status_eq"
    alwaysOn
  />,
  // Labeled as slow to dissuade from using this filter too often since on
  // the backend, the JSONB column has to be stringified & linear scanned
  // to find matches
  <TextInput label="Payload (slow lookup)" source="payload_like" alwaysOn />,
  <TextInput
    label="Failed Attempts Made (>=)"
    source="failed_attempts_made_gte"
    alwaysOn
  />,
];

function JobInfoList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.JobInfo}
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={true}
      filters={jobInfoFilters}
    >
      <TextField label="Id" source="id" />
      <TextField label="Kind" source="kind" />
      <TextField label="Status" source="status" />
      <NumberField label="Failed Attempts Made" source="failed_attempts_made" />
      <NumberField label="Failed Attempts Max" source="failed_attempts_max" />
      <NumberField label="Job Count" source="job_count" sortable={false} />
      <TextField label="Payload" source="payload" sortable={false} />
      <TextField label="Cron Key" source="cron_key" sortable={false} />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Ended At" source="ended_at" />
      <CustomDateTimeField label="Process After" source="process_after" />
      <CustomDateTimeField label="Started At" source="started_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default JobInfoList;
