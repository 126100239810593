/**
 * Custom hook that returns a boolean indicating whether or not a modal
 * is open, as well as functions for setting that value to true (open)
 * and false (closed).
 *
 * This is useful as we use modals throughout the app and this custom
 * hook allows to only have to write such logic a single time.
 */

import { useState } from "react";

function useModalState() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return { handleClose, handleOpen, modalOpen };
}

export default useModalState;
