const jobsiteJeff = {
  /**
   * Log some Jobsite Jeff ASCII art to the console for everyone to admire.
   */
  logConsoleAsciiArt: () => {
    console.log(`
MMMMMMMMMMMWWNNNNNNXXXXXXNNWWMMMMMMMMMMM
MMMMMMMMWXK0OOOOOOOOOOOOOO00KXWWMMMMMMMM
MMMMMMWXOkxxkkkkkkkkkkkkkkOO00KXNWMMMMMM
MMMMMN0kxxxxkkkOOOOOOOOOOOOOO0KXKKNMMMMM
MMMMN0kxxddxkkkkkkOkkkkxdddodOXNXKXWMMMM
MMMWKkoc::::codddxxdooc::clllxKNNXXNWMMM
MMMN0kddoc:;;;cdkOxl:::cloxkOOKXNNXNWMMM
MMMN0kxdc,,,;;lxOOxlcc:;;;cdkO0NNNXXWMMM
MMMN0kxolcccldxxk00OOxddodxkO0KNNNXKNWWM
MMX0OOkxddoddlcloooxkkxxxxkkO0KNNNKkxx0W
MWklxOkxxxdoc,,::;,;cclldkO000KXNNKdllkN
MWkokOOkxol:;,,,,,,,;:ccldxOKKKXNNKdlkXM
MMXOkOkxl:;,''''',,,,,,,:lox0KXXXNKkOXWM
MMNOkkxo:'...'',,,,,,,'.';ldOKXXXK0OKWMM
MMWXOkxl;..';:;;;;;::cc;,';lkKXKKKKXNMMM
MMMMXxoc:,,:::;,'',;;:cc:::cok0KKKNMMMMM
MMMMWOllxdollc::;;,::clooodl:cdOKNWMMMMM
MMMMMW0kOOOxol:;,'';::lddxd:',lONWMMMMMM
MMMMMMMNKOkxlc:,..',;;coddl;cxKNMMMMMMMM
MMMMMMMMMWKkoc;,..',,;:cdxkOXWMMMMMMMMMM`);
  },
};

export default jobsiteJeff;
