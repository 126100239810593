enum Currency {
  AED = "aed",
  ALL = "all",
  ARS = "ars",
  AUD = "aud",
  BAM = "bam",
  BGN = "bgn",
  BHD = "bhd",
  BRL = "brl",
  BYN = "byn",
  CAD = "cad",
  CHF = "chf",
  CLP = "clp",
  CNY = "cny",
  COP = "cop",
  CZK = "czk",
  DKK = "dkk",
  DZD = "dzd",
  EGP = "egp",
  EUR = "eur",
  GBP = "gbp",
  HKD = "hkd",
  HUF = "huf",
  IDR = "idr",
  ILS = "ils",
  INR = "inr",
  JPY = "jpy",
  KRW = "krw",
  KZT = "kzt",
  MAD = "mad",
  MXN = "mxn",
  MYR = "myr",
  NOK = "nok",
  NZD = "nzd",
  PAB = "pab",
  PEN = "pen",
  PHP = "php",
  PLN = "pln",
  QAR = "qar",
  RON = "ron",
  RSD = "rsd",
  RUB = "rub",
  SAR = "sar",
  SEK = "sek",
  SGD = "sgd",
  THB = "thb",
  TRY = "try",
  TWD = "twd",
  UAH = "uah",
  USD = "usd",
  VND = "vnd",
  ZAR = "zar",
}

export { Currency };
