import Tooltip from "@mui/material/Tooltip";
import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForFinancesResponse,
  CustomDataProvider,
  MetricsResponse,
} from "../../../../types";
import { ChartContainer, StackedAreaChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

// Used to "humanize" the MRR and ARR numbers to a single fractional digit.
//    - Ex: "12100" becomes "12.1K".
//    - Ex: "3200000" becomes "3.2M".
const singleFractionalDigitFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

// Used to "humanize" the MRR delta numbers with zero fractional digits.
//    - Ex: "12100" becomes "12K".
//    - Ex: "320340" becomes "320K".
const zeroFractionalDigitFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  maximumFractionDigits: 0,
});

function MrrChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data: activeUserCountsForFinances } = useQuery<{
    data: ActiveUserCountsForFinancesResponse[];
  }>(["getActiveUserCountsForFinances"], () =>
    dataProvider.getActiveUserCountsForFinances()
  );

  const { data: metrics } = useQuery<{
    data: MetricsResponse;
  }>(["getMetrics"], () => dataProvider.getMetrics());

  if (activeUserCountsForFinances?.data && metrics?.data) {
    const processedActiveUserCountsForFinancesData = analyticsUtils.processData(
      activeUserCountsForFinances.data,
      new ActiveUserCountsForFinancesResponse(),
      30
    );

    // MRR: Monthly recurring revenue. Computed based on the last day's MRR values.
    // Note that we don't format MRR like we do the other numbers. This is because
    // MRR has a 12x impact on ARR and we don't want to lose precision and perhaps
    // miss if something goes up or down.
    const mrr = analyticsUtils.calculateTotalMrrNthDay(
      processedActiveUserCountsForFinancesData,
      -1
    );
    const formattedMrr = singleFractionalDigitFormatter.format(mrr);

    // ARR: Annual recurring revenue. Estimate is 12x the current MRR (12 months in a year!).
    const arr = mrr * 12;
    const formattedArr = singleFractionalDigitFormatter.format(arr);

    // EOM: ARR at the end of this month estimated by pulling values from BigQuery on the BE.
    const arrEndOfMonth = metrics.data.annual_recurring_revenue_end_of_month;
    const formattedArrEndOfMonth =
      singleFractionalDigitFormatter.format(arrEndOfMonth);

    // MRR Deltas: How much the MRR has changed since those many days in the past compared to current day:
    //    - Ex: "30: +12K" shows we have grown MRR by 12K in the past 30 days.
    //    - Ex: "10: -2K" shows we lost 2K of MRR in the past 10 days.
    const formattedMrrDeltas = [0, 5, 10, 15, 20, 25]
      .map((delta: number) => {
        const mrrNthDay = analyticsUtils.calculateTotalMrrNthDay(
          processedActiveUserCountsForFinancesData,
          delta
        );
        const mrrDelta = mrr - mrrNthDay;
        const formattedMrrDelta = zeroFractionalDigitFormatter.format(mrrDelta);
        // We prefix the delta with a "+" if it's >= 0.
        const sign = mrrDelta >= 0 ? "+" : "";
        return `${30 - delta}: ${sign}${formattedMrrDelta}`;
      })
      .join(", ");

    const Title = () => {
      return (
        <>
          <Tooltip title={arr}>
            <span>ARR: {formattedArr}</span>
          </Tooltip>
          <span>
            , MRR: {formattedMrr} [{formattedMrrDeltas}]&nbsp;
          </span>
          <Tooltip title={arrEndOfMonth}>
            <span>EOM: {formattedArrEndOfMonth} est.</span>
          </Tooltip>
        </>
      );
    };

    return (
      <ChartContainer title={<Title />}>
        <StackedAreaChart
          labels={processedActiveUserCountsForFinancesData.map(
            (d: ActiveUserCountsForFinancesResponse) => d.date
          )}
          datasets={[
            {
              data: processedActiveUserCountsForFinancesData.map(
                (d: ActiveUserCountsForFinancesResponse) =>
                  d.mrr_enterprise_per_project
              ),
              label: "Enterprise (Project)",
            },
            {
              data: processedActiveUserCountsForFinancesData.map(
                (d: ActiveUserCountsForFinancesResponse) =>
                  d.mrr_enterprise_per_user
              ),
              label: "Enterprise (User)",
            },
            {
              data: processedActiveUserCountsForFinancesData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.mrr_pro
              ),
              label: "Pro",
            },
            {
              data: processedActiveUserCountsForFinancesData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.mrr_business
              ),
              label: "Business",
            },
            {
              data: processedActiveUserCountsForFinancesData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.mrr_business_plus
              ),
              label: "Business Plus",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default MrrChart;
