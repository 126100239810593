import { ReactElement } from "react";

import Authorize from "../Authorize";
import { MultiRegionMenuItemLink } from ".";
import { MultiRegionMenuItemProps } from "./Common";

export interface TopLevelMultiRegionMenuItemProps
  extends MultiRegionMenuItemProps {
  icon: ReactElement;
}

function TopLevelMultiRegionMenuItem(props: TopLevelMultiRegionMenuItemProps) {
  return (
    <Authorize
      action={props.action}
      dataKind={props.dataKind}
      disableUnauthorizedMessage
    >
      <MultiRegionMenuItemLink
        key={props.primaryText}
        leftIcon={props.icon}
        pathForEu={props.pathForEu}
        pathForUs={props.pathForUs}
        primaryText={props.primaryText}
      />
    </Authorize>
  );
}

export default TopLevelMultiRegionMenuItem;
