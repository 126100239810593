/**
 * An opinionated, custom hook that creates a getStripeCreditCardToken function
 * for the calling component to use to fetch a token from Stripe's servers.
 *
 * The calling component must be wrapped in a Stripe Elements provider and render
 * a Stripe CardElement component.
 */

import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";

function useGetStripeCreditCardToken(
  stripe: Stripe | null,
  stripeElements: StripeElements | null
) {
  const getStripeCreditCardToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      // Ensure that Stripe has loaded.
      if (!stripe) {
        console.warn("Stripe not loaded.");
        return reject();
      }

      // Ensure that the Card element, which contains the credit card
      // information needed to create the credit card token, is found.
      const stripeCardElement = stripeElements?.getElement("card");
      if (!stripeCardElement) {
        console.warn("Could not find Card element.");
        return reject();
      }

      return stripe
        .createToken(stripeCardElement)
        .then((tokenResult: TokenResult) => {
          if (tokenResult.token) {
            return resolve(tokenResult.token.id);
          } else {
            console.warn(
              `Received token result but token not found.\n${tokenResult.error.message}`
            );
            return reject();
          }
        })
        .catch((error: any) => {
          console.warn(`Not able to create token.\n${error}`);
          return reject();
        });
    });
  };

  return getStripeCreditCardToken;
}

export default useGetStripeCreditCardToken;
