import * as Sentry from "@sentry/react";

import env from "./Env";

const DSN = env.sentry.fetch_data_source_name();
const ENVIRONMENT = env.sentry.fetch_environment();

// We might have forgotten to set some of these environment
// variables. Let's log a friendly warning about this
if (!DSN) {
  console.warn("Sentry DSN is not set! Errors won't be sent to sentry.io");
}

if (!ENVIRONMENT) {
  console.warn(
    "Sentry environment is not set! Errors won't be tagged appropriately"
  );
}

const errorTracker = {
  captureException: (error: Error) => {
    Sentry.captureException(error);
  },

  init: () => {
    Sentry.init({
      dsn: DSN,
      environment: ENVIRONMENT,
      autoSessionTracking: false,
    });
  },
};

export default errorTracker;
