import {
  FormDataConsumer,
  NullableBooleanInput,
  SelectInput,
  TextInput,
  ValidateForm,
} from "react-admin";

import { datetime } from "../../datetime";
import {
  Currency,
  DataKind,
  EnterprisePlanCategory,
  EnterprisePlanKind,
  EnterprisePlanResponse,
} from "../../types";
import validators from "../../utils/Validators";
import { componentUtils } from "../component_utils";
import {
  CurrencyConversionInput,
  CurrencySelectInput,
  CustomCreate,
  CustomNumberInput,
  CustomUtcDateTimeInput,
} from "../custom";

const categoryChoices = componentUtils.generateCapitalCaseSelectChoicesFromEnum(
  EnterprisePlanCategory
);
const kindChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(EnterprisePlanKind);

function EnterprisePlanCreate() {
  const transform = (data: Partial<EnterprisePlanResponse>) => ({
    ...data,
    hilti_subscription_id: data.hilti_subscription_id?.trim(),
  });

  const validateForm: ValidateForm = (values: any) => {
    return validators.validateDateRange(values.start_at, values.end_at);
  };

  return (
    <CustomCreate
      checkPermissionFor={DataKind.EnterprisePlan}
      transform={transform}
      validate={validateForm}
    >
      <TextInput label="Name" source="name" required />
      <CurrencySelectInput
        label="Currency"
        source="currency"
        defaultValue={Currency.USD}
        required
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <CurrencyConversionInput
            label="Price"
            source="price_native_currency"
            currency={formData.currency}
            value={formData.price_native_currency}
            required
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <CurrencyConversionInput
            label="Overage Price"
            source="overage_price_native_currency"
            currency={formData.currency}
            value={formData.overage_price_native_currency}
          />
        )}
      </FormDataConsumer>
      <CustomNumberInput
        label="Max User Count"
        source="max_user_count"
        required
      />
      <TextInput label="Company Name" source="company_name" />
      <TextInput label="Company Domain" source="company_domain" />
      <TextInput
        label="Hilti Subscription ID (For Hilti Contracts Only)"
        source="hilti_subscription_id"
      />
      <CustomNumberInput label="Account ID" source="account_id" required />
      <CustomUtcDateTimeInput
        defaultValue={datetime.getStartOfDayUTC()}
        helperText="The 12:00 AM start time should rarely need to be changed."
        label="Start At (UTC)"
        source="start_at"
        required
      />
      <CustomUtcDateTimeInput
        defaultValue={datetime.getEndOfDayInOneYearUTC()}
        helperText="The 11:59 PM end time should rarely need to be changed."
        parseSecondsAs={59}
        label="End At (UTC)"
        source="end_at"
        required
      />
      <NullableBooleanInput label="Is Temporary" source="is_temporary" />
      <NullableBooleanInput label="Is Poc" source="is_poc" />
      <SelectInput
        label="Category"
        source="category"
        choices={categoryChoices}
        required
      />
      <SelectInput label="Kind" source="kind" choices={kindChoices} required />
      <NullableBooleanInput
        label="Is Notify Admin Enabled"
        source="is_notify_admin_enabled"
      />
      <NullableBooleanInput
        label="Is Notify New User Enabled"
        source="is_notify_new_user_enabled"
      />
      <NullableBooleanInput
        label="Is Notify Over Limit Enabled"
        source="is_notify_over_limit_enabled"
      />
      <NullableBooleanInput
        label="Is Auto Add Users"
        source="is_auto_add_users"
      />
      <NullableBooleanInput
        label="Is Project Create By Account User Enabled"
        source="is_project_create_by_account_user_enabled"
      />
    </CustomCreate>
  );
}

export default EnterprisePlanCreate;
