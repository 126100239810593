import { TextField } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomReferenceField,
  CustomReferenceFieldLazy,
  CustomShow,
  ShowTable,
} from "../custom";

function ApiKeyShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.ApiKey}
      displayDelete={true}
      displayEdit={true}
    >
      {/* START OF API KEY DETAILS */}
      <ShowTable title="Api Key Details">
        <TextField label="Id" source="id" />
        <CustomReferenceFieldLazy
          label="Account"
          source="account_id"
          reference={Resource.Account}
        />
        <CustomReferenceField
          label="User"
          source="user_id"
          reference={Resource.User}
        >
          <TextField source="email" />
        </CustomReferenceField>
        <TextField label="Description" source="description" />
        <TextField label="Label" source="label" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>

      {/* END OF API KEY DETAILS */}
    </CustomShow>
  );
}

export default ApiKeyShow;
