import { Identifier, useDataProvider } from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import { AccountResponse, CustomDataProvider } from "../../../types";
import { LabelWithInfoTooltip, MutationActionMenuItem } from "..";

interface UnblockAccountMutationFnVariables {
  id: Identifier;
}

interface UnblockAccountMenuItemProps {
  accountRecord: AccountResponse;
}

function UnblockAccountMenuItem(props: UnblockAccountMenuItemProps) {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const mutationFn: MutationFunction<
    { data: AccountResponse },
    UnblockAccountMutationFnVariables
  > = (variables: UnblockAccountMutationFnVariables) => {
    return dataProvider.unblockAccount(variables.id);
  };
  const mutationKey: MutationKey = ["unblockAccount", props.accountRecord.id];
  const successMessage = "Unblocked account successfully! ✅";

  return (
    <MutationActionMenuItem
      disabled={props.accountRecord.blocked_at ? false : true}
      label={
        <LabelWithInfoTooltip
          labelText="Unblock Account"
          tooltipText="Unblocks only if the account is under free usage limit."
        />
      }
      mutationFn={mutationFn}
      mutationKey={mutationKey}
      mutationVariables={{ id: props.accountRecord.id }}
      successMessage={successMessage}
    />
  );
}

export default UnblockAccountMenuItem;
