/**
 * A wrapper around React Admin's Button component used for external links.
 *
 * Enforces the following behavior across all instances of the link button:
 * 1. Underlying component is an anchor.
 * 2. Opens external link in a new tab.
 * 3. Button variant is "fieldwire-primary".
 */

import { Button, ButtonProps } from "react-admin";

function ExternalLinkButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      component="a"
      variant="fieldwire-primary"
      // @ts-expect-error
      rel="noopener noreferrer"
      target="_blank"
    />
  );
}

export default ExternalLinkButton;
