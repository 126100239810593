import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { ReactElement, useState } from "react";
import { MenuItemLink, useSidebarState } from "react-admin";

import { adminAbility } from "../../../abilities";
import Authorize from "../Authorize";
import { MultiRegionMenuItemLink } from ".";
import {
  menuItemLinkSx,
  MultiRegionMenuItemProps,
  SingleRegionMenuItemProps,
} from "./Common";

interface PrimaryTextWithExpandIconProps {
  open: boolean;
  primaryText: string;
}

interface TopLevelMultiLevelMenuItemProps {
  children: (MultiRegionMenuItemProps | SingleRegionMenuItemProps)[];
  icon: ReactElement;
  primaryText: string;
}

/**
 * Used by the `MultiLevelMenuItem` component to render its primary text with
 * an expand less or expand more icon depending on whether the multi-level menu
 * item is open or closed.
 */
function PrimaryTextWithExpandIcon(props: PrimaryTextWithExpandIconProps) {
  return (
    <Box
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {props.primaryText}
      {props.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </Box>
  );
}

/**
 * Menu item capable of rendering one or more child menu items.
 *
 * Clicking on the top-level menu item will display and hide the
 * child menu items.
 *
 * Open example:
 *
 * ```
 *    Demo Projects
 *       --> Templates
 *       --> Users
 * ```
 *
 * Closed example:
 *
 * ```
 *    Demo Projects
 * ```
 *
 * Note that the entire menu item will not render if the current admin
 * user does not have permission to view any of the child menu items.
 *
 * It is also important to note that an open multi-level menu item
 * will collapse when the entire side menu is collapsed. However its
 * state is persisted and the multi-level menu item will open again
 * once the entire side menu is opened.
 */
function TopLevelMultiLevelMenuItem(props: TopLevelMultiLevelMenuItemProps) {
  const [multiLevelMenuOpen, setMultiLevelMenuOpen] = useState(false);
  const [open] = useSidebarState();

  const toggleMultiLevelMenu = () => {
    setMultiLevelMenuOpen(!multiLevelMenuOpen);
  };

  // Extract action, data kind pairs from children.
  const actionDataKindPairs = props.children.map((child) => ({
    action: child.action,
    dataKind: child.dataKind,
  }));

  // Return nothing (null) if there are no children set or if the current
  // admin user does not have the permission to view any of the children.
  if (
    !props.children.length ||
    !adminAbility.canAdminUserPerformAny(actionDataKindPairs)
  ) {
    return null;
  }

  return (
    <>
      <MenuItemLink
        onClick={toggleMultiLevelMenu}
        to="#"
        primaryText={
          <PrimaryTextWithExpandIcon
            open={multiLevelMenuOpen}
            primaryText={props.primaryText}
          />
        }
        leftIcon={props.icon}
        sx={menuItemLinkSx}
      />
      {multiLevelMenuOpen &&
        open &&
        // We don't have a default fallback because all possibilities for
        // `child.tag` are handled. Unfortunately, ESLint doesn't pick this
        // up and believes it is possible for us to not return anything in
        // the callback. Therefore we will disable ESLint for this rule here.
        //
        // eslint-disable-next-line array-callback-return
        props.children.map((child) => {
          switch (child.tag) {
            case "multi_region":
              return (
                <Authorize
                  action={child.action}
                  dataKind={child.dataKind}
                  key={child.primaryText}
                  disableUnauthorizedMessage
                >
                  <MultiRegionMenuItemLink
                    leftIcon={<></>}
                    pathForEu={child.pathForEu}
                    pathForUs={child.pathForUs}
                    primaryText={child.primaryText}
                  />
                </Authorize>
              );
            case "single_region":
              return (
                <Authorize
                  action={child.action}
                  key={child.to}
                  dataKind={child.dataKind}
                  disableUnauthorizedMessage
                >
                  <MenuItemLink
                    to={child.to}
                    primaryText={child.primaryText}
                    leftIcon={<></>}
                    sx={menuItemLinkSx}
                  />
                </Authorize>
              );
          }
        })}
    </>
  );
}

export default TopLevelMultiLevelMenuItem;
