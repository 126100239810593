/**
 * A wrapper around React Admin's `SimpleForm` component which is used
 * on the create and edit pages. The main difference between the custom
 * and original simple form is that the custom one has no padding while
 * the original one does. The custom one also sets specific styles for
 * the inputs which appear within it.
 */

import { outlinedInputClasses } from "@mui/material";
import { ReactElement } from "react";
import { SimpleForm, ValidateForm } from "react-admin";

interface CustomSimpleFormForCreateOrEditProps {
  children: any;
  toolbar: ReactElement;
  validate?: ValidateForm;
}

function CustomSimpleFormForCreateOrEdit(
  props: CustomSimpleFormForCreateOrEditProps
) {
  return (
    <SimpleForm
      sx={{
        padding: "0",
        // Give inputs within form a white
        // background and specific width.
        [`& .${outlinedInputClasses.root}`]: {
          bgcolor: "fieldwire.white.1",
          width: "480px",
        },
      }}
      toolbar={props.toolbar}
      validate={props.validate}
    >
      {props.children}
    </SimpleForm>
  );
}

export default CustomSimpleFormForCreateOrEdit;
