import Link from "@mui/material/Link";
import {
  BooleanField,
  FunctionField,
  Identifier,
  NumberField,
  TextField,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import {
  Action,
  BoxTokenResponse,
  CustomDataProvider,
  DataKind,
  Region,
  Resource,
  SyncErrorResponse,
  UserResponse,
} from "../../types";
import { resourceUtils } from "../../utils";
import {
  ActionMenu,
  Authorize,
  CustomDatagrid,
  CustomDateTimeField,
  CustomReferenceField,
  CustomReferenceManyField,
  CustomShow,
  MutationActionMenuItem,
  ProjectsForSync,
  ShowTable,
} from "../custom";

interface DisconnectProjectsMutationFnVariables {
  id: Identifier;
}

interface ResetStreamPositionMutationFnVariables {
  id: Identifier;
}

function DisconnectProjects(props: { region: Region }) {
  const record = useRecordContext<BoxTokenResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<
      { data: BoxTokenResponse },
      DisconnectProjectsMutationFnVariables
    > = (variables: DisconnectProjectsMutationFnVariables) => {
      return dataProvider.disconnectProjectsForBoxToken(
        props.region,
        variables.id
      );
    };
    const mutationKey: MutationKey = [
      "disconnectProjectsForBoxToken",
      record.id,
    ];
    const successMessage = `Disconnected all projects connected to the Box token: ${record.id}. ✅`;

    return (
      <MutationActionMenuItem
        label="Disconnect Projects"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function ResetStreamPosition(props: { region: Region }) {
  const record = useRecordContext<BoxTokenResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<
      { data: BoxTokenResponse },
      ResetStreamPositionMutationFnVariables
    > = (variables: ResetStreamPositionMutationFnVariables) => {
      return dataProvider.resetStreamPosition(props.region, variables.id);
    };
    const mutationKey: MutationKey = ["resetStreamPosition", record.id];
    const successMessage = "Token stream position reset successfully! ✅";

    return (
      <MutationActionMenuItem
        label="Reset Stream Position"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function BoxTokenShow(props: { region: Region }) {
  const fieldwireActions = [
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.BoxToken,
      element: <DisconnectProjects region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.BoxToken,
      element: <ResetStreamPosition region={props.region} />,
    },
  ];

  return (
    <CustomShow
      checkPermissionFor={DataKind.BoxToken}
      displayDelete={false}
      displayEdit={false}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF BOX TOKEN DETAILS */}
      <ShowTable title="Box Token Details">
        <CustomReferenceField
          label="User"
          source="user_id"
          reference={Resource.User}
        >
          <FunctionField
            render={(record: UserResponse) =>
              `${record.first_name} ${record.last_name}`
            }
          />
        </CustomReferenceField>
        <BooleanField label="Is Valid" source="is_valid" />
        <CustomDateTimeField label="Last Pulled At" source="last_pulled_at" />
        <CustomDateTimeField label="Last Pushed At" source="last_pushed_at" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        <CustomDateTimeField label="Expires At" source="expires_at" />
        <TextField label="Email" source="email" />
        <TextField label="Stream Position" source="stream_position" />
      </ShowTable>
      {/* END OF BOX TOKEN DETAILS */}

      {/* START OF PROJECTS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.BoxToken}
        disableUnauthorizedMessage
      >
        <ProjectsForSync
          region={props.region}
          target={resourceUtils.boxTokenResourceFor(props.region)}
        />
      </Authorize>
      {/* END OF PROJECTS */}

      {/* START OF SYNC ERRORS */}
      {/* Backend enforces the ordering for sync errors - update_at_desc */}
      <Authorize action={Action.Read} dataKind={DataKind.BoxToken}>
        <ShowTable rowFlexDirection="column" title="Sync Errors">
          <CustomReferenceManyField
            displayPagination={true}
            label={false}
            reference={resourceUtils.syncErrorResourceFor(props.region)}
            target={resourceUtils.boxTokenResourceFor(props.region)}
          >
            <CustomDatagrid>
              <CustomDateTimeField
                label="Last Occurred"
                source="updated_at"
                sortable={false}
              />
              <CustomDateTimeField
                label="First Occurred"
                source="created_at"
                sortable={false}
              />
              <CustomReferenceField
                label="User"
                source="sync_owner_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField source="email" />
              </CustomReferenceField>
              <NumberField label="Count" source="count" sortable={false} />
              <TextField
                label="Entity Type"
                source="entity_type"
                sortable={false}
              />
              <TextField
                label="Entity Name"
                source="entity_name"
                sortable={false}
              />
              <TextField
                label="Entity Id"
                source="entity_id"
                sortable={false}
              />
              <FunctionField
                label="Error (May Link to Rollbar)"
                render={(record: SyncErrorResponse) =>
                  record.error_url ? (
                    <Link
                      href={record.error_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {record.error_message}
                    </Link>
                  ) : (
                    record.error_message
                  )
                }
                sortable={false}
              />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF SYNC ERRORS */}
    </CustomShow>
  );
}

export default BoxTokenShow;
