/**
 * A loose recreation of React Admin's `Layout` component. At the moment, it is
 * primarily used to specify the usage of custom `AppBar` and `Menu` components,
 * but also gives us the flexibility around the usage and functionality of the
 * error boundary.
 */

import Box from "@mui/material/Box";
import { LayoutProps, Sidebar } from "react-admin";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { usePageTracking } from "../../../hooks";
import { CustomAppBar, ErrorFallback } from "..";
import { CustomSideMenu } from "../side-menu";

function Root(props: { children: any }) {
  return (
    <Box
      sx={{
        bgcolor: "fieldwire.white.1",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        zIndex: 1,
      }}
    >
      {props.children}
    </Box>
  );
}

function AppFrame(props: { children: any }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflowX: "auto" }}>
      {props.children}
    </Box>
  );
}

function ContentWithSidebar(props: { children: any }) {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, marginTop: "48px" }}>
      {props.children}
    </Box>
  );
}

function Content(props: { children: any }) {
  return (
    <Box
      sx={{
        bgcolor: "fieldwire.gray.6",
        display: "flex",
        flexDirection: "column",
        flexGrow: 2,
        padding: "24px",
        // Without this style, the width of the content would
        // not be bounded by the width of the viewport.
        width: 0,
      }}
    >
      {props.children}
    </Box>
  );
}

function CustomLayout(props: LayoutProps) {
  // https://marmelab.com/react-admin/Routing.html#reacting-to-a-page-change
  usePageTracking();

  return (
    <Root>
      <AppFrame>
        <CustomAppBar />
        <ContentWithSidebar>
          <Sidebar>
            <CustomSideMenu />
          </Sidebar>
          <Content>
            <ErrorBoundary
              fallbackRender={(fallbackProps: FallbackProps) => (
                <ErrorFallback
                  error={fallbackProps.error}
                  resetErrorBoundary={fallbackProps.resetErrorBoundary}
                />
              )}
            >
              {props.children}
            </ErrorBoundary>
          </Content>
        </ContentWithSidebar>
      </AppFrame>
    </Root>
  );
}

export default CustomLayout;
