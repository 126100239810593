/**
 * The UI that the user sees when they get a 404.
 */

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "react-admin";

import jobsiteJeffDealWithIt from "../../assets/jobsite-jeff-deal-with-it.png";

function Typography404(props: { children: any }) {
  return (
    <Typography
      component="h1"
      sx={{
        color: "fieldwire.gray.1",
        fontSize: "156px",
        fontWeight: "700",
        lineHeight: "1",
      }}
    >
      {props.children}
    </Typography>
  );
}

function NotFound() {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
      >
        <Typography404>4</Typography404>
        <img src={jobsiteJeffDealWithIt} alt="Jobsite Jeff deal with it" />
        <Typography404>4</Typography404>
      </Box>
      <Typography component="p" sx={{ color: "fieldwire.gray.1" }}>
        You saw a fork in the road and you took it.
      </Typography>
      <Button
        label="Back"
        onClick={() => {
          window.history.go(-1);
        }}
        variant="fieldwire-primary"
      />
    </Box>
  );
}

export default NotFound;
