import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, StackedAreaChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function SignupsBySourceChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      30
    );

    return (
      <ChartContainer
        title={`Signups by Source (${analyticsUtils.calculateTotalSignupsBySourceLastDay(
          processedData
        )})`}
      >
        <StackedAreaChart
          labels={processedData.map(
            (d: ActiveUserCountsForMarketingResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.android_signups_unknown_source
              ),
              label: "Android - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.ipad_signups_unknown_source
              ),
              label: "iPad - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.ipad_signups_paid_source
              ),
              label: "iPad - Paid",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.iphone_signups_unknown_source
              ),
              label: "iPhone - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.iphone_signups_paid_source
              ),
              label: "iPhone - Paid",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.web_signups_unknown_source
              ),
              label: "Web - Unknown",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  d.web_signups_paid_source
              ),
              label: "Web - Paid",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default SignupsBySourceChart;
