/**
 * Very simple implementation of the React Admin filter form that requires
 * an admin user either press ENTER or click the FILTER button in order for
 * the filtering to take place.
 *
 * Based on the following guide:
 *
 * https://marmelab.com/react-admin/FilteringTutorial.html#building-a-custom-filter
 *
 * This is in contrast to the default React Admin filter form in which filtering
 * takes place after the user has finished interacting with the form. Why would
 * we want to do this? Two reasons:
 *
 * 1. It's possible for a user to stop typing and then start typing again and
 *    for the newly entered characters to be replaced. This is not an optimal
 *    user experience.
 *
 * 2. There might be some performance benefits in not firing off as many queries
 *    and forcing the user to enumerate the desired filter(s) completely before
 *    we perform them.
 */

import { Box } from "@mui/material";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import {
  Button,
  FilterFormClasses,
  FilterFormInput,
  FilterFormProps,
  getFilterFormValues,
  useListContext,
} from "react-admin";
import { FormProvider, useForm } from "react-hook-form";

function CustomFilterForm(props: FilterFormProps) {
  const {
    // Value of the filters based on the URL. Ex: { "name": Jay }
    filterValues,
    // Callback to set the filter values. Ex: setFilters({ "name": Yaj })
    setFilters,
  } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  // Reapply filterValues when the URL changes.
  //
  // IMPORTANT:
  //
  // This code is "borrowed" from React Admin's `<FilterForm />` source code.
  // Please do not modify it unless you are absolutely sure you know what you
  // are doing!
  useEffect(() => {
    const newValues = getFilterFormValues(form.getValues(), filterValues);
    if (!isEqual(newValues, form.getValues())) {
      form.reset(newValues);
    }
  }, [filterValues, form]);

  const onSubmit = (values: any) => {
    // @ts-expect-error
    setFilters(values);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.filters?.map((filter: React.ReactNode) => {
            return (
              <FilterFormInput
                // @ts-expect-error
                key={filter?.props?.source}
                filterElement={filter}
                className={FilterFormClasses.filterFormInput}
              />
            );
          })}
          <Box
            // So the button vertically aligns with the filter inputs.
            mt={1}
          >
            <Button
              variant="fieldwire-primary"
              label="Filter"
              type="submit"
              // Match the height of the filters.
              sx={{ height: "40px" }}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

export default CustomFilterForm;
