import { NumberField, TextField, TextInput } from "react-admin";

import {
  EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT,
  ROUNDED_PRICE_TOOLTIP_TEXT,
} from "../../constants";
import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  LabelWithInfoTooltip,
  RoundedPriceField,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const canceledEnterpriseOwnerFilters = [
  <TextInput
    label={
      <LabelWithInfoTooltip
        labelText="Account Owner Email"
        tooltipText={EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT}
      />
    }
    source="email_like"
    alwaysOn
  />,
];

function CanceledEnterpriseOwnerList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.CanceledEnterpriseOwner}
      displayCreate={false}
      filters={canceledEnterpriseOwnerFilters}
      customRowClickConfig={{
        reference: Resource.EnterprisePlan,
        source: "id",
      }}
    >
      <CustomReferenceField
        label="Account Owner"
        source="account_id"
        reference={Resource.Account}
        sortable={false}
      >
        <TextField source="owner_email" />
      </CustomReferenceField>

      <TextField label="User Id" source="user_id" sortable={false} />

      <TextField label="Company" source="company" sortable={false} />
      <TextField label="Name" source="name" sortable={false} />
      <TextField
        label="Currency"
        source="currency"
        sx={{ textTransform: "uppercase" }}
        sortable={false}
      />
      <RoundedPriceField
        label={
          <LabelWithInfoTooltip
            labelText="Price"
            tooltipText={ROUNDED_PRICE_TOOLTIP_TEXT}
          />
        }
        source="price_native_currency"
        sortable={false}
      />
      <NumberField
        label="Max User Count"
        source="max_user_count"
        sortable={false}
      />
      <CustomDateTimeField
        label="Start At"
        source="start_at"
        sortable={false}
      />
      <CustomDateTimeField label="End At" source="end_at" sortable={false} />
      <TextField label="Category" source="category" sortable={false} />
      {/* MISSING: Last User Count */}
    </CustomList>
  );
}

export default CanceledEnterpriseOwnerList;
