/**
 * A lazy version of React Admin's `ReferenceField` component that doesn't
 * fetch the referenced record from the BE. Rather, it just displays the id
 * of the referenced record as a link that leads to the show page for said
 * referenced record.
 *
 * The advantage of this component is that it doesn't make an unnecessary
 * call to the BE for the referenced record if we are only displaying its
 * id.
 */

import Link from "@mui/material/Link";
import { MouseEvent } from "react";
import {
  ReferenceFieldProps,
  useCreatePath,
  useRecordContext,
} from "react-admin";

function CustomReferenceFieldLazy(props: ReferenceFieldProps) {
  const createPath = useCreatePath();
  const record = useRecordContext();

  if (record) {
    const referenceRecordId = record[props.source];
    const path = createPath({
      resource: props.reference,
      id: referenceRecordId,
      type: "show",
    });

    return (
      <Link
        href={`#${path}`}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          // If this field is used within a Datagrid, the click event will cause
          // the Datagrid to add the route for parent row to the router history.
          e.stopPropagation();
        }}
      >
        {referenceRecordId}
      </Link>
    );
  } else {
    return null;
  }
}

export default CustomReferenceFieldLazy;
