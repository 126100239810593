import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomCreate } from "../custom";

function ConfigVariableCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.ConfigVariable}>
      <TextInput label="Name" source="name" required />
      <TextInput label="Value" source="value" required />
    </CustomCreate>
  );
}

export default ConfigVariableCreate;
