/**
 * The MutationActionMenuItem is a very opinionated wrapper around the CustomMenuItem
 * component used to perform custom mutations. An example of a custom mutation would
 * be unlocking a user or toggling BIM on an account.
 *
 * Necessary:
 * - Mutation function must take some object as argument.
 * - Success message always shown after successful mutation.
 * - Error message always shown after unsuccessful mutation.
 *
 * Optional:
 * - Refresh after successful mutation. Enabled by default.
 * - Disable button. Button is enabled by default.
 */
import { ReactElement } from "react";
import { MutationFunction, MutationKey } from "react-query";

import { useMutation } from "../../hooks";
import { CustomMenuItem } from ".";

interface MutationActionMenuItemProps<TData, TVariables> {
  label: string | ReactElement;
  mutationFn: MutationFunction<TData, TVariables>;
  mutationKey: MutationKey;
  mutationVariables: TVariables;
  successMessage: string | ((response: TData) => string);
  disabled?: boolean;
  skipPageRefresh?: boolean;
}

function MutationActionMenuItem<TVariables, TData>(
  props: MutationActionMenuItemProps<TVariables, TData>
) {
  const { mutate, isLoading } = useMutation(
    props.mutationFn,
    props.mutationKey,
    props.successMessage,
    props.skipPageRefresh
  );

  return (
    <CustomMenuItem
      onClick={() => {
        mutate(props.mutationVariables);
      }}
      disabled={props.disabled ?? isLoading}
    >
      {props.label}
    </CustomMenuItem>
  );
}

export default MutationActionMenuItem;
