import { NumberField, TextField } from "react-admin";

import { Action, DataKind, Resource } from "../../types";
import {
  Authorize,
  CustomDatagrid,
  CustomReferenceManyField,
  CustomShow,
  SalesforceLink,
  ShowTable,
} from "../custom";

function CompanyShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.Company}
      displayDelete={true}
      displayEdit={true}
    >
      {/* START OF COMPANY DETAILS */}
      <ShowTable title="Company Details">
        <TextField label="Id" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Email Domain" source="email_domain" />
        <TextField label="Fieldwire Rep" source="fw_rep_admin_user_id" />
        {/* MISSING: Accounts */}
        {/* MISSING: Sum Lead Score */}
        {/* MISSING: Avg Lead Score */}
        {/* MISSING: Total Users */}
        {/* MISSING: 30 Day Users*/}
        <SalesforceLink label="Salesforce Id" />
        {/* MISSING: Scores Cached On */}
      </ShowTable>

      {/* END OF COMPANY DETAILS */}

      {/* START OF ASSOCIATED ACCOUNTS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.Account}
        disableUnauthorizedMessage
      >
        <ShowTable title="Associated Accounts">
          <CustomReferenceManyField
            label={false}
            reference={Resource.Account}
            target={Resource.Company}
            // We have decided not to support pagination of accounts as
            // the maximum number of accounts for a company is < 200 at
            // at the time of writing.
            displayPagination={false}
          >
            <CustomDatagrid>
              <TextField label="Id" source="id" sortable={false} />
              <TextField
                label="Owner Email"
                source="owner_email"
                sortable={false}
              />
              <TextField
                label="Plan Name"
                source="plan_name"
                sortable={false}
              />
              <NumberField
                label="Current User Count"
                source="current_user_count"
                sortable={false}
              />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF ASSOCIATED ACCOUNTS */}
    </CustomShow>
  );
}

export default CompanyShow;
