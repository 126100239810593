import Link from "@mui/material/Link";
import { MouseEvent } from "react";
import {
  BooleanField,
  Button,
  FunctionField,
  Identifier,
  NumberField,
  RecordContextProvider,
  TextField,
  useDataProvider,
  useRecordContext,
  useReference,
  useShowContext,
} from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import { EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT } from "../../constants";
import { useModalState, useMutation } from "../../hooks";
import {
  AccountResponse,
  Action,
  CustomDataProvider,
  DataKind,
  ProjectShowResponse,
  ProjectUserResponse,
  Region,
  Resource,
  UserResponse,
} from "../../types";
import { resourceUtils } from "../../utils";
import { routes } from "../../utils/Routes";
import { componentUtils } from "../component_utils";
import {
  ActionMenu,
  Authorize,
  CustomDatagrid,
  CustomDateTimeField,
  CustomMenuItem,
  CustomReferenceField,
  CustomReferenceFieldLazy,
  CustomReferenceManyField,
  CustomShow,
  ExportProjectModal,
  LabelWithInfoTooltip,
  MutationActionMenuItem,
  ShowTable,
} from "../custom";

interface ReplicateProjectToConsolidatedTableMutationFnVariables {
  id: Identifier;
}

interface ReplicateProjectUserToConsolidatedTableMutationFnVariables {
  projectId: Identifier;
  projectUserId: Identifier;
}

interface ToggleBimMutationFnVariables {
  id: Identifier;
}

interface ToggleChangeOrdersMutationFnVariables {
  id: Identifier;
}

interface ToggleBudgetMutationFnVariables {
  id: Identifier;
}

interface ToggleFormsMutationFnVariables {
  id: Identifier;
}

interface ToggleMarkupSymbolsFnVariables {
  id: Identifier;
}

interface TogglePmMutationFnVariables {
  id: Identifier;
}

interface ToggleSpecsMutationFnVariables {
  id: Identifier;
}

interface ToggleSubmittalsMutationFnVariables {
  id: Identifier;
}

// Custom Actions

function AuditLogsProjects() {
  const record = useRecordContext<ProjectShowResponse>();

  if (record) {
    return (
      // We use the following directive to suppress the type errors arising from
      // usage of the `component` and `href` props. Despite the type errors, the
      // component works as expected with the props.
      <CustomMenuItem
        // @ts-expect-error
        component="a"
        href={routes.computeAuditLogForUsProject(record.id)}
      >
        Audit Logs
      </CustomMenuItem>
    );
  } else {
    return null;
  }
}

function ExportProject(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();

  if (record) {
    return <ExportProjectButton project={record} region={props.region} />;
  } else {
    return null;
  }
}

function ExportProjectButton(props: {
  project: ProjectShowResponse;
  region: Region;
}) {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    <>
      <CustomMenuItem onClick={handleOpen}>
        Create Project Export
      </CustomMenuItem>
      <ExportProjectModal
        handleClose={handleClose}
        modalOpen={modalOpen}
        project={props.project}
        region={props.region}
      />
    </>
  );
}

function ReplicateProjectToConsolidatedTable(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn = (
      variables: ReplicateProjectToConsolidatedTableMutationFnVariables
    ) =>
      dataProvider.replicateProjectToConsolidatedTable(
        props.region,
        variables.id
      );
    const mutationKey = ["replicateProjectToConsolidatedTable", record.id];
    const successMessage =
      "Replicated project to consolidated projects table successfully! ✅";

    return (
      <MutationActionMenuItem
        label={
          <LabelWithInfoTooltip
            labelText="Replicate"
            tooltipText="Replicates project to consolidated projects table."
          />
        }
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function ReplicateProjectUserToConsolidatedTable(props: { region: Region }) {
  const { record: projectRecord } = useShowContext<ProjectShowResponse>();
  const projectUserRecord = useRecordContext<ProjectUserResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  const mutationFn = (
    variables: ReplicateProjectUserToConsolidatedTableMutationFnVariables
  ) =>
    dataProvider.replicateProjectUserToConsolidatedTable(
      props.region,
      variables.projectId,
      variables.projectUserId
    );
  const mutationKey = [
    "replicateProjectUserToConsolidatedTable",
    projectRecord?.id,
    projectUserRecord.id,
  ];
  const successMessage =
    "Replicated project user to consolidated project user table successfully! ✅";
  const skipRefresh = true;

  const { mutate } = useMutation(
    mutationFn,
    mutationKey,
    successMessage,
    skipRefresh
  );

  if (projectRecord && projectUserRecord) {
    return (
      <Button
        label="Replicate"
        variant="fieldwire-link"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          // Because this field is used within a Datagrid, the click event will
          // inadvertantly cause the Datagrid to route to the show page for the
          // record, which we don't want.
          e.stopPropagation();
          mutate({
            projectId: projectRecord.id,
            projectUserId: projectUserRecord.id,
          });
        }}
        // Ensure that the button text size matches that of all other text in
        // the Datagrid.
        sx={{ fontSize: "inherit" }}
      />
    );
  } else {
    return null;
  }
}

function ToggleBim(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    let mutationFn: MutationFunction<
      { data: ProjectShowResponse },
      ToggleBimMutationFnVariables
    >;
    let mutationKey: MutationKey;
    let successMessage: string;

    if (record.is_3d_bim_enabled) {
      mutationFn = (variables: ToggleBimMutationFnVariables) =>
        dataProvider.disableBim(props.region, variables.id);
      mutationKey = ["disableBim", record.id];
      successMessage = "Disabled 3D BIM successfully! ✅";
    } else {
      mutationFn = (variables: ToggleBimMutationFnVariables) =>
        dataProvider.enableBim(props.region, variables.id);
      mutationKey = ["enableBim", record.id];
      successMessage = "Enabled 3D BIM successfully! ✅";
    }

    return (
      <MutationActionMenuItem
        label="Toggle 3D BIM"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function ToggleChangeOrders(props: { region: Region }) {
  const ToggleChangeOrders = (props: {
    projectRecord: ProjectShowResponse;
    region: Region;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.projectRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      let mutationFn: MutationFunction<
        void,
        ToggleChangeOrdersMutationFnVariables
      >;
      let mutationKey: MutationKey;
      let successMessage: string;

      if (props.projectRecord.is_change_orders_enabled) {
        mutationFn = (variables: ToggleChangeOrdersMutationFnVariables) =>
          dataProvider.disableChangeOrders(props.region, variables.id);
        mutationKey = ["disableChangeOrders", props.projectRecord.id];
        successMessage =
          "Disabled change orders successfully. Please allow a few minutes for this to take effect! ✅";
      } else {
        mutationFn = (variables: ToggleChangeOrdersMutationFnVariables) =>
          dataProvider.enableChangeOrders(props.region, variables.id);
        mutationKey = ["enableChangeOrders", props.projectRecord.id];
        successMessage =
          "Enabled change orders successfully. Please allow a few minutes for this to take effect! ✅";
      }

      return (
        <MutationActionMenuItem
          label="Toggle Change Orders"
          mutationFn={mutationFn}
          mutationKey={mutationKey}
          mutationVariables={{ id: props.projectRecord.id }}
          successMessage={successMessage}
          // Disable toggle if project belongs to basic account.
          disabled={componentUtils.isAccountBasic(accountRecord)}
        />
      );
    } else {
      return null;
    }
  };

  const projectRecord = useRecordContext<ProjectShowResponse>();

  if (projectRecord) {
    return (
      <ToggleChangeOrders projectRecord={projectRecord} region={props.region} />
    );
  } else {
    return null;
  }
}

function ToggleBudget(props: { region: Region }) {
  const ToggleBudget = (props: {
    projectRecord: ProjectShowResponse;
    region: Region;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.projectRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      let mutationFn: MutationFunction<void, ToggleBudgetMutationFnVariables>;
      let mutationKey: MutationKey;
      let successMessage: string;

      if (props.projectRecord.is_budget_enabled) {
        mutationFn = (variables: ToggleBudgetMutationFnVariables) =>
          dataProvider.disableBudget(props.region, variables.id);
        mutationKey = ["disableBudget", props.projectRecord.id];
        successMessage =
          "Disabled budget successfully. Please allow a few minutes for this to take effect! ✅";
      } else {
        mutationFn = (variables: ToggleBudgetMutationFnVariables) =>
          dataProvider.enableBudget(props.region, variables.id);
        mutationKey = ["enableBudget", props.projectRecord.id];
        successMessage =
          "Enabled budget successfully. Please allow a few minutes for this to take effect! ✅";
      }

      return (
        <MutationActionMenuItem
          label="Toggle Budget"
          mutationFn={mutationFn}
          mutationKey={mutationKey}
          mutationVariables={{ id: props.projectRecord.id }}
          successMessage={successMessage}
          // Disable toggle if project belongs to basic account.
          disabled={componentUtils.isAccountBasic(accountRecord)}
        />
      );
    } else {
      return null;
    }
  };

  const projectRecord = useRecordContext<ProjectShowResponse>();

  if (projectRecord) {
    return <ToggleBudget projectRecord={projectRecord} region={props.region} />;
  } else {
    return null;
  }
}

function ToggleForms(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    let mutationFn: MutationFunction<
      { data: ProjectShowResponse },
      ToggleFormsMutationFnVariables
    >;
    let mutationKey: MutationKey;
    let successMessage: string;

    if (record.is_forms_enabled) {
      mutationFn = (variables: ToggleFormsMutationFnVariables) =>
        dataProvider.disableForms(props.region, variables.id);
      mutationKey = ["disableForms", record.id];
      successMessage = "Disabled forms successfully! ✅";
    } else {
      mutationFn = (variables: ToggleFormsMutationFnVariables) =>
        dataProvider.enableForms(props.region, variables.id);
      mutationKey = ["enableForms", record.id];
      successMessage = "Enabled forms successfully! ✅";
    }

    return (
      <MutationActionMenuItem
        label="Toggle Forms"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function ToggleMarkupSymbols(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    let mutationFn: MutationFunction<
      { data: ProjectShowResponse },
      ToggleMarkupSymbolsFnVariables
    >;
    let mutationKey: MutationKey;
    let successMessage: string;

    if (record.is_markup_symbols_enabled) {
      mutationFn = (variables: ToggleMarkupSymbolsFnVariables) =>
        dataProvider.disableMarkupSymbols(props.region, variables.id);
      mutationKey = ["disableMarkupSymbols", record.id];
      successMessage = "Disabled markup symbols successfully! ✅";
    } else {
      mutationFn = (variables: ToggleMarkupSymbolsFnVariables) =>
        dataProvider.enableMarkupSymbols(props.region, variables.id);
      mutationKey = ["enableMarkupSymbols", record.id];
      successMessage = "Enabled markup symbols successfully! ✅";
    }

    return (
      <MutationActionMenuItem
        label="Toggle Markup Symbols"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function TogglePm(props: { region: Region }) {
  const TogglePm = (props: {
    projectRecord: ProjectShowResponse;
    region: Region;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.projectRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      let mutationFn: MutationFunction<void, TogglePmMutationFnVariables>;
      let mutationKey: MutationKey;
      let successMessage: string;

      if (props.projectRecord.is_pm_enabled) {
        mutationFn = (variables: TogglePmMutationFnVariables) =>
          dataProvider.disablePm(props.region, variables.id);
        mutationKey = ["disablePm", props.projectRecord.id];
        successMessage =
          "Disabled RFIs successfully. Please allow a few minutes for this to take effect! ✅";
      } else {
        mutationFn = (variables: TogglePmMutationFnVariables) =>
          dataProvider.enablePm(props.region, variables.id);
        mutationKey = ["enablePm", props.projectRecord.id];
        successMessage =
          "Enabled RFIs successfully. Please allow a few minutes for this to take effect! ✅";
      }

      return (
        <MutationActionMenuItem
          label="Toggle RFIs"
          mutationFn={mutationFn}
          mutationKey={mutationKey}
          mutationVariables={{ id: props.projectRecord.id }}
          successMessage={successMessage}
          // Disable toggle if project belongs to basic account.
          disabled={componentUtils.isAccountBasic(accountRecord)}
        />
      );
    } else {
      return null;
    }
  };

  const projectRecord = useRecordContext<ProjectShowResponse>();

  if (projectRecord) {
    return <TogglePm projectRecord={projectRecord} region={props.region} />;
  } else {
    return null;
  }
}

function ToggleSpecs(props: { region: Region }) {
  const record = useRecordContext<ProjectShowResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    let mutationFn: MutationFunction<
      { data: ProjectShowResponse },
      ToggleSpecsMutationFnVariables
    >;
    let mutationKey: MutationKey;
    let successMessage: string;

    if (record.has_specs) {
      mutationFn = (variables: ToggleSpecsMutationFnVariables) =>
        dataProvider.disableSpecs(props.region, variables.id);
      mutationKey = ["disableSpecs", record.id];
      successMessage =
        "Disabled specifications successfully. Please allow a few minutes for this to take effect! ✅";
    } else {
      mutationFn = (variables: ToggleSpecsMutationFnVariables) =>
        dataProvider.enableSpecs(props.region, variables.id);
      mutationKey = ["enableSpecs", record.id];
      successMessage =
        "Enabled specifications successfully. Please allow a few minutes for this to take effect! ✅";
    }

    return (
      <MutationActionMenuItem
        label="Toggle Specifications"
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{ id: record.id }}
        successMessage={successMessage}
      />
    );
  } else {
    return null;
  }
}

function ToggleSubmittals(props: { region: Region }) {
  const ToggleSubmittals = (props: {
    projectRecord: ProjectShowResponse;
    region: Region;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { referenceRecord: accountRecord } = useReference<AccountResponse>({
      id: props.projectRecord.account_id,
      reference: "accounts",
    });

    if (accountRecord) {
      let mutationFn: MutationFunction<
        void,
        ToggleSubmittalsMutationFnVariables
      >;
      let mutationKey: MutationKey;
      let successMessage: string;

      if (props.projectRecord.is_submittals_enabled) {
        mutationFn = (variables: ToggleSubmittalsMutationFnVariables) =>
          dataProvider.disableSubmittals(props.region, variables.id);
        mutationKey = ["disableSubmittals", props.projectRecord.id];
        successMessage =
          "Disabled submittals successfully. Please allow a few minutes for this to take effect! ✅";
      } else {
        mutationFn = (variables: ToggleSubmittalsMutationFnVariables) =>
          dataProvider.enableSubmittals(props.region, variables.id);
        mutationKey = ["enableSubmittals", props.projectRecord.id];
        successMessage =
          "Enabled submittals successfully. Please allow a few minutes for this to take effect! ✅";
      }

      return (
        <MutationActionMenuItem
          label="Toggle Submittals"
          mutationFn={mutationFn}
          mutationKey={mutationKey}
          mutationVariables={{ id: props.projectRecord.id }}
          successMessage={successMessage}
          // Disable toggle if project belongs to basic account.
          disabled={componentUtils.isAccountBasic(accountRecord)}
        />
      );
    } else {
      return null;
    }
  };

  const projectRecord = useRecordContext<ProjectShowResponse>();

  if (projectRecord) {
    return (
      <ToggleSubmittals projectRecord={projectRecord} region={props.region} />
    );
  } else {
    return null;
  }
}

// Custom Fields

function InvitedBy(_props: { label: string; sortable: false }) {
  const InvitedBy = (props: { projectUserRecord: ProjectUserResponse }) => {
    const { referenceRecord: userRecord } = useReference<UserResponse>({
      reference: "users",
      id: props.projectUserRecord.user_id,
    });
    if (userRecord) {
      return (
        <RecordContextProvider value={userRecord}>
          <CustomReferenceFieldLazy
            reference={Resource.User}
            source="invited_by_id"
          />
        </RecordContextProvider>
      );
    } else {
      return null;
    }
  };

  const projectUserRecord = useRecordContext<ProjectUserResponse>();
  if (projectUserRecord) {
    return <InvitedBy projectUserRecord={projectUserRecord} />;
  } else {
    return null;
  }
}

function ProjectShow(props: { region: Region }) {
  const fieldwireActions = [
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ExportProject region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.ConsolidatedProject,
      element: <ReplicateProjectToConsolidatedTable region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <TogglePm region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleSubmittals region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleChangeOrders region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleBudget region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleSpecs region={props.region} />,
    },
    // NOTE: We are keeping these permissions consistent with
    // the other project toggles. However, the BE does enforce
    // that the current admin user has either the super role or
    // the finance operator role. Therefore, this action will fail
    // account managers and support. This is OK for now since the
    // uptake on Admin V2 isn't too high yet and the toggle will
    // be removed completely within a few weeks.
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleMarkupSymbols region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleForms region={props.region} />,
    },
    {
      checkPermissionForAction: Action.Update,
      checkPermissionForDataKind: DataKind.Project,
      element: <ToggleBim region={props.region} />,
    },
  ];

  // Audit logs aren't available in EU yet. Until then, we won't be showing
  // this in the options menu
  if (props.region === Region.Us) {
    fieldwireActions.unshift({
      checkPermissionForAction: Action.Read,
      checkPermissionForDataKind: DataKind.Project,
      element: <AuditLogsProjects />,
    });
  }

  return (
    <CustomShow
      checkPermissionFor={DataKind.Project}
      displayDelete={false}
      displayEdit={true}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF PROJECT DETAILS */}
      <ShowTable title="Project Details">
        <TextField label="Name" source="name" />
        <TextField label="Company Name" source="company_name" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Last Active At" source="last_active_at" />
        <NumberField label="Sum. Lead Score" source="lead_score" />
        <NumberField label="Avg. Lead Score" source="lead_score_avg" />
        <NumberField label="Total Users" source="total_users" />
        <NumberField label="30 Day Users" source="active_30_users" />
        <NumberField label="Task Count" source="tasks_sequence_counter" />
        <TextField label="Address" source="address" />
        <BooleanField
          label={
            <LabelWithInfoTooltip
              labelText="Is Sample Project"
              tooltipText="Sample projects are not included in analytics."
            />
          }
          source="is_sample_project"
        />
        <BooleanField label="Are Tasks Enabled" source="is_tasks_enabled" />
        <BooleanField
          label="Are Photo Attachments Enabled"
          source="is_photo_attachments_enabled"
        />
        <BooleanField
          label="Is Per Project Pricing"
          source="is_per_project_pricing"
        />
        <BooleanField label="BIM Enabled" source="is_3d_bim_enabled" />
        <BooleanField
          label="Is PTS Enabled"
          source="is_plan_text_search_enabled"
        />
        <BooleanField label="Are Forms Enabled" source="is_forms_enabled" />
        <BooleanField label="Are RFIs Enabled" source="is_pm_enabled" />
        <BooleanField
          label="Are Submittals Enabled"
          source="is_submittals_enabled"
        />
        <BooleanField
          label="Are Change Orders Enabled"
          source="is_change_orders_enabled"
        />
        <BooleanField label="Is Budget Enabled" source="is_budget_enabled" />
        <BooleanField label="Are Specs Enabled" source="has_specs" />
        <BooleanField
          label="Are Markup Symbols Enabled"
          source="is_markup_symbols_enabled"
        />
        <NumberField label="Monthly Recurring Revenue (MRR)" source="mrr" />
        <CustomReferenceField
          label="Owner Email"
          reference={Resource.User}
          source="owner_id"
        >
          <TextField source="email"></TextField>
        </CustomReferenceField>
        <TextField label="Account" source="account_id" />
        <CustomDateTimeField label="Archived At" source="archived_at" />
        <CustomDateTimeField label="Deleted At" source="deleted_at" />
        <CustomReferenceField
          label="Deleted By"
          source="deleted_by_user_id"
          reference={Resource.User}
          sortable={false}
        >
          <TextField source="email" />
        </CustomReferenceField>
        <CustomDateTimeField label="Trial Start At" source="trial_start_at" />
        <CustomDateTimeField label="Trial End At" source="trial_end_at" />
        <CustomDateTimeField
          label="Contract Start At"
          source="contract_start_at"
        />
        <CustomDateTimeField label="Contract End At" source="contract_end_at" />
        <CustomDateTimeField label="Invoice Paid At" source="invoice_paid_at" />
        <CustomDateTimeField label="Next Invoice At" source="next_invoice_at" />
        <FunctionField
          label="Sync Token"
          render={(record: ProjectShowResponse) => {
            // prettier-ignore
            switch (true) {
              case !!record.microsoft_token_id:
                const msTokenName = `Microsoft Token ${record.microsoft_token_id} - ${props.region.toUpperCase()}`;
                const msHref = routes.computeShowFor(
                  resourceUtils.microsoftTokenResourceFor(props.region),
                  record.microsoft_token_id!
                );

                return (<Link href={msHref}>{msTokenName}</Link>);
              case !!record.dropbox_token_id:
                const dbTokenName = `Dropbox Token ${record.dropbox_token_id} - ${props.region.toUpperCase()}`;
                const dbHref = routes.computeShowFor(
                  resourceUtils.dropboxTokenResourceFor(props.region),
                  record.dropbox_token_id!
                );

                return (<Link href={dbHref}>{dbTokenName}</Link>);
              case !!record.box_token_id:
                const boxTokenName = `Box Token ${record.box_token_id} - ${props.region.toUpperCase()}`;
                const boxHref = routes.computeShowFor(
                  resourceUtils.boxTokenResourceFor(props.region),
                  record.box_token_id!
                );

                return (<Link href={boxHref}>{boxTokenName}</Link>);
              case !!record.kahua_token_id:
                // At this point, all of the other tokens are null, and because
                // we do not have a show page set up for the Kahua token we will
                // display it in plain text.
                return `Kahua Token ${record.kahua_token_id}`;
              default:
                return null;
            }
          }}
        />
        <TextField label="Sync Id" source="sync_id" />
        <NumberField label="Sheets" source="sheets" />
        <NumberField label="Tasks" source="metrics_tasks_count" />
        <NumberField label="Photos" source="metrics_photos_count" />
        <NumberField label="Forms" source="metrics_forms_count" />
        <NumberField label="Files" source="metrics_files_count" />
        <CustomDateTimeField
          label="Scores Cached On"
          source="scores_cached_on"
        />
      </ShowTable>
      {/* END OF PROJECT DETAILS */}

      {/* START OF USERS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.ProjectsUser}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Users">
          <CustomReferenceManyField
            label={false}
            defaultSort={{ field: "user_id", order: "DESC" }}
            displayPagination={true}
            reference={Resource.User}
            target={resourceUtils.projectResourceFor(props.region)}
          >
            <CustomDatagrid
              // React Admin uses the `id` of the underlying record in order to
              // construct the show page route to navigate to. However, in this
              // case, the underlying record is a project user record, not a user
              // record (even though React Admin "believes" it is due to the config
              // of the `CustomReferenceManyField` above).
              customRowClickConfig={{
                reference: Resource.User,
                source: "user_id",
              }}
            >
              {/* REG-1924: This leads to react-admin rendering an empty column if
              authorization check fails. Noticed by our very keen eyed Michael:
              https://github.com/Fieldwire/fieldwire_admin/pull/317#discussion_r1232537341 */}
              <Authorize
                action={Action.Update}
                dataKind={DataKind.ConsolidatedProjectUsers}
                disableUnauthorizedMessage
              >
                <FunctionField
                  label={
                    <LabelWithInfoTooltip
                      labelText="Replicate"
                      tooltipText="Replicates project user to consolidated project user table."
                    />
                  }
                  render={(_: ProjectUserResponse) => {
                    return (
                      <ReplicateProjectUserToConsolidatedTable
                        region={props.region}
                      />
                    );
                  }}
                  sortable={false}
                />
              </Authorize>
              <TextField label="Id" source="user_id" />
              <BooleanField
                label="Is User Approved for Project"
                source="is_approved"
                sortable={false}
              />
              <CustomReferenceField
                label="Email"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField source="email" />
              </CustomReferenceField>
              <CustomReferenceField
                label="First Name"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField source="first_name" />
              </CustomReferenceField>
              <CustomReferenceField
                label="Last Name"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField source="last_name" />
              </CustomReferenceField>
              <CustomReferenceField
                label="Company"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField source="company" />
              </CustomReferenceField>
              <CustomReferenceField
                label={
                  <LabelWithInfoTooltip
                    labelText="Email Confirmation Status"
                    tooltipText={EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT}
                  />
                }
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <FunctionField
                  render={(userRecord: UserResponse) =>
                    componentUtils.snakeCaseToCapitalCase(
                      userRecord.email_confirmation_status
                    )
                  }
                />
              </CustomReferenceField>
              <CustomReferenceField
                label="User Created At"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <CustomDateTimeField
                  label="User Created At"
                  source="created_at"
                />
              </CustomReferenceField>
              <CustomDateTimeField
                label="Project User Created At"
                source="created_at"
              />
              <CustomReferenceField
                label="Current Sign In At"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <CustomDateTimeField
                  label="Current Sign In At"
                  source="current_sign_in_at"
                />
              </CustomReferenceField>
              <InvitedBy label="Invited By" sortable={false} />
              <CustomReferenceField
                label="User Updated At"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <CustomDateTimeField
                  label="User Updated At"
                  source="updated_at"
                />
              </CustomReferenceField>
              <CustomDateTimeField
                label="Project User Updated At"
                source="updated_at"
              />
              <CustomDateTimeField
                label="Project User Deleted At"
                source="deleted_at"
              />
              <TextField label="Role" source="role" />
              <CustomReferenceField
                label="Lead Score"
                source="user_id"
                reference={Resource.User}
                sortable={false}
              >
                <TextField label="Lead Score" source="lead_score" />
              </CustomReferenceField>
              {/* MISSING: Is Covered */}
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF USERS */}
    </CustomShow>
  );
}

export default ProjectShow;
