/**
 * The `ActionButtonsBar` component is meant to be used within
 * a form or modal-like component to organize action buttons
 * such as as cancel, submit, etc.
 */

import Box from "@mui/material/Box";

interface ActionButtonsBarProps {
  children: any;
}

function ActionButtonsBar(props: ActionButtonsBarProps) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        gap: "16px",
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </Box>
  );
}

export default ActionButtonsBar;
