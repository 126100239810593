import { SelectInput, TextField, TextInput } from "react-admin";

import {
  EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT,
  ROUNDED_PRICE_TOOLTIP_TEXT,
} from "../../constants";
import { DataKind, EnterprisePlanInvoiceKind, Resource } from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  EnterprisePlanInvoiceMrrField,
  LabelWithInfoTooltip,
  RoundedPriceField,
} from "../custom";

const kindChoices = componentUtils.generateCapitalCaseSelectChoicesFromEnum(
  EnterprisePlanInvoiceKind
);

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const enterprisePlanInvoiceFilters = [
  <TextInput
    label="Enterprise Plan Name"
    source="enterprise_plan_name_like"
    alwaysOn
  />,
  <TextInput
    label={
      <LabelWithInfoTooltip
        labelText="Account Owner Email"
        tooltipText={EP_EPI_OWNER_EMAIL_FILTER_TOOLTIP_TEXT}
      />
    }
    source="owner_email_like"
    alwaysOn
  />,
  <TextInput label="Account ID" source="account_id_eq" alwaysOn />,
  <SelectInput choices={kindChoices} label="Kind" source="kind_eq" alwaysOn />,
];

function EnterprisePlanInvoiceList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.EnterprisePlanInvoice}
      displayCreate={false}
      filters={enterprisePlanInvoiceFilters}
    >
      <TextField label="Id" source="id" />
      <CustomReferenceField
        label="Enterprise Plan"
        source="enterprise_plan_id"
        reference={Resource.EnterprisePlan}
        sortable={false}
      >
        <TextField source="name" />
      </CustomReferenceField>
      <CustomReferenceField
        label="Owner Email"
        source="enterprise_plan_id"
        reference={Resource.EnterprisePlan}
        sortable={false}
      >
        <TextField source="owner_email" />
      </CustomReferenceField>
      {/* MISSING: Account (might be able to use a nested reference?) */}
      <TextField label="Description" source="description" />
      <TextField
        label="Currency"
        source="currency"
        sx={{ textTransform: "uppercase" }}
        sortable={false}
      />
      <RoundedPriceField
        label={
          <LabelWithInfoTooltip
            labelText="Amount"
            tooltipText={ROUNDED_PRICE_TOOLTIP_TEXT}
          />
        }
        source="amount_native_currency"
        sortable={false}
      />
      <CustomDateTimeField label="Start At" source="start_at" />
      <CustomDateTimeField label="End At" source="end_at" />
      <CustomDateTimeField label="Paid At" source="paid_at" />
      <TextField label="Kind" source="kind" />
      <EnterprisePlanInvoiceMrrField label="MRR (USD)" sortable={false} />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default EnterprisePlanInvoiceList;
