import { NumberField, TextField, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const adminUserFilters = [
  <TextInput label="Email" source="email_like" alwaysOn />,
];

function AdminUserList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.AdminUser}
      displayCreate={true}
      filters={adminUserFilters}
    >
      <TextField label="Id" source="id" />
      <TextField label="Email" source="email" sortable={false} />
      <NumberField
        label="Failed attempts"
        source="failed_attempts"
        sortable={false}
      />
      <NumberField label="Sign In Count" source="sign_in_count" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField
        label="Locked At"
        source="locked_at"
        sortable={false}
      />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default AdminUserList;
