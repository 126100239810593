import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import { Action, DataKind } from "../../types";
import { env } from "../../utils";
import {
  ActionMenu,
  Authorize,
  ChartContainer,
  CustomMenuItem,
  TitleRow,
} from "../custom";

interface ExternalDashboardLinkProps {
  label: string;
  url?: string;
}

function ExternalDashboardLink(props: ExternalDashboardLinkProps) {
  if (props.url) {
    return (
      <CustomMenuItem
        // @ts-expect-error
        component="a"
        href={props.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.label}
      </CustomMenuItem>
    );
  } else {
    return (
      <Tooltip title="Dashboard URL is not set as an environment variable!">
        {/*
          We wrap the disabled CustomMenuItem in a Box as the Tooltip
          doesn't work properly when its direct child component is disabled.
          Please see this thread for more information:
          https://github.com/mui/material-ui/issues/8416
        */}
        <Box>
          <CustomMenuItem disabled>{props.label}</CustomMenuItem>
        </Box>
      </Tooltip>
    );
  }
}

function IFrame(props: { src: string }) {
  return (
    <Box
      component="iframe"
      src={props.src}
      sx={{ border: "none", height: "100%", width: "100%" }}
    ></Box>
  );
}

// The dashboard pages below are protected by their own auth (AWS SSO) but still
// we have permissions setup to stick to the requirements of `FieldwireAction`
const fieldwireActions = [
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.SystemStatus,
    element: (
      <ExternalDashboardLink
        label="View EU Dashboard"
        url={env.system_status.fetch_dashboard_url_for_eu()}
      />
    ),
  },
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.SystemStatus,
    element: (
      <ExternalDashboardLink
        label="View US Dashboard"
        url={env.system_status.fetch_dashboard_url_for_us()}
      />
    ),
  },
];

function SystemStatus() {
  return (
    <Authorize action={Action.Read} dataKind={DataKind.SystemStatus}>
      <TitleRow
        actions={<ActionMenu fieldwireActions={fieldwireActions} />}
        title="System Status"
      />
      <Alert severity="warning">
        These charts are not environment dependent. They are hardcoded to
        represent production data. This means they will display production data
        whether this code is being run in production, staging, or locally.
      </Alert>
      {/* Grid layout with two charts per line and 16px gaps. */}
      <Box
        sx={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "24px",
        }}
      >
        <ChartContainer title="iOS Release Status">
          <IFrame src="https://datastudio.google.com/embed/reporting/11538df1-62a7-49fc-a4f3-3dca7d78a3cb/page/1xZU" />
        </ChartContainer>
        <ChartContainer title="Android Release Status">
          <IFrame src="https://datastudio.google.com/embed/reporting/4d5e71b1-b3cb-41a2-9e66-0749e3081053/page/1xZU" />
        </ChartContainer>
      </Box>
    </Authorize>
  );
}

export default SystemStatus;
