import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  HttpError as ReactAdminHttpError,
  Identifier,
  SortPayload,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";

import {
  AccountAnalyticsResponse,
  AccountResponse,
  AccountWorkatoConnectedAppsResponse,
  ActiveUserCountsForFinancesResponse,
  ActiveUserCountsForMarketingResponse,
  BoxTokenResponse,
  CreateDemoProjectRequest,
  CustomDataProvider,
  DropboxTokenResponse,
  ExportAccountRequest,
  ExportAuditLogsForProjectRequest,
  ExportProjectRequest,
  HttpClientError,
  HttpClientResponse,
  InviteUserToFieldwireRequest,
  Language,
  MetricsResponse,
  MicrosoftTokenResponse,
  ProjectShowResponse,
  Region,
  SignupEmailVerificationDistributionResponse,
  TransferProjectsRequest,
  UpdateCreditCardRequest,
  UpdateStripeSubscriptionRequest,
  UserCheckoutInfoResponse,
  UsersSignupsCountryDistributionResponse,
  UserSyncTokensInfoResponse,
} from "../types";
import { apiUtils, urlBuilder } from "./api_utils";
import { httpClient } from "./http_client";

function notImplemented(): Promise<any> {
  return Promise.resolve();
}

/**
 * Per the React Admin documentation, when a data provider method receives
 * receives an error from the backend, it should return a rejected Promise
 * containing an Error object. The Error object should have, at least, a
 * message and a status code. We use React Admin's HttpError class which
 * extends the native JS Error class.
 */
function rejectWithErrorMessage(err: HttpClientError) {
  const message = apiUtils.generateErrorMessage(err);
  return Promise.reject(new ReactAdminHttpError(message, err.response?.status));
}

const dataProvider: CustomDataProvider = {
  getList: (resource: string, params: GetListParams) => {
    const pagination = params.pagination;
    const url = urlBuilder.getList(
      resource,
      params.filter,
      params.sort,
      pagination
    );

    return httpClient
      .get(url)
      .then((res: HttpClientResponse) => ({
        data: res.data,
        pageInfo: {
          hasPreviousPage: pagination.page > 1,
          hasNextPage: res.data.length === pagination.perPage,
        },
      }))
      .catch(rejectWithErrorMessage);
  },

  getOne: (resource: string, params: GetOneParams) => {
    const url = urlBuilder.getOne(resource, params.id);
    return httpClient
      .get(url)
      .then((res: HttpClientResponse) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  getMany: (resource: string, params: GetManyParams) => {
    const url = urlBuilder.getMany(resource, params.ids);
    return httpClient
      .get(url)
      .then((res: HttpClientResponse) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  // We've identified a bug in React Admin's ReferenceManyField implementation in
  // which the pagination controls only get rendered when `total` isn't undefined.
  // However, the ReferenceManyField is supposed to support partial pagination, in
  // which `total` is undefined and `pageInfo` is used instead. As a workaround -
  // until the bug gets fixed - we return `total` as null, which allows pagination
  // controls to be rendered. We utilize the @ts-expect-error directive because the
  // expected type of `total` is number or undefined.
  // @ts-expect-error
  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const pagination = params.pagination;
    const url = urlBuilder.getManyReferences(
      resource,
      params.id,
      params.target,
      params.sort,
      params.pagination
    );

    return httpClient
      .get(url)
      .then((res: HttpClientResponse) => ({
        // This is needed because `getManyReference` expects data to
        // be of type `any[]`. An example in which `getManyReference`
        // is called and our BE doesn't return a vector of data is
        // when we fetch account info on the user show page using
        // the `CustomReferenceManyField`.
        data: !Array.isArray(res.data) ? [res.data] : res.data,
        pageInfo: {
          hasPreviousPage: pagination.page > 1,
          hasNextPage: res.data.length === pagination.perPage,
        },
        total: null,
      }))
      .catch(rejectWithErrorMessage);
  },

  update: (resource: string, params: UpdateParams) => {
    const url = urlBuilder.update(resource, params.id);
    const data = apiUtils.generateDataForUpdate(params);
    return httpClient
      .patch(url, data)
      .then((res: HttpClientResponse) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  updateMany: (_resource: string, _params: UpdateManyParams) =>
    notImplemented(),

  create: (resource: string, params: CreateParams) => {
    const url = urlBuilder.create(resource);
    const data = apiUtils.generateDataForCreate(params.data);
    return httpClient
      .post(url, data)
      .then((res: HttpClientResponse) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  delete: (resource: string, params: DeleteParams) => {
    const url = urlBuilder.delete(resource, params.id);
    return httpClient.delete(url).then().catch(rejectWithErrorMessage);
  },

  deleteMany: (_resource: string, _params: DeleteManyParams) =>
    notImplemented(),

  // Custom Methods

  clearSubscriptionForMicrosoftToken(
    region: Region,
    microsoftTokenId: Identifier
  ) {
    const url = urlBuilder.regional(
      region,
      `microsoft_tokens/${microsoftTokenId}/clear_subscription`
    );
    return httpClient
      .post<MicrosoftTokenResponse>(url)
      .then((res: HttpClientResponse<MicrosoftTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  createDemoProject(
    region: Region,
    createDemoProjectRequest: CreateDemoProjectRequest
  ) {
    // Demo projects are right now only supported in the US region
    const url = urlBuilder.regional(region, "demo_projects");
    return httpClient
      .post<void, void, CreateDemoProjectRequest>(url, createDemoProjectRequest)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disableBim: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_3d_bim`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disableChangeOrders: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_change_orders`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disableBudget: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_budget`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disableForms: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_forms`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disableMarkupSymbols: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_markup_symbols`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disablePm: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(region, `projects/${projectId}/disable_pm`);
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disableSpecs: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_specs`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disableSpecsForAllProjects(region: Region, accountId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `accounts/${accountId}/disable_specs_for_all_projects`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disableSubmittals: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/disable_submittals`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  disconnectProjectsForBoxToken: (region: Region, boxTokenId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `box_tokens/${boxTokenId}/disconnect_projects`
    );
    return httpClient
      .post<BoxTokenResponse>(url)
      .then((res: HttpClientResponse<BoxTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disconnectProjectsForDropboxToken: (
    region: Region,
    dropboxTokenId: Identifier
  ) => {
    const url = urlBuilder.regional(
      region,
      `dropbox_tokens/${dropboxTokenId}/disconnect_projects`
    );
    return httpClient
      .post<DropboxTokenResponse>(url)
      .then((res: HttpClientResponse<DropboxTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  disconnectProjectsForMicrosoftToken: (
    region: Region,
    microsoftTokenId: Identifier
  ) => {
    const url = urlBuilder.regional(
      region,
      `microsoft_tokens/${microsoftTokenId}/disconnect_projects`
    );
    return httpClient
      .post<MicrosoftTokenResponse>(url)
      .then((res: HttpClientResponse<MicrosoftTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  enableBim: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_3d_bim`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  enableChangeOrders: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_change_orders`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  enableBudget: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_budget`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  enableForms: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_forms`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  enableMarkupSymbols: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_markup_symbols`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  enablePm: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(region, `projects/${projectId}/enable_pm`);
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  enableSpecs: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_specs`
    );
    return httpClient
      .post<ProjectShowResponse>(url)
      .then((res: HttpClientResponse<ProjectShowResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  enableSpecsForAllProjects(region: Region, accountId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `accounts/${accountId}/enable_specs_for_all_projects`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  enableSubmittals: (region: Region, projectId: Identifier) => {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/enable_submittals`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  exportAccountUsersAndHistoricalData: (accountId: Identifier) => {
    const url = urlBuilder.super(`accounts/${accountId}/export`);
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  exportAccountProjectData: (
    region: Region,
    accountId: Identifier,
    adminUserEmail: string
  ) => {
    const url = urlBuilder.regional(region, `accounts/${accountId}/export`);
    return httpClient
      .post<void, void, ExportAccountRequest>(url, {
        email: adminUserEmail,
      })
      .then()
      .catch(rejectWithErrorMessage);
  },

  exportAuditLogsForProject: (
    projectId: Identifier,
    adminUserEmail: string,
    filter: any,
    sort: SortPayload
  ) => {
    // Query parameters take the form of API filters & include the project ID.
    // We set `project_id_eq` last so that it's not overridden by whatever might
    // already be present in `filters`
    const queryParams = apiUtils.queryParametersStringify({
      filters: apiUtils.formatFilters({
        ...filter,
        project_id_eq: projectId,
      }),
      sorts: apiUtils.formatSorts(sort),
    });

    // Hardcoded to US since that's the only region that audit logs are available
    const url = urlBuilder.regional(
      Region.Us,
      `audit_logs/export?${queryParams}`
    );

    return httpClient
      .post<void, void, ExportAuditLogsForProjectRequest>(url, {
        email: adminUserEmail,
      })
      .then()
      .catch(rejectWithErrorMessage);
  },

  exportProject(region: Region, projectId: Identifier, userId: Identifier) {
    const url = urlBuilder.regional(region, "projects/create_project_export");
    return httpClient
      .post<void, void, ExportProjectRequest>(url, {
        project_id: projectId,
        user_id: userId,
      })
      .then()
      .catch(rejectWithErrorMessage);
  },

  forceUnblockAccount(accountId: Identifier) {
    const url = urlBuilder.super(
      `accounts/${accountId}/unblock?require_under_limit=false`
    );
    return httpClient
      .post<AccountResponse>(url)
      .then((res: HttpClientResponse<AccountResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  getAccountAnalytics(accountId: Identifier) {
    const url = urlBuilder.super(
      `analytics/account_analytics?${apiUtils.queryParametersStringify({
        filters: apiUtils.formatFilters({
          account_id_eq: accountId,
        }),
      })}`
    );

    // This endpoint returns an array. Since our backend has a 1:1 mapping
    // between accounts & account_analytics, we can just extract out the
    // first record in the result (if there is one)
    return httpClient
      .get<AccountAnalyticsResponse[]>(url)
      .then((res: HttpClientResponse<AccountAnalyticsResponse[]>) => ({
        data: res.data.at(0),
      }))
      .catch(rejectWithErrorMessage);
  },

  getAccountWorkatoConnectedApps(region: Region, accountId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `accounts/${accountId}/workato_connected_apps`
    );
    return httpClient
      .get<AccountWorkatoConnectedAppsResponse[]>(url)
      .then(
        (res: HttpClientResponse<AccountWorkatoConnectedAppsResponse[]>) => ({
          data: res.data,
        })
      )
      .catch(rejectWithErrorMessage);
  },

  getActiveUserCountsForFinances() {
    const url = urlBuilder.super("analytics/finance_counts");
    return httpClient
      .get<ActiveUserCountsForFinancesResponse[]>(url)
      .then(
        (res: HttpClientResponse<ActiveUserCountsForFinancesResponse[]>) => ({
          data: res.data,
        })
      )
      .catch(rejectWithErrorMessage);
  },

  getActiveUserCountsForMarketing() {
    const url = urlBuilder.super("analytics/marketing_counts");
    return httpClient
      .get<ActiveUserCountsForMarketingResponse[]>(url)
      .then(
        (res: HttpClientResponse<ActiveUserCountsForMarketingResponse[]>) => ({
          data: res.data,
        })
      )
      .catch(rejectWithErrorMessage);
  },

  getAuthCookieForSidekiq(region: Region) {
    // The response to this GET request will carry a `Set-Cookie` header.
    // In order to ensure that the header is respected, we need to pass
    // the `{ withCredentials: true }` config for this request. Please
    // see the following link for more information:
    //
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#requests_with_credentials
    const url = urlBuilder.regional(region, "auth_cookie");
    return httpClient
      .get<void, void>(url, { withCredentials: true })
      .then()
      .catch(rejectWithErrorMessage);
  },

  getMetrics() {
    // Analytics data is right now only available in the US region
    const url = urlBuilder.regional(
      Region.Us,
      "analytics/active_user_counts/compute_metrics"
    );
    return httpClient
      .get<MetricsResponse>(url)
      .then((res: HttpClientResponse<MetricsResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  getSignupEmailVerificationDistribution() {
    // Analytics data is present in both EU and US regions (replicated from the super service)
    // However it is more convenient and aligns with existing patterns to only fetch it from the US
    const url = urlBuilder.regional(
      Region.Us,
      "analytics/users/signup_email_verification_distribution"
    );
    return httpClient
      .get<SignupEmailVerificationDistributionResponse>(url)
      .then(
        (
          res: HttpClientResponse<SignupEmailVerificationDistributionResponse>
        ) => ({
          data: res.data,
        })
      )
      .catch(rejectWithErrorMessage);
  },

  getUserCheckoutInfo(userId: Identifier) {
    const url = urlBuilder.super(`users/${userId}/checkout_info`);
    return httpClient
      .get<UserCheckoutInfoResponse>(url)
      .then((res: HttpClientResponse<UserCheckoutInfoResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  getUsersSignupsCountryDistribution() {
    // Analytics data is right now only available in the US region
    const url = urlBuilder.regional(
      Region.Us,
      "analytics/users/signup_country_distribution"
    );
    return httpClient
      .get<UsersSignupsCountryDistributionResponse[]>(url)
      .then(
        (
          res: HttpClientResponse<UsersSignupsCountryDistributionResponse[]>
        ) => ({
          data: res.data,
        })
      )
      .catch(rejectWithErrorMessage);
  },

  getUserSyncTokensInfo(region: Region, userId) {
    const url = urlBuilder.regional(region, `users/${userId}/sync_tokens_info`);
    return httpClient
      .get<UserSyncTokensInfoResponse>(url)
      .then((res: HttpClientResponse<UserSyncTokensInfoResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  inviteUserToFieldwire(email: string, language: Language) {
    const url = urlBuilder.super("users/invite");
    return httpClient
      .post<void, void, InviteUserToFieldwireRequest>(url, {
        email,
        language,
      })
      .then()
      .catch(rejectWithErrorMessage);
  },

  markUserForPendingDeletion(userId: Identifier) {
    const url = urlBuilder.super(`users/${userId}/mark_for_pending_deletion`);
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  removeUserFromAllProjects(region: Region, userId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `users/${userId}/remove_from_all_projects`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  replicateProjectToConsolidatedTable(region: Region, projectId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/replicate_to_consolidated_table`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  replicateProjectUserToConsolidatedTable(
    region: Region,
    projectId: Identifier,
    projectUserId: Identifier
  ) {
    const url = urlBuilder.regional(
      region,
      `projects/${projectId}/project_users/${projectUserId}/replicate_to_consolidated_table`
    );
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  resetCursor(region: Region, dropboxTokenId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `dropbox_tokens/${dropboxTokenId}/reset_cursor`
    );
    return httpClient
      .post<DropboxTokenResponse>(url)
      .then((res: HttpClientResponse<DropboxTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  resetDeltaLink(region: Region, microsoftTokenId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `microsoft_tokens/${microsoftTokenId}/reset_delta_link`
    );
    return httpClient
      .post<MicrosoftTokenResponse>(url)
      .then((res: HttpClientResponse<MicrosoftTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  resetStreamPosition(region: Region, boxTokenId: Identifier) {
    const url = urlBuilder.regional(
      region,
      `box_tokens/${boxTokenId}/reset_stream_position`
    );
    return httpClient
      .post<BoxTokenResponse>(url)
      .then((res: HttpClientResponse<BoxTokenResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  retryCharge(chargeId: Identifier) {
    const url = urlBuilder.super(`charges/${chargeId}/retry`);
    return httpClient
      .patch<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  transferProjects(
    region: Region,
    destinationAccountId: Identifier,
    sourceAccountId: Identifier
  ) {
    const url = urlBuilder.regional(
      region,
      `accounts/${sourceAccountId}/transfer_projects`
    );
    return httpClient
      .post<void, void, TransferProjectsRequest>(url, {
        destination_account_id: destinationAccountId,
      })
      .then()
      .catch(rejectWithErrorMessage);
  },

  unblockAccount(accountId: Identifier) {
    const url = urlBuilder.super(
      `accounts/${accountId}/unblock?require_under_limit=true`
    );
    return httpClient
      .post<AccountResponse>(url)
      .then((res: HttpClientResponse<AccountResponse>) => ({
        data: res.data,
      }))
      .catch(rejectWithErrorMessage);
  },

  unlockUser(id: Identifier) {
    const url = urlBuilder.super(`users/${id}/unlock`);
    return httpClient
      .post<void, void>(url)
      .then()
      .catch(rejectWithErrorMessage);
  },

  updateCreditCard(
    accountId: Identifier,
    updateCreditCardRequest: UpdateCreditCardRequest
  ) {
    const url = urlBuilder.super(`accounts/${accountId}/update_card`);
    return httpClient
      .patch<void, void, UpdateCreditCardRequest>(url, updateCreditCardRequest)
      .then()
      .catch(rejectWithErrorMessage);
  },

  updateStripeSubscription(
    userId: Identifier,
    updateStripeSubscriptionRequest: UpdateStripeSubscriptionRequest
  ) {
    const url = urlBuilder.super(`users/${userId}/subscription`);
    return httpClient
      .patch<void, void, UpdateStripeSubscriptionRequest>(
        url,
        updateStripeSubscriptionRequest
      )
      .then()
      .catch(rejectWithErrorMessage);
  },
};

export default dataProvider;
