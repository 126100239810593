import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForFinancesResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, StackedAreaChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function PayingUsersChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForFinancesResponse[];
  }>(["getActiveUserCountsForFinances"], () =>
    dataProvider.getActiveUserCountsForFinances()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForFinancesResponse(),
      30
    );

    return (
      <ChartContainer
        title={`Paying Users (${analyticsUtils.calculateTotalPayingUsersLastDay(
          processedData
        )})`}
      >
        <StackedAreaChart
          labels={processedData.map(
            (d: ActiveUserCountsForFinancesResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.paying_pro
              ),
              label: "Pro",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.paying_business
              ),
              label: "Business",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForFinancesResponse) => d.paying_enterprise
              ),
              label: "Enterprise",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForFinancesResponse) =>
                  d.paying_business_plus
              ),
              label: "Business Plus",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default PayingUsersChart;
