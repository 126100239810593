import { NullableBooleanInput, SelectInput, TextInput } from "react-admin";

import { DataKind } from "../../types";
import {
  CustomEdit,
  CustomNumberInput,
  CustomUtcDateTimeInput,
  LabelWithInfoTooltip,
} from "../custom";

// TODO [Nice-to-Have] [REG-902]: Have util also handle enums with numeric values.
const salesAttributionChoices = [
  { id: 0, name: "Complete Process" },
  { id: 1, name: "Negotiated" },
  { id: 2, name: "Closed" },
];

function ProjectEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.Project}>
      <TextInput label="Name" source="name" required />
      <TextInput label="Address" source="address" />
      <NullableBooleanInput
        label={
          <LabelWithInfoTooltip
            labelText="Is Sample Project"
            tooltipText="Sample projects are not included in analytics."
          />
        }
        source="is_sample_project"
        required
      />
      <NullableBooleanInput
        label="Is Per Project Pricing"
        source="is_per_project_pricing"
        required
      />
      <CustomNumberInput label="Monthly Recurring Revenue (MRR)" source="mrr" />
      <CustomNumberInput label="Account ID" source="account_id" required />
      <CustomUtcDateTimeInput label="Archived At (UTC)" source="archived_at" />
      <CustomUtcDateTimeInput label="Deleted At (UTC)" source="deleted_at" />
      <CustomUtcDateTimeInput
        label="Contract Start At (UTC)"
        source="contract_start_at"
      />
      <CustomUtcDateTimeInput
        label="Contract End At (UTC)"
        source="contract_end_at"
      />
      <CustomUtcDateTimeInput
        label="Invoice Paid At (UTC)"
        source="invoice_paid_at"
      />
      <CustomUtcDateTimeInput
        label="Next Invoice At (UTC)"
        source="next_invoice_at"
      />
      <SelectInput
        label="Sales Attribution"
        source="sales_attribution"
        choices={salesAttributionChoices}
      />
      <CustomUtcDateTimeInput
        label="Commission Paid Date (UTC)"
        source="commission_paid_date"
      />
    </CustomEdit>
  );
}

export default ProjectEdit;
