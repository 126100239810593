import { TextField } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomShow, ShowTable } from "../custom";

function DemoProjectTemplateShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.DemoProjectTemplate}
      displayDelete={true}
      displayEdit={true}
    >
      <ShowTable title="Demo Project Template Details">
        <TextField label="Id" source="id" />
        <TextField label="Demo Project Id" source="demo_project_id" />
        <TextField label="Name" source="name" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default DemoProjectTemplateShow;
