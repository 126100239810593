import { storage } from "../storage";
import { Action, AdminUserRole, DataKind } from "../types";
import {
  ACCOUNT_MANAGER_PERMISSIONS,
  CONFIG_VARS_MANAGER_PERMISSIONS,
  DEBUG_INFO_VIEWER_PERMISSIONS,
  DEFAULT_PERMISSIONS,
  DEMO_TEMPLATES_MANAGER_PERMISSIONS,
  FINANCE_OPERATOR_PERMISSIONS,
  FINANCE_VIEWER_PERMISSIONS,
  JOBS_MANAGER_PERMISSIONS,
  MARKETER_PERMISSIONS,
  SSO_INFO_MANAGER_PERMISSIONS,
  SUPPORT_PERMISSIONS,
} from "./permissions";

interface ActionDataKindPair {
  action: Action;
  dataKind: DataKind;
}

const adminAbility = {
  /**
   * Determine if an admin user can perform an action
   * on a data kind for at least one of their role(s).
   */
  canAdminUserPerform: (action: Action, dataKind: DataKind): boolean => {
    const roles = storage.loadRoles();

    // If we can't determine an admin user's role(s), then we err on
    // the side of caution and do not allow the action to be performed.
    if (!roles) {
      console.log(
        "Could not determine admin user's role(s) while checking admin ability."
      );
      return false;
    }

    // If an admin user can perform the action on the data kind for at least
    // one of their roles, then we allow the action to be performed.
    return roles.some((role) =>
      canAdminUserPerformForRole(action, dataKind, role)
    );
  },

  /**
   * Determine if the current admin user can perform at least one
   * of a given list of action, data kind pairs given their role(s).
   */
  canAdminUserPerformAny: (
    actionDataKindPairs: ActionDataKindPair[]
  ): boolean => {
    return actionDataKindPairs.some((actionDataKindPair: ActionDataKindPair) =>
      adminAbility.canAdminUserPerform(
        actionDataKindPair.action,
        actionDataKindPair.dataKind
      )
    );
  },
};

/**
 * Determine if a specific role can perform an action on a data kind.
 */
const canAdminUserPerformForRole = (
  action: Action,
  dataKind: DataKind,
  role: AdminUserRole
): boolean => {
  switch (role) {
    // The super role can perform any action on any data kind.
    case AdminUserRole.Super:
      return true;
    // The following roles have a mixed set of privileges.
    case AdminUserRole.AccountManager:
      return !!ACCOUNT_MANAGER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.ConfigVarsManager:
      return !!CONFIG_VARS_MANAGER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.DebugInfoViewer:
      return !!DEBUG_INFO_VIEWER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.Default:
      return !!DEFAULT_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.DemoTemplatesManager:
      return !!DEMO_TEMPLATES_MANAGER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.FinanceOperator:
      return !!FINANCE_OPERATOR_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.FinanceViewer:
      return !!FINANCE_VIEWER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.JobsManager:
      return !!JOBS_MANAGER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.Marketer:
      return !!MARKETER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.SsoInfoManager:
      return !!SSO_INFO_MANAGER_PERMISSIONS[dataKind]?.includes(action);
    case AdminUserRole.Support:
      return !!SUPPORT_PERMISSIONS[dataKind]?.includes(action);
    // If the role doesn't match our known set of roles, something is
    // wrong. In this case, we err on the side of caution and do not
    // allow the action to be performed.
    default:
      return false;
  }
};

export default adminAbility;
