import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

function DemoProjectUserEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.DemoProjectUser}>
      <TextInput
        label="Demo Project Shadow User Id"
        source="demo_project_shadow_user_id"
        required
      />
      <TextInput
        label="Demo Project User Id"
        source="demo_project_user_id"
        required
      />
    </CustomEdit>
  );
}

export default DemoProjectUserEdit;
