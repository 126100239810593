import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider } from "react-admin";

import { useForm, useMutation } from "../../../hooks";
import {
  AccountResponse,
  CustomDataProvider,
  ModalProps,
  Region,
} from "../../../types";
import { ActionButtonsBar, Form, Modal } from "..";

interface TransferProjectsModalProps extends ModalProps {
  account: AccountResponse;
}

interface FormState {
  destinationAccountId: Identifier;
  sourceAccountId: Identifier;
}

function TransferProjectsModal(props: TransferProjectsModalProps) {
  const defaultFormState: FormState = {
    destinationAccountId: "",
    sourceAccountId: props.account.id,
  };

  const { form, handleChangeForm, handleClearForm } = useForm(defaultFormState);

  const dataProvider = useDataProvider<CustomDataProvider>();
  const mutationFn = (form: FormState) => {
    const promiseFns = Object.values(Region).map((region) => {
      return dataProvider.transferProjects(
        region,
        form.destinationAccountId,
        form.sourceAccountId
      );
    });

    return Promise.all(promiseFns);
  };

  const { mutate } = useMutation(
    mutationFn,
    ["transferProjects", form],
    `Projects from account ${form.sourceAccountId} will be transferred to account
          ${form.destinationAccountId} shortly. Please allow a few minutes for this to
          take effect! ✅`,
    true
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();
    props.handleClose();
    mutate(form);
  };

  return (
    <Modal open={props.modalOpen} title="Projects Transfer">
      <Form onSubmit={handleSubmit}>
        <Typography component="p" sx={{ color: "fieldwire.gray.1" }}>
          Please ensure that you enter, or have entered, the correct destination
          account ID. This action cannot be undone.
        </Typography>
        <TextField
          disabled
          label="Source Account Id"
          name="sourceAccountId"
          value={form.sourceAccountId}
          required
        />
        <TextField
          label="Desination Account Id"
          name="destinationAccountId"
          onChange={handleChangeForm}
          type="number"
          value={form.destinationAccountId}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!form.destinationAccountId}
            label="Transfer Projects"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default TransferProjectsModal;
