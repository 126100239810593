/**
 * The MutationActionButton is a very opinionated wrapper around the Button
 * component used to perform custom mutations. An example of a custom mutation
 * would be unlocking a user or toggling BIM on an account.
 *
 * Necessary:
 * - Mutation function must take some object as argument.
 * - Success message always shown after successful mutation.
 * - Error message always shown after unsuccessful mutation.
 *
 * Optional:
 * - Icon to display to the left of the button label. No icon used by default.
 * - Refresh after successful mutation. Enabled by default.
 * - Disable button. Button is enabled by default.
 */
import { Button } from "react-admin";
import { MutationFunction, MutationKey } from "react-query";

import { useMutation } from "../../hooks";

interface MutationActionButtonProps<TData, TVariables> {
  label: string;
  mutationFn: MutationFunction<TData, TVariables>;
  mutationKey: MutationKey;
  mutationVariables: TVariables;
  successMessage: string | ((response: TData) => string);
  startIcon?: React.ReactNode;
  disabled?: boolean;
  skipPageRefresh?: boolean;
}

function MutationActionButton<TVariables, TData>(
  props: MutationActionButtonProps<TVariables, TData>
) {
  const { mutate, isLoading } = useMutation(
    props.mutationFn,
    props.mutationKey,
    props.successMessage,
    props.skipPageRefresh
  );

  return (
    <Button
      onClick={() => {
        mutate(props.mutationVariables);
      }}
      startIcon={props.startIcon}
      disabled={props.disabled ?? isLoading}
      label={props.label}
      variant="fieldwire-primary"
    />
  );
}

export default MutationActionButton;
