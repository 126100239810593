import { TextField } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomReferenceFieldLazy,
  CustomShow,
  ShowTable,
} from "../custom";

function SsoInfoShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.SsoInfo}
      displayDelete={true}
      displayEdit={true}
    >
      <ShowTable title="Sso Info Details">
        <TextField label="Id" source="id" />
        <TextField label="Domain" source="domain" />
        <TextField
          label="Auth0 Connection Name"
          source="auth0_connection_name"
        />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        <CustomReferenceFieldLazy
          label="Account"
          source="account_id"
          reference={Resource.Account}
        />
      </ShowTable>
    </CustomShow>
  );
}

export default SsoInfoShow;
