import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import { Button, useLogout } from "react-admin";

import { useMenuState, useModalState } from "../../../hooks";
import { storage } from "../../../storage";
import { Resource } from "../../../types";
import { routes } from "../../../utils/Routes";
import { ConfirmationModal, CustomMenu, CustomMenuItem } from "..";

const CustomUserMenu = () => {
  const logout = useLogout();

  const { anchorEl, open, handleClick, onClose } = useMenuState();

  const adminUser = storage.loadAuthInfo()?.admin_user;
  const adminUserEmail = adminUser?.email;
  const adminUserId = adminUser?.id;

  const handleLogout = () => {
    onClose();
    logout();
  };

  return (
    <>
      <Box>
        <Button
          disableRipple
          label={adminUserEmail}
          onClick={handleClick}
          startIcon={<AccountCircleIcon />}
          // To keep icon size consistent.
          size="medium"
          // The default color of button text is a shade of blue.
          // Because we aren't using any FW variants here that
          // would specify a different color, specify white.
          sx={{
            color: "fieldwire.white.1",
          }}
        />
      </Box>
      <CustomMenu anchorEl={anchorEl} onClose={onClose} open={open}>
        {/*
          Only render profile button if we can access the current admin user's id.
        */}
        {adminUserId && (
          <CustomMenuItem
            // @ts-expect-error
            component="a"
            href={routes.computeShowFor(Resource.AdminUser, adminUserId)}
            onClick={onClose}
          >
            View Profile
          </CustomMenuItem>
        )}
        <SendFeedbackModalMenuItem />
        <CustomMenuItem onClick={handleLogout}>Sign Out</CustomMenuItem>
      </CustomMenu>
    </>
  );
};

function SendFeedbackModalMenuItem() {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    <>
      <CustomMenuItem onClick={handleOpen}>Send Feedback</CustomMenuItem>
      <ConfirmationModal
        cancelHandler={handleClose}
        confirmHandler={handleClose}
        confirmButtonText="OK"
        confirmButtonVariant="fieldwire-primary"
        description={`Please submit suggestions using the product feedback channel
                      in Slack. If you've found a bug, please submit a ticket using
                      the BESTUS board in Jira.`}
        modalOpen={modalOpen}
        title="Send Feedback"
      />
    </>
  );
}

export default CustomUserMenu;
