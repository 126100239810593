import {
  BooleanField,
  NumberField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";

import { ROUNDED_PRICE_TOOLTIP_TEXT } from "../../constants";
import {
  Action,
  DataKind,
  EnterprisePlanResponse,
  Resource,
} from "../../types";
import { routes } from "../../utils/Routes";
import {
  ActionMenu,
  Authorize,
  CustomDatagrid,
  CustomDateTimeField,
  CustomMenuItem,
  CustomReferenceFieldLazy,
  CustomReferenceManyField,
  CustomShow,
  LabelWithInfoTooltip,
  RoundedPriceField,
  ShowTable,
} from "../custom";

function CreateInvoice() {
  const record = useRecordContext<EnterprisePlanResponse>();

  if (record) {
    return (
      <CustomMenuItem
        // We use the following directive to suppress the type errors arising from
        // usage of the `component`, `to`, and `state` props. Despite the type errors,
        // the component works as expected with the props.
        //
        // Also note that in most cases we use the anchor tag (<a>) for the underlying
        // component when creating link-like menu items. However, in this case we are
        // using react router dom's `Link` component to take advantage of its ability
        // to store state. In general, we should avoid using `Link` because it doesn't
        // handle external URLs well.
        // @ts-expect-error
        component={Link}
        to={{
          // We remove the leading "#" because a hash route does not play well with
          // react-router-dom's Link component.
          pathname: routes
            .computeCreateFor(Resource.EnterprisePlanInvoice)
            .substring(1),
        }}
        // Prefills the "Enterprise Plan ID", "Start At", and "End At" field
        // on the EPI create page.
        state={{
          record: {
            enterprise_plan_id: record.id,
            start_at: record.start_at,
            end_at: record.end_at,
            currency: record.currency,
          },
        }}
      >
        Create Invoice
      </CustomMenuItem>
    );
  } else {
    return null;
  }
}

function DuplicatePlan() {
  const record = useRecordContext<EnterprisePlanResponse>();

  if (record) {
    const { name, ...enterprisePlanWithoutName } = record;
    return (
      <CustomMenuItem
        // We use the following directive to suppress the type errors arising from
        // usage of the `component`, `to`, and `state` props. Despite the type errors,
        // the component works as expected with the props.
        //
        // Also note that in most cases we use the anchor tag (<a>) for the underlying
        // component when creating link-like menu items. However, in this case we are
        // using react router dom's `Link` component to take advantage of its ability
        // to store state. In general, we should avoid using `Link` because it doesn't
        // handle external URLs well.
        // @ts-expect-error
        component={Link}
        to={{
          // We remove the leading "#" because a hash route does not play well with
          // react-router-dom's Link component.
          pathname: routes
            .computeCreateFor(Resource.EnterprisePlan)
            .substring(1),
        }}
        state={{
          // Prefills the EP create page with the same values as the current EP except
          // for name. The reason we do not bring name over is due to a bug with React
          // Admin that incorrectly disables the save button when all fields are brought
          // over (and forces the user to change at least one field before saving):
          //
          // https://github.com/marmelab/react-admin/issues/9217
          //
          // Even though in our case the admin user will need to change certain fields
          // (ex: start and end dates), they might not know this without feedback that
          // they receive from the BE. Therefore, we force the admin user to enter a
          // name to avoid potentially putting them in a confusing state.
          //
          // TODO [Nice-to-Have] [BESTUS-770]: Bring over all fields when bug is fixed.
          record: enterprisePlanWithoutName,
        }}
      >
        Duplicate Plan
      </CustomMenuItem>
    );
  } else {
    return null;
  }
}

const fieldwireActions = [
  {
    checkPermissionForAction: Action.Create,
    checkPermissionForDataKind: DataKind.EnterprisePlanInvoice,
    element: <CreateInvoice />,
  },
  {
    checkPermissionForAction: Action.Create,
    checkPermissionForDataKind: DataKind.EnterprisePlan,
    element: <DuplicatePlan />,
  },
];

function EnterprisePlanShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.EnterprisePlan}
      displayDelete={false}
      displayEdit={true}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF ENTERPRISE PLAN DETAILS */}
      <ShowTable title="Enterprise Plan Details">
        <CustomReferenceFieldLazy
          label="Account"
          source="account_id"
          reference={Resource.Account}
        />
        <TextField label="Name" source="name" />
        <TextField
          label="Currency"
          source="currency"
          sx={{ textTransform: "uppercase" }}
        />
        <TextField label="Price" source="price_native_currency" />
        <TextField
          label="Overage Price"
          source="overage_price_native_currency"
        />
        <NumberField label="Max User Count" source="max_user_count" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        <TextField label="Company Name" source="company_name" />
        <TextField label="Company Domain" source="company_domain" />
        <TextField
          label="Hilti Subscription ID"
          source="hilti_subscription_id"
        />
        <CustomDateTimeField label="Start At" source="start_at" />
        <CustomDateTimeField label="End At" source="end_at" />
        <CustomDateTimeField
          label="Notification Sent At"
          source="notification_sent_at"
        />
        <BooleanField label="Is Temporary" source="is_temporary" />
        <TextField label="Category" source="category" />
        <BooleanField
          label="Is Notify Admin Enabled"
          source="is_notify_admin_enabled"
        />
        <BooleanField
          label="Is Notify New User Enabled"
          source="is_notify_new_user_enabled"
        />
        <TextField label="Kind" source="kind" />
        <CustomDateTimeField
          label="Over Limit Notification Sent At"
          source="over_limit_notification_sent_at"
        />
        <BooleanField
          label="Is Notify Over Limit Enabled"
          source="is_notify_over_limit_enabled"
        />
        <BooleanField label="Is Auto Add Users" source="is_auto_add_users" />
        <BooleanField label="Is Poc" source="is_poc" />
        <BooleanField
          label="Is Project Create By Account User Enabled"
          source="is_project_create_by_account_user_enabled"
        />
      </ShowTable>
      {/* END OF ENTERPRISE PLAN DETAILS */}

      {/* START OF ENTERPRISE PLAN INVOICES */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.EnterprisePlanInvoice}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Enterprise Plan Invoices">
          <CustomReferenceManyField
            label={false}
            displayPagination={true}
            reference={Resource.EnterprisePlanInvoice}
            target={Resource.EnterprisePlan}
          >
            <CustomDatagrid>
              <TextField label="Id" source="id" />
              <TextField label="Description" source="description" />
              <TextField
                label="Currency"
                source="currency"
                sx={{ textTransform: "uppercase" }}
                sortable={false}
              />
              <RoundedPriceField
                label={
                  <LabelWithInfoTooltip
                    labelText="Amount"
                    tooltipText={ROUNDED_PRICE_TOOLTIP_TEXT}
                  />
                }
                source="amount_native_currency"
                sortable={false}
              />
              <CustomDateTimeField label="Start At" source="start_at" />
              <CustomDateTimeField label="End At" source="end_at" />
              <CustomDateTimeField label="Paid At" source="paid_at" />
              <TextField label="Kind" source="kind" />
              <CustomDateTimeField label="Created At" source="created_at" />
              <CustomDateTimeField label="Updated At" source="updated_at" />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF ENTERPRISE PLAN INVOICES */}
    </CustomShow>
  );
}

export default EnterprisePlanShow;
