import {
  AccountResponse,
  ChargeResponse,
  ChargeState,
  EnterprisePlanInvoiceResponse,
  PlanInfo,
} from "../../types";

const DAYS_PER_MONTH = 365 / 12;
const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

interface SelectChoice {
  id: string;
  name: string;
}

const componentUtils = {
  calculateMrrForEnterprisePlanInvoice(
    enterprisePlanInvoice: EnterprisePlanInvoiceResponse
  ): number {
    if (!(enterprisePlanInvoice.start_at && enterprisePlanInvoice.end_at)) {
      return 0;
    }

    // prettier-ignore
    const endAtStartOfDay = new Date(enterprisePlanInvoice.end_at).setUTCHours(0, 0, 0, 0);
    // prettier-ignore
    const startAtStartOfDay = new Date(enterprisePlanInvoice.start_at).setUTCHours(0, 0, 0, 0);

    // prettier-ignore
    const differenceInCalendarDays = (endAtStartOfDay - startAtStartOfDay) / MILLISECONDS_IN_DAY;

    // prettier-ignore
    return (DAYS_PER_MONTH * enterprisePlanInvoice.amount) / differenceInCalendarDays;
  },

  /**
   * Transform an `enum` into an array of `{id: string, name: string }`
   * objects for consumption by `SelectInput`s, where name is capital case.
   *
   * ```
   *  Input: enum { AccountMember = "account_member", AccountAdmin = "account_admin" }
   *  Output: [ { id: "account_member", name: "Account Member" }, { id: "account_admin", name: "Account Admin" } ]
   * ```
   *
   * Please note if non `snake_case` strings, `fooBar` for example,
   * are used in the `enum`, the function will behave unexpectedly!
   */
  generateCapitalCaseSelectChoicesFromEnum: (e: any): SelectChoice[] => {
    return generateSelectChoicesFromEnum(e, snakeCaseToCapitalCase);
  },

  /**
   * Transform an `enum` into an array of `{id: string, name: string }`
   * objects for consumption by `SelectInput`s, where name is upper case.
   *
   * ```
   *  Input: enum { Eu = "eu", Us = "us" }
   *  Output: [ { id: "eu", name: "EU" }, { id: "us", name: "US" } ]
   * ```
   *
   * Please note if non `snake_case` strings, `fooBar` for example,
   * are used in the `enum`, the function will behave unexpectedly!
   */
  generateUpperCaseSelectChoicesFromEnum: (e: any): SelectChoice[] => {
    return generateSelectChoicesFromEnum(e, snakeCaseToUpperCase);
  },

  /**
   * Returns true if account is on a basic (free) plan. At this
   * time, the only basic (free) plan is the team v1 stripe plan.
   */
  isAccountBasic: (account: AccountResponse) => {
    return account.plan_info === PlanInfo.StripeTeamV1;
  },

  /**
   * Returns true if charge can be retried.
   */
  isChargeRetriable: (charge: ChargeResponse) => {
    return charge.state === ChargeState.Failed;
  },

  /**
   * Returns true if plan info corresponds to an enterpise plan.
   */
  isPlanInfoEnterprisePlan: (planInfo: PlanInfo) => {
    return (
      planInfo === PlanInfo.EnterpriseBusiness ||
      planInfo === PlanInfo.EnterpriseBusinessPlus ||
      planInfo === PlanInfo.EnterprisePremier ||
      planInfo === PlanInfo.EnterprisePro
    );
  },

  snakeCaseToCapitalCase: (str: string): string => {
    return snakeCaseToCapitalCase(str);
  },
};

const generateSelectChoicesFromEnum = (
  e: any,
  strTransformFn: (str: string) => string
): SelectChoice[] => {
  return Object.values(e).map((snakeCaseStr: any) => {
    return {
      id: snakeCaseStr,
      name: strTransformFn(snakeCaseStr),
    };
  });
};

const snakeCaseToCapitalCase = (str: string) => {
  return str
    .split("_")
    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

const snakeCaseToUpperCase = (str: string) => {
  return str
    .split("_")
    .map((s: string) => s.toUpperCase())
    .join(" ");
};

export default componentUtils;
