import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomCreate, CustomNumberInput } from "../custom";

function CompanyCreate() {
  return (
    <CustomCreate checkPermissionFor={DataKind.Company}>
      <TextInput label="Name" source="name" required />
      <TextInput label="Email Domain" source="email_domain" required />
      <CustomNumberInput
        label="FW Rep Admin User ID"
        source="fw_rep_admin_user_id"
      />
      <TextInput label="Salesforce ID" source="salesforce_id" />
    </CustomCreate>
  );
}

export default CompanyCreate;
