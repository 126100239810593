import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider } from "react-admin";

import { useForm, useMutation } from "../../../hooks";
import { storage } from "../../../storage";
import {
  AccountResponse,
  CustomDataProvider,
  ModalProps,
  Region,
} from "../../../types";
import { ActionButtonsBar, Form, Modal } from "..";

interface ExportAccountModalProps extends ModalProps {
  account: AccountResponse;
}

interface FormState {
  accountId: Identifier;
  exportAccountUsersAndHistoricalData: boolean;
  exportProjectDataFromUs: boolean;
  exportProjectDataFromEu: boolean;
}

function buildDefaultFormState(accountId: Identifier): FormState {
  return {
    accountId,
    exportAccountUsersAndHistoricalData: false,
    exportProjectDataFromUs: false,
    exportProjectDataFromEu: false,
  };
}

function exportAccount(
  form: FormState,
  dataProvider: CustomDataProvider
): Promise<void[]> {
  const adminUserEmail = storage.loadAuthInfo()?.admin_user.email;
  const promiseFns = [];

  // Trigger super export
  if (form.exportAccountUsersAndHistoricalData) {
    promiseFns.push(
      dataProvider.exportAccountUsersAndHistoricalData(form.accountId)
    );
  }

  // Trigger regional exports
  if (adminUserEmail) {
    if (form.exportProjectDataFromUs) {
      promiseFns.push(
        dataProvider.exportAccountProjectData(
          Region.Us,
          form.accountId,
          adminUserEmail
        )
      );
    }

    if (form.exportProjectDataFromEu) {
      promiseFns.push(
        dataProvider.exportAccountProjectData(
          Region.Eu,
          form.accountId,
          adminUserEmail
        )
      );
    }
  }

  return Promise.all(promiseFns);
}

function ExportAccountModal(props: ExportAccountModalProps) {
  // Since the only mutable elements in this form are `Checkbox`es, we can
  // go for checking `target.checked` without worrying about the input type
  const { form, handleChangeForm, handleClearForm } = useForm(
    buildDefaultFormState(props.account.id),
    (e) => e.target.checked
  );

  const dataProvider = useDataProvider<CustomDataProvider>();
  const { mutate } = useMutation(
    (form: FormState) => exportAccount(form, dataProvider),
    ["createAccountExport", form],
    "Account export in progress! ✅",
    true
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();
    props.handleClose();
    mutate(form);
  };

  return (
    <Modal open={props.modalOpen} title="New Account Export">
      <Form onSubmit={handleSubmit}>
        <Typography component="p" sx={{ color: "fieldwire.gray.1" }}>
          Each of the items chosen below will be exported in its own CSV and
          emailed separately to you.
        </Typography>
        <TextField
          label="Account Id"
          name="accountId"
          value={form.accountId}
          required
          disabled
        />
        <FormControlLabel
          label="Export users and historical usage data"
          control={
            <Checkbox
              name="exportAccountUsersAndHistoricalData"
              onChange={handleChangeForm}
              value={form.exportAccountUsersAndHistoricalData}
            />
          }
        />
        <FormControlLabel
          label="Export project data from US"
          control={
            <Checkbox
              name="exportProjectDataFromUs"
              onChange={handleChangeForm}
              value={form.exportProjectDataFromUs}
            />
          }
        />
        <FormControlLabel
          label="Export project data from EU"
          control={
            <Checkbox
              name="exportProjectDataFromEu"
              onChange={handleChangeForm}
              value={form.exportProjectDataFromEu}
            />
          }
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            label="Create Account Export"
            type="submit"
            variant="fieldwire-primary"
            disabled={
              !form.exportAccountUsersAndHistoricalData &&
              !form.exportProjectDataFromUs &&
              !form.exportProjectDataFromEu
            }
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default ExportAccountModal;
