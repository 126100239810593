import axios from "axios";

import { storage } from "../../storage";
import {
  AccessToken,
  AdminRefreshAccessTokenResponse,
  HttpClient,
  HttpClientError,
  HttpClientResponse,
} from "../../types";
import { urlBuilder } from "../api_utils";
import { accessTokenUpdater } from ".";

interface RefreshCache {
  refreshCall?: Promise<AccessToken>;
}

// TODO [High] [REG-879]: Add tests for access token refresh logic.

const accessTokenRefresher = {
  setRefreshMechanism(httpClient: HttpClient) {
    const refreshCache: RefreshCache = {};

    httpClient.interceptors.response.use(
      // If the status code of the response falls
      // within the 2xx range, we let it through.
      (res) => res,
      (err: HttpClientError) => {
        if (err.response?.status === 401) {
          return getRefreshCall(httpClient, refreshCache)
            .catch((_: HttpClientError) => {
              // If we are unable to refresh the access token, we
              // allow the unauthorized error to go through. This
              // is then handled in the `authProvider` which will
              // prompt the user to sign in again.
              return Promise.reject(err);
            })
            .then((access_token: AccessToken) => {
              // `err.config` contains everything we need to
              // retry the request. However, it contains the
              // old access token so we will have to update
              // it with our new access token.
              const config = {
                ...err.config,
                headers: {
                  ...err.config.headers,
                  Authorization: `Bearer ${access_token}`,
                },
              };

              // Retry the request with our new access token. Note that we retry
              // the request with `axios` rather than our `httpClient` (which is
              // just an instance of axios). This is because if we retried with the
              // `httpClient` and received a 401 again, we could enter an infinite
              // retry loop as the `httpClient` continues to intercept the response,
              // refresh the access token, and retry again. Note that `config` has
              // everything we need to retry the request.
              return axios.request(config);
            });
        }
        // The error we have encountered is not one that is
        // due to authorization, therefore we let it through.
        return Promise.reject(err);
      }
    );
  },
};

const clearRefreshCache = (refreshCache: RefreshCache): void => {
  if (refreshCache.refreshCall) {
    refreshCache.refreshCall = undefined;
  }
};

const getRefreshCall = (
  httpClient: HttpClient,
  refreshCache: RefreshCache
): Promise<AccessToken> => {
  if (!refreshCache.refreshCall) {
    refreshCache.refreshCall = refresh(httpClient, refreshCache);
  }
  return refreshCache.refreshCall;
};

const refresh = (
  httpClient: HttpClient,
  refreshCache: RefreshCache
): Promise<AccessToken> => {
  const authInfo = storage.loadAuthInfo();
  if (authInfo) {
    const url = urlBuilder.super("refresh_access_token");
    return httpClient
      .post(url, {
        admin_user_id: authInfo.admin_user.id,
        refresh_token: authInfo.refresh_token,
      })
      .then((res: HttpClientResponse<AdminRefreshAccessTokenResponse>) => {
        const updatedAccessToken = res.data.access_token;

        // Update local storage and HTTP Client with new access token.
        accessTokenUpdater.updateAccessToken(updatedAccessToken, httpClient);

        // Update local storage with the roles returned by the refresh access
        // token response. In most cases, these roles will not change between
        // refreshes. However, in the event that they do, the user won't have
        // to log out and log back in again in order for local storage to be
        // updated.
        storage.updateRoles(res.data.roles);

        return Promise.resolve(updatedAccessToken);
      })
      .catch(() => {
        return Promise.reject();
      })
      .finally(() => {
        clearRefreshCache(refreshCache);
      });
  } else {
    // If auth info doesn't exist in local storage or
    // we can't parse it correctly, we fail the refresh.
    return Promise.reject();
  }
};

export default accessTokenRefresher;
