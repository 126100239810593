import { NumberField, TextField, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

import { Action, AdminUserResponse, DataKind, Resource } from "../../types";
import { routes } from "../../utils/Routes";
import {
  ActionMenu,
  Authorize,
  CustomDatagrid,
  CustomDateTimeField,
  CustomMenuItem,
  CustomReferenceManyField,
  CustomShow,
  ShowTable,
} from "../custom";

function CreateRole() {
  const record = useRecordContext<AdminUserResponse>();

  if (record) {
    return (
      <CustomMenuItem
        // We use the following directive to suppress the type errors arising from
        // usage of the `component`, `to`, and `state` props. Despite the type errors,
        // the component works as expected with the props.
        //
        // Also note that in most cases we use the anchor tag (<a>) for the underlying
        // component when creating link-like menu items. However, in this case we are
        // using react router dom's `Link` component to take advantage of its ability
        // to store state. In general, we should avoid using `Link` because it doesn't
        // handle external URLs well.
        // @ts-expect-error
        component={Link}
        to={{
          // We remove the leading "#" because a hash route does not play well with
          // react-router-dom's Link component.
          pathname: routes
            .computeCreateFor(Resource.AdminRoleMembership)
            .substring(1),
        }}
        // Prefills the "Admin User ID" field on the admin role membership create page.
        state={{
          record: {
            admin_user_id: record.id,
          },
        }}
      >
        Create Role
      </CustomMenuItem>
    );
  } else {
    return null;
  }
}

const fieldwireActions = [
  {
    checkPermissionForAction: Action.Create,
    checkPermissionForDataKind: DataKind.AdminRoleMembership,
    element: <CreateRole />,
  },
];

function AdminUserShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.AdminUser}
      displayDelete={true}
      displayEdit={true}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF ADMIN USER DETAILS */}
      <ShowTable title="Admin User Details">
        <TextField label="Id" source="id" />
        <TextField label="Email" source="email" />
        <NumberField label="Failed attempts" source="failed_attempts" />
        <NumberField label="Sign In Count" source="sign_in_count" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Locked At" source="locked_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
      {/* END OF ADMIN USER DETAILS */}

      {/* START OF ADMIN USER ROLES */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.AdminRoleMembership}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Admin Role Memberships">
          <CustomReferenceManyField
            defaultSort={{ field: "created_at", order: "DESC" }}
            displayPagination={true}
            label={false}
            reference={Resource.AdminRoleMembership}
            target={Resource.AdminUser}
          >
            <CustomDatagrid>
              <TextField label="Id" source="id" />
              <TextField label="Role" source="role" />
              <CustomDateTimeField label="Created At" source="created_at" />
              <CustomDateTimeField label="Updated At" source="updated_at" />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF ADMIN USER ROLES */}
    </CustomShow>
  );
}

export default AdminUserShow;
