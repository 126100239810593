import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function CrUsersSignupsChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      30
    );

    return (
      <ChartContainer title="CR Users/Signups (Daily)">
        <LineChart
          labels={processedData.map(
            (d: ActiveUserCountsForMarketingResponse) => d.date
          )}
          datasets={[
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  analyticsUtils.multiplyOrNull(d.projects_conversion_rate, 100)
              ),
              label: "Projects",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  analyticsUtils.multiplyOrNull(
                    d.floorplans_conversion_rate,
                    100
                  )
              ),
              label: "Plans",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  analyticsUtils.multiplyOrNull(d.tasks_conversion_rate, 100)
              ),
              label: "Tasks",
            },
            {
              data: processedData.map(
                (d: ActiveUserCountsForMarketingResponse) =>
                  analyticsUtils.multiplyOrNull(
                    d.project_users_conversion_rate,
                    100
                  )
              ),
              label: "Users",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default CrUsersSignupsChart;
