import {
  Button,
  useDelete,
  useRecordContext,
  useRedirect,
  useResourceContext,
} from "react-admin";

import { useModalState } from "../../../../hooks";
import { ConfirmationModal } from "../..";

const DELETE_DESCRIPTION =
  "Are you sure you want to delete this record? This will permanently remove it from Fieldwire.";

function CustomDeleteButton() {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  const record = useRecordContext();
  const resource = useResourceContext();
  const [deleteOne] = useDelete();
  const redirect = useRedirect();

  const handleDelete = () => {
    deleteOne(
      resource,
      { id: record.id },
      {
        onSuccess: () => {
          handleClose();
          // Route back to list page for resource. We assume that the list
          // page path matches the resource name. If this is not the case,
          // the redirect will behave unexpectedly.
          redirect(`/${resource}`);
        },
      }
    );
  };

  if (record && resource) {
    return (
      <>
        <Button
          label="Delete"
          onClick={handleOpen}
          variant="fieldwire-danger"
        />
        <ConfirmationModal
          cancelHandler={handleClose}
          confirmHandler={handleDelete}
          confirmButtonText="Delete"
          confirmButtonVariant="fieldwire-danger"
          description={DELETE_DESCRIPTION}
          modalOpen={modalOpen}
          title="Delete"
        />
      </>
    );
  } else {
    return null;
  }
}

export default CustomDeleteButton;
