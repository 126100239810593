import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { mixpanel } from "../utils";

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    mixpanel.trackPageview(location.pathname);
  }, [location.pathname]);
}

export default usePageTracking;
