/**
 * A wrapper around React Admin's `Toolbar` component which is used
 * within React Admin's `SimpleForm` component on the create and edit
 * pages. The main difference between the custom and original toolbar
 * is that the custom one positions its buttons on the right (instead
 * of the left) and has a transparent background (instead of gray).
 */

import { Toolbar } from "react-admin";

interface CustomToolbarProps {
  children: any;
}

function CustomToolbar(props: CustomToolbarProps) {
  return (
    <Toolbar
      sx={{
        bgcolor: "rgba(0, 0, 0, 0)", // transparent
        justifyContent: "flex-end",
        // TODO [Nice-to-Have] [REG-1150]: Investigate if we can remove this directive.
        // Note: Without the `!important` directive this style would be
        // overriden by defaults coming from React Admin/Material UI.
        padding: "0 !important",
      }}
    >
      {props.children}
    </Toolbar>
  );
}

export default CustomToolbar;
