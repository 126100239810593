import {
  FormDataConsumer,
  SelectInput,
  TextInput,
  ValidateForm,
} from "react-admin";

import { DataKind, EnterprisePlanInvoiceKind } from "../../types";
import validators from "../../utils/Validators";
import { componentUtils } from "../component_utils";
import {
  CurrencyConversionInput,
  CurrencySelectInput,
  CustomCreate,
  CustomNumberInput,
  CustomUtcDateTimeInput,
} from "../custom";

const kindChoices = componentUtils.generateCapitalCaseSelectChoicesFromEnum(
  EnterprisePlanInvoiceKind
);

function EnterprisePlanInvoiceCreate() {
  const validateForm: ValidateForm = (values: any) => {
    return validators.validateDateRange(values.start_at, values.end_at);
  };

  return (
    <CustomCreate
      checkPermissionFor={DataKind.EnterprisePlanInvoice}
      validate={validateForm}
    >
      <CustomNumberInput
        label="Enterprise Plan ID"
        source="enterprise_plan_id"
        disabled={true}
        required
      />
      <TextInput label="Description" source="description" required />
      <TextInput
        label="Purchase Order Number"
        source="purchase_order_number"
        inputProps={{ maxLength: 256 }}
      />
      <CurrencySelectInput label="Currency" source="currency" disabled={true} />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <CurrencyConversionInput
            label="Amount"
            source="amount_native_currency"
            currency={formData.currency}
            value={formData.amount_native_currency}
            required
          />
        )}
      </FormDataConsumer>
      <CustomUtcDateTimeInput
        label="Start At (UTC)"
        source="start_at"
        required
      />
      <CustomUtcDateTimeInput
        parseSecondsAs={59}
        label="End At (UTC)"
        source="end_at"
        required
      />
      <CustomUtcDateTimeInput label="Paid At (UTC)" source="paid_at" />
      <SelectInput label="Kind" source="kind" choices={kindChoices} required />
    </CustomCreate>
  );
}

export default EnterprisePlanInvoiceCreate;
