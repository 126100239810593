import { Alert, MenuItem, TextField } from "@mui/material";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider, useNotify } from "react-admin";
import { useQuery } from "react-query";

import { DEFAULT_STRIPE_PLAN } from "../../../constants";
import {
  useForm,
  useGetStripeCreditCardToken,
  useMutation,
  useUpdateAccountInvoiceAddressInformation,
} from "../../../hooks";
import { requiresState } from "../../../iso";
import {
  getCheckoutableStripePlanInfosWithId,
  getStripePromise,
  isLegacy,
  isYearly,
  kind,
} from "../../../stripe";
import {
  AccountResponse,
  CheckoutableStripePlanId,
  CheckoutableStripePlanInfoWithId,
  CountryCodeRequiringState,
  CustomDataProvider,
  ModalProps,
  UpdateStripeSubscriptionRequest,
  UserCheckoutInfoResponse,
  UserResponse,
} from "../../../types";
import {
  ActionButtonsBar,
  CountrySelectField,
  Form,
  Modal,
  StateSelectField,
  StripeCreditCardTextField,
} from "..";

interface CheckoutModalProps extends ModalProps {
  account: AccountResponse;
  user: UserResponse;
}

interface CheckoutModalContentBaseProps extends CheckoutModalProps {}

interface CheckoutModalContentProps extends ModalProps {
  account: AccountResponse;
  userCheckoutInfo: UserCheckoutInfoResponse;
}

// Typically we order properties in alphabetical order. However, the
// form state here is more complex so we've ordered properties using
// the order in which their associated inputs appear in the checkout
// modal.
interface FormState {
  account_id: Identifier;
  owner_email: string | null;
  current_user_count: number;
  project_count: number;
  stripe_plan_id: CheckoutableStripePlanId;
  checkout_quantity: string;
  discount_amount: string;
  invoice_country_iso2: string;
  company_address: string;
  invoice_city: string;
  invoice_state_iso2: string;
  invoice_postal_code: string;
}

interface UpdateSubscriptionMutationVariables {
  request: UpdateStripeSubscriptionRequest;
  userId: Identifier;
}

// The purpose of this layer is to 1) ensure that Stripe and the user checkout
// info is lazily loaded and 2) wrap the downstream components in the Elements
// provider so that we can use Stripe and its hooks.
function CheckoutModal(props: CheckoutModalProps) {
  if (props.modalOpen) {
    return (
      <Elements stripe={getStripePromise()}>
        <CheckoutModalContentBase {...props} />
      </Elements>
    );
  } else {
    return null;
  }
}

// The purpose of this layer is to fetch the user checkout info and pass it down
// to the CheckoutModalContent component. This reduces some of the complexity of
// the component, which is complex enough as it is.
function CheckoutModalContentBase(props: CheckoutModalContentBaseProps) {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data: userCheckoutInfoRecord } = useQuery<{
    data: UserCheckoutInfoResponse;
  }>(["getUserCheckoutInfo", { id: props.user.id }], () =>
    dataProvider.getUserCheckoutInfo(props.user.id)
  );

  if (userCheckoutInfoRecord) {
    return (
      <CheckoutModalContent
        account={props.account}
        handleClose={props.handleClose}
        modalOpen={props.modalOpen}
        userCheckoutInfo={userCheckoutInfoRecord.data}
      />
    );
  } else {
    return null;
  }
}

function CheckoutModalContent(props: CheckoutModalContentProps) {
  const defaultFormState: FormState = {
    account_id: props.userCheckoutInfo.account_id,
    // Owner email could be null, which React doesn't like as a form field value.
    // We don't actually do anything with this value other than display it to the
    // current admin user so it is OK to use the empty string.
    owner_email: props.userCheckoutInfo.owner_email ?? "",
    current_user_count: props.userCheckoutInfo.current_user_count,
    project_count: props.userCheckoutInfo.project_count,
    stripe_plan_id: DEFAULT_STRIPE_PLAN,
    // While the following two fields are technically optional, we need to set
    // an initial value for them so React doesn't warn us about switching between
    // uncontrolled and controlled inputs. Why are they optional values? Because
    // monthly users don't have seats, they just get billed when the users are
    // added. On the other hand, annual plans have a set of seats that the
    // account can use.
    checkout_quantity: "",
    discount_amount: "",
    invoice_country_iso2: props.userCheckoutInfo.invoice_country_iso2 ?? "",
    company_address: props.userCheckoutInfo.company_address ?? "",
    invoice_city: props.userCheckoutInfo.invoice_city ?? "",
    // While the following field is technically optional, we need to set
    // an initial value for it so React doesn't warn us about switching
    // between uncontrolled and controlled inputs.
    invoice_state_iso2: props.userCheckoutInfo.invoice_state_iso2 ?? "",
    invoice_postal_code: props.userCheckoutInfo.invoice_postal_code ?? "",
  };

  const { form, handleClearForm, handleChangeForm } = useForm(defaultFormState);

  // Ensure that Stripe is loaded and that the required fields are filled
  // out before the current admin user can perform the checkout.
  //
  // Note that we don't check if the credit card info has been filled out.
  // This is because it is a bit trickier to validate. If the current admin
  // user does leave this field blank or incomplete we catch the error and
  // ask them to verify that they've entered the credit card information
  // correctly.
  const canCheckout = () => {
    return (
      stripe &&
      form.stripe_plan_id &&
      form.invoice_country_iso2 &&
      form.company_address &&
      form.invoice_city &&
      form.invoice_postal_code &&
      // Ensure that either state isn't required, or if it is, that it is present.
      (!requiresState(form.invoice_country_iso2) || form.invoice_state_iso2)
    );
  };

  const dataProvider = useDataProvider<CustomDataProvider>();
  const { mutate } = useMutation(
    (variables: UpdateSubscriptionMutationVariables) =>
      dataProvider.updateStripeSubscription(
        variables.userId,
        variables.request
      ),
    // Technically the request info is derived from the form state.
    // However, the uniqueness of the form state should suffice for
    // the purposes of a mutation key.
    ["updateStripeSubscription", form],
    "Checkout successful! ✅"
  );

  const notify = useNotify();
  const stripe = useStripe();
  const stripeElements = useElements();
  const getStripeCreditCardToken = useGetStripeCreditCardToken(
    stripe,
    stripeElements
  );
  const updateStripeSubscription = () => {
    getStripeCreditCardToken()
      .then((creditCardToken: string) => {
        mutate({
          request: {
            credit_card_token: creditCardToken,
            plan_name: kind(form.stripe_plan_id),
            pay_annually: isYearly(form.stripe_plan_id),
            plan_id: form.stripe_plan_id,
            // Only include checkout quantity as part of the request
            // if the current admin user has filled out the field.
            ...(form.checkout_quantity && {
              checkout_quantity: parseInt(form.checkout_quantity),
            }),
            // Only include discount amount as part of the request
            // if the current admin user has filled out the field.
            ...(form.discount_amount && {
              discount_amount: parseInt(form.discount_amount),
            }),
          },
          userId: props.userCheckoutInfo.id,
        });
      })
      .catch(() => {
        // TODO [Nice-to-Have] [REG-1480]: Extract static text into a single place.
        notify(
          "Something went wrong while preparing to update the stripe subscription. \
          Please ensure that you've entered the credit card information correctly or \
          contact engineering."
        );
      })
      .finally(() => {
        // We  call `handleClose` here because `react-stripe-js` requires that
        // the `CardElement` remain mounted in order to get the info contained
        // within it.
        props.handleClose();
      });
  };

  const updateAccount = useUpdateAccountInvoiceAddressInformation(
    props.account,
    {
      company_address: form.company_address,
      invoice_country_iso2: form.invoice_country_iso2,
      invoice_city: form.invoice_city,
      invoice_state_iso2: form.invoice_state_iso2,
      invoice_postal_code: form.invoice_postal_code,
    },
    updateStripeSubscription
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();

    // We normally call `handleClose` here. However, for this modal we can't do
    // that until we've fetched the credit card token from Stripe. This is b/c
    // `react-stripe-js` requires that the `CardElement` remain mounted in order
    // to get the information contained within it. Therefore, we call `handleClose`
    // above after the promise for fetching the credit card token from Stripe has
    // settled.

    // Update account and then update stripe sub upon successful account update.
    updateAccount();
  };

  return (
    <Modal open={props.modalOpen} title="Checkout">
      <Form onSubmit={handleSubmit}>
        <Alert severity="warning">
          Only use legacy plans for existing grandfathered users that were
          auto-canceled due to payment failure. Do not use for new accounts.
        </Alert>
        <TextField disabled label="Account" value={form.account_id} />
        <TextField disabled label="Account Owner" value={form.owner_email} />
        <TextField
          disabled
          label="User Count"
          value={form.current_user_count}
        />
        <TextField disabled label="Project Count" value={form.project_count} />
        <TextField
          label="Plan"
          name="stripe_plan_id"
          onChange={handleChangeForm}
          value={form.stripe_plan_id}
          required
          select
        >
          {getCheckoutableStripePlanInfosWithId().map(
            (stripePlanInfoWithId: CheckoutableStripePlanInfoWithId) => {
              return (
                <MenuItem
                  key={stripePlanInfoWithId.id}
                  value={stripePlanInfoWithId.id}
                >
                  {stripePlanInfoWithId.displayName}
                </MenuItem>
              );
            }
          )}
        </TextField>
        {/*
          This field is optional and will only be shown when
          the currently selected stripe plan is an annual one.
        */}
        {isYearly(form.stripe_plan_id) && (
          <TextField
            label="Quantity"
            name="checkout_quantity"
            onChange={handleChangeForm}
            inputProps={{ maxLength: 20 }}
            value={form.checkout_quantity}
          />
        )}
        <StripeCreditCardTextField />
        {/*
          This field is optional and will only be shown when the currently
          selected stripe plan is an annual one and non-legacy.
        */}
        {isYearly(form.stripe_plan_id) && !isLegacy(form.stripe_plan_id) && (
          <TextField
            label="One-Time Discount Amount"
            helperText="Only for annual, non-legacy subscriptions!"
            name="discount_amount"
            onChange={handleChangeForm}
            inputProps={{ maxLength: 8 }}
            value={form.discount_amount}
          />
        )}
        <CountrySelectField
          label="Country"
          name="invoice_country_iso2"
          onChange={handleChangeForm}
          value={form.invoice_country_iso2}
          required
        />
        <TextField
          label="Company Address"
          name="company_address"
          onChange={handleChangeForm}
          value={form.company_address}
          required
        />
        <TextField
          label="City"
          name="invoice_city"
          onChange={handleChangeForm}
          value={form.invoice_city}
          required
        />
        {requiresState(form.invoice_country_iso2) && (
          <StateSelectField
            countryCode={form.invoice_country_iso2 as CountryCodeRequiringState}
            label="State"
            name="invoice_state_iso2"
            onChange={handleChangeForm}
            value={form.invoice_state_iso2}
            required
          />
        )}
        <TextField
          label="Postal Code"
          name="invoice_postal_code"
          onChange={handleChangeForm}
          value={form.invoice_postal_code}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!canCheckout()}
            label="Checkout"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

export default CheckoutModal;
