import { NumberInputProps } from "react-admin";

import { Currency } from "../../../types";
import { CustomNumberInput } from "..";

const currencyToUSDRates: {
  [key in Currency]: number;
} = {
  [Currency.AED]: 0.272257,
  [Currency.ALL]: 0.010445,
  [Currency.ARS]: 0.001213,
  [Currency.AUD]: 0.639535,
  [Currency.BAM]: 0.564808,
  [Currency.BGN]: 0.564808,
  [Currency.BHD]: 2.65252,
  [Currency.BRL]: 0.196628,
  [Currency.BYN]: 0.330979,
  [Currency.CAD]: 0.744186,
  [Currency.CHF]: 1.162791,
  [Currency.CLP]: 0.001119,
  [Currency.CNY]: 0.144186,
  [Currency.COP]: 0.000215,
  [Currency.CZK]: 0.045349,
  [Currency.DKK]: 0.154574,
  [Currency.DZD]: 0.007576,
  [Currency.EGP]: 0.023958,
  [Currency.EUR]: 1.104651,
  [Currency.GBP]: 1.255814,
  [Currency.HKD]: 0.128205,
  [Currency.HUF]: 0.00264,
  [Currency.IDR]: 0.000063367,
  [Currency.ILS]: 0.265099,
  [Currency.INR]: 0.011884,
  [Currency.JPY]: 0.007147,
  [Currency.KRW]: 0.000782314,
  [Currency.KZT]: 0.002036,
  [Currency.MAD]: 0.099903,
  [Currency.MXN]: 0.053488,
  [Currency.MYR]: 0.222558,
  [Currency.NOK]: 0.094413,
  [Currency.NZD]: 0.584823,
  [Currency.PAB]: 1.0,
  [Currency.PEN]: 0.269695,
  [Currency.PHP]: 0.017837,
  [Currency.PLN]: 0.239884,
  [Currency.QAR]: 0.274726,
  [Currency.RON]: 0.217859,
  [Currency.RSD]: 0.009219,
  [Currency.RUB]: 0.008919,
  [Currency.SAR]: 0.26634,
  [Currency.SEK]: 0.095581,
  [Currency.SGD]: 0.755814,
  [Currency.THB]: 0.029651,
  [Currency.TRY]: 0.026744,
  [Currency.TWD]: 0.031884,
  [Currency.UAH]: 0.018315,
  [Currency.USD]: 1.0,
  [Currency.VND]: 0.00004186,
  [Currency.ZAR]: 0.05,
};

interface CurrencyConversionInputProps extends NumberInputProps {
  currency?: Currency;
  value: number | undefined;
}

function CurrencyConversionInput(props: CurrencyConversionInputProps) {
  // Adding currency abbreviation to label to help show users
  // which has been selected e.g. Price (USD)
  const labelValue = props.currency
    ? `${props.label} (${props.currency.toUpperCase()})`
    : props.label;

  // If the USD Amount message is not supposed to display
  // we are using the no-break space unicode character instead (\u00A0)
  // to preserve spacing between the inputs in the form
  const helperTextValue =
    props.currency && props.currency !== Currency.USD && props.value
      ? `USD Amount: $${(
          props.value * currencyToUSDRates[props.currency]
        ).toFixed(2)}`
      : "\u00A0";

  return (
    <CustomNumberInput
      label={labelValue}
      required={props.required}
      source={props.source}
      helperText={helperTextValue}
    />
  );
}

export default CurrencyConversionInput;
