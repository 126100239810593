import { TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceFieldLazy,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const ssoInfoFilters = [
  <TextInput label="Domain" source="domain_like" alwaysOn />,
  <TextInput
    label="Auth0 Connection Name"
    source="auth0_connection_name_like"
    alwaysOn
  />,
];

function SsoInfoList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.SsoInfo}
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={true}
      filters={ssoInfoFilters}
    >
      <TextField label="Id" source="id" />
      <TextField label="Domain" source="domain" />
      <TextField label="Auth0 Connection Name" source="auth0_connection_name" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
      <CustomReferenceFieldLazy
        label="Account"
        source="account_id"
        reference={Resource.Account}
        sortable={false}
      />
    </CustomList>
  );
}

export default SsoInfoList;
