/**
 * The Authorize component renders its children components if
 * the current admin user is authorized to perform a specific
 * action on a specific resource. If not, it indicates to the
 * user that they do not have the proper permissions.
 */
import { adminAbility } from "../../abilities";
import { Action, DataKind } from "../../types";

interface AuthorizeProps {
  action: Action;
  children: any;
  dataKind: DataKind;
  // If true, hide message that indicates to user that they
  // are unauthorized to perform action. This is useful for
  // show pages where an admin user might not be authorized
  // to view a secondary table and it would be intrusive to
  // display an unauthorized message.
  disableUnauthorizedMessage?: boolean;
}

function Authorize(props: AuthorizeProps) {
  if (adminAbility.canAdminUserPerform(props.action, props.dataKind)) {
    return <>{props.children}</>;
  } else if (!props.disableUnauthorizedMessage) {
    // TODO [High] [REG-871]: Cleaner and more flushed out presentation to user that they aren't authorized.
    return <p>User lacks sufficient permissions to perform this action.</p>;
  } else {
    return null;
  }
}

export default Authorize;
