enum JobInfoKind {
  Command = "command",
  Query = "query",
}

enum JobInfoStatus {
  Available = "available",
  Executing = "executing",
  Failed = "failed",
  Succeeded = "succeeded",
}

export { JobInfoKind, JobInfoStatus };
