import { storage } from "../../storage";
import { AccessToken, HttpClient } from "../../types";
import { httpClientUpdater } from ".";

const accessTokenUpdater = {
  /**
   * Updates the access token in local storage and in the auth header of our HTTP client.
   */
  updateAccessToken(accessToken: AccessToken, httpClient: HttpClient): void {
    storage.updateAccessToken(accessToken);
    httpClientUpdater.updateAuthHeader(accessToken, httpClient);
  },
};

export default accessTokenUpdater;
