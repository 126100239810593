import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { FormEvent } from "react";
import { Button, Identifier, useDataProvider, useGetList } from "react-admin";

import { useForm, useMutation } from "../../../hooks";
import {
  CustomDataProvider,
  DemoProjectTemplateResponse,
  ModalProps,
  Region,
  UserResponse,
} from "../../../types";
import { resourceUtils } from "../../../utils";
import { ActionButtonsBar, Form, Modal } from "..";

interface CreateDemoProjectModalProps extends ModalProps {
  region: Region;
  user: UserResponse;
}

interface FormState {
  projectIdToClone: Identifier;
  userId: Identifier;
  demoName: string;
}

function CreateDemoProjectModal(props: CreateDemoProjectModalProps) {
  const defaultFormState: FormState = {
    projectIdToClone: "",
    userId: props.user.id,
    demoName: "",
  };

  const title = `New Demo Project - ${props.region.toUpperCase()}`;

  const { form, handleChangeForm, handleClearForm } = useForm(defaultFormState);

  const dataProvider = useDataProvider<CustomDataProvider>();
  const { mutate } = useMutation(
    (form: FormState) =>
      dataProvider.createDemoProject(props.region, {
        project_id_to_clone: form.projectIdToClone,
        user_id: form.userId,
        demo_name: form.demoName,
      }),
    ["createDemoProject", form],
    "New demo project successfully enqueued. Please allow a few minutes to complete. ✅",
    true
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClearForm();
    props.handleClose();
    mutate(form);
  };

  return (
    <Modal open={props.modalOpen} title={title}>
      <Form onSubmit={handleSubmit}>
        <TextField disabled label="User Id" value={form.userId} required />
        <DemoType
          handleChange={handleChangeForm}
          region={props.region}
          value={form.projectIdToClone}
        />
        <TextField
          label="Demo Project Name"
          name="demoName"
          onChange={handleChangeForm}
          value={form.demoName}
          required
        />
        <ActionButtonsBar>
          <Button
            label="Cancel"
            onClick={props.handleClose}
            variant="fieldwire-secondary"
          />
          <Button
            disabled={!form.projectIdToClone || !form.demoName}
            label="Create Demo Project"
            type="submit"
            variant="fieldwire-primary"
          />
        </ActionButtonsBar>
      </Form>
    </Modal>
  );
}

function DemoType(props: {
  handleChange: (e: any) => void;
  region: Region;
  value: Identifier;
}) {
  const { data } = useGetList<DemoProjectTemplateResponse>(
    resourceUtils.demoProjectTemplateResourceFor(props.region),
    {
      // Make it simple just showing one page with a higher value
      // than the default one of 25, as we are unlikely to create
      // a large amount of these entities (as of Sep 2024, we have
      // 24 in prod and a bit more on staging).
      pagination: { page: 1, perPage: 100 },
    }
  );

  if (data) {
    return (
      <TextField
        label="Demo Type"
        name="projectIdToClone"
        onChange={props.handleChange}
        value={props.value}
        required
        select
      >
        {data.map((demoProjectTemplate: DemoProjectTemplateResponse) => {
          if (demoProjectTemplate.demo_project_id) {
            return (
              <MenuItem
                key={demoProjectTemplate.id}
                value={demoProjectTemplate.demo_project_id}
              >
                {demoProjectTemplate.name}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </TextField>
    );
  } else {
    return null;
  }
}

export default CreateDemoProjectModal;
