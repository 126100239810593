import { QueryClient } from "react-query";

// From the React-admin documentation:
//
//  "React-admin uses react-query to fetch, cache and update data. Internally,
//   the <Admin> component creates a react-query QueryClient on mount, using
//   react-query’s 'aggressive but sane' defaults."
//
// We create our own QueryClient instance in order to prevent automatic data
// refetches on window focus. We do this in order to prevent extra, and even
// unnecessary, calls from being made to our BE. For example, imagine a user
// switching between tabs frequently. Each time they revisit the tab with
// the admin dashboard, the data will be refetched.
//
// NOTE: All other defaults are retained.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default queryClient;
