import { Identifier } from "react-admin";

import { Resource } from "../types";

// React-admin uses well understood conventions to build routes for
// resources which it uses to draw the front-end routes automatically:
// https://marmelab.com/react-admin/Resource.html#name
//
// Sometime we want to do something special:
//   - We want to dynamically build one of the above routes
//   - We want to route to a custom page
//
// In such cases, this file will serve as the source of truth &
// collection of helpers
//

const PLACEHOLDER_ACCOUNT_ID = ":account_id";
const PLACEHOLDER_PROJECT_ID = ":project_id";
const PATH_TEMPLATE_US_HISTORY_FOR_ACCOUNT = `/accounts/${PLACEHOLDER_ACCOUNT_ID}/us/history`;
const PATH_TEMPLATE_AUDIT_LOG_FOR_US_PROJECT = `/${Resource.UsAuditLogProject}/${PLACEHOLDER_PROJECT_ID}`;

const PATH_DASHBOARD = "/dashboard";
const PATH_IOS_RELEASE_STATUS = "/ios_release_status";
const PATH_SIDEKIQ = "/sidekiq";
const PATH_SYSTEM_STATUS = "/system_status";

const routes = {
  computeAuditLogForUsProject: (projectId: Identifier) => {
    return `#${PATH_TEMPLATE_AUDIT_LOG_FOR_US_PROJECT.replace(
      PLACEHOLDER_PROJECT_ID,
      projectId.toString()
    )}`;
  },

  computeCreateFor: (resource: Resource) => {
    return `#/${resource}/create`;
  },

  computeListFor: (resource: Resource) => {
    return `#/${resource}`;
  },

  computeShowFor: (resource: Resource, id: Identifier) => {
    return `#/${resource}/${id}/show`;
  },

  computeUsHistoryForAccount: (accountId: Identifier) => {
    return `#${PATH_TEMPLATE_US_HISTORY_FOR_ACCOUNT.replace(
      PLACEHOLDER_ACCOUNT_ID,
      accountId.toString()
    )}`;
  },
};

export {
  PATH_DASHBOARD,
  PATH_IOS_RELEASE_STATUS,
  PATH_SIDEKIQ,
  PATH_SYSTEM_STATUS,
  PATH_TEMPLATE_AUDIT_LOG_FOR_US_PROJECT,
  PATH_TEMPLATE_US_HISTORY_FOR_ACCOUNT,
  routes,
};
