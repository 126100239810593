import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";

import {
  ActiveUserCountsForMarketingResponse,
  CustomDataProvider,
} from "../../../../types";
import { ChartContainer, LineChart } from "../../../custom";
import { analyticsUtils } from "../../analytics-utils";

function InvitesSevenDayTrailingChart() {
  const dataProvider = useDataProvider<CustomDataProvider>();

  const { data } = useQuery<{
    data: ActiveUserCountsForMarketingResponse[];
  }>(["getActiveUserCountsForMarketing"], () =>
    dataProvider.getActiveUserCountsForMarketing()
  );

  if (data?.data) {
    const processedData = analyticsUtils.processData(
      data.data,
      new ActiveUserCountsForMarketingResponse(),
      37
    );

    const signups = processedData.map(
      (d: ActiveUserCountsForMarketingResponse) => d.signups
    );
    const signupsSevenDayTrailingSum =
      analyticsUtils.calculateSevenPeriodTrailingSum(signups);

    const invitesSent = processedData.map(
      (d: ActiveUserCountsForMarketingResponse) => d.invites_sent
    );
    const invitesSentSevenDayTrailingSum =
      analyticsUtils.calculateSevenPeriodTrailingSum(invitesSent);

    const invitesAccepted = processedData.map(
      (d: ActiveUserCountsForMarketingResponse) => d.invites_accepted
    );
    const invitesAcceptedSevenDayTrailingSum =
      analyticsUtils.calculateSevenPeriodTrailingSum(invitesAccepted);

    return (
      <ChartContainer title="Invites (7 Day Trailing)">
        <LineChart
          labels={analyticsUtils
            .getLastNItems(processedData, 30)
            .map((d: ActiveUserCountsForMarketingResponse) => d.date)}
          datasets={[
            {
              data: analyticsUtils.getLastNItems(
                signupsSevenDayTrailingSum,
                30
              ),
              label: "Signups",
            },
            {
              data: analyticsUtils.getLastNItems(
                invitesSentSevenDayTrailingSum,
                30
              ),
              label: "New Users Invited",
            },
            {
              data: analyticsUtils.getLastNItems(
                invitesAcceptedSevenDayTrailingSum,
                30
              ),
              label: "New Users Accepted",
            },
          ]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
}

export default InvitesSevenDayTrailingChart;
