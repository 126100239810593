import Link from "@mui/material/Link";
import {
  DateInput,
  FunctionField,
  NumberField,
  TextField,
  TextInput,
} from "react-admin";

import { DataKind, Resource, SyncErrorResponse } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const syncErrorFilters = [
  <TextInput
    label="Sync Owner Email"
    source="sync_owner_email_like"
    alwaysOn
  />,
  <DateInput label="Last Occurred (>=)" source="updated_at_gte" alwaysOn />,
  <DateInput label="Last Occurred (<=)" source="updated_at_lte" alwaysOn />,
  <DateInput label="First Occurred (>=)" source="created_at_gte" alwaysOn />,
  <DateInput label="First Occurred (<=)" source="created_at_lte" alwaysOn />,
  <TextInput label="Error" source="error_like" alwaysOn />,
  <TextInput label="Count (>=)" source="count_gt" alwaysOn />,
  <TextInput label="Count (=)" source="count_eq" alwaysOn />,
  <TextInput label="Count (<=)" source="count_lt" alwaysOn />,
];

function SyncErrorList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.SyncError}
      defaultSort={{ field: "updated_at", order: "DESC" }}
      displayCreate={false}
      filters={syncErrorFilters}
    >
      <CustomDateTimeField label="Last Occurred" source="updated_at" />
      <CustomDateTimeField
        label="First Occurred"
        source="created_at"
        sortable={false}
      />
      <CustomReferenceField
        label="Sync Owner"
        reference={Resource.User}
        source="sync_owner_id"
        sortable={false}
      >
        <TextField source="email" />
      </CustomReferenceField>
      <NumberField label="Count" source="count" />
      <TextField label="Entity Type" source="entity_type" sortable={false} />
      <TextField label="Entity Name" source="entity_name" sortable={false} />
      <TextField label="Entity Id" source="entity_id" sortable={false} />
      <FunctionField
        label="Error (May Link to Rollbar)"
        render={(record: SyncErrorResponse) =>
          record.error_url ? (
            <Link
              href={record.error_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {record.error_message}
            </Link>
          ) : (
            record.error_message
          )
        }
        sortable={false}
      />
    </CustomList>
  );
}

export default SyncErrorList;
