/**
 * An opinionated, custom hook that updates invoice address information for a
 * given account and then calls the passed callback if the update is successful.
 *
 * This custom hook is currently used by the Checkout and UpdateCreditCard modals.
 */

import { useUpdate } from "react-admin";

import { getName, subdivision } from "../iso";
import { AccountResponse, Resource } from "../types";

interface AccountInvoiceAddressInformation {
  company_address: string;
  invoice_country_iso2: string;
  invoice_city: string;
  invoice_state_iso2: string;
  invoice_postal_code: string;
}

function useUpdateAccountInvoiceAddressInformation(
  account: AccountResponse,
  accountInvoiceAddressInfo: AccountInvoiceAddressInformation,
  onSuccessCallback: () => void
) {
  const [update] = useUpdate(
    Resource.Account,
    {
      id: account.id,
      data: {
        company_address: accountInvoiceAddressInfo.company_address,
        invoice_country_iso2: accountInvoiceAddressInfo.invoice_country_iso2,
        invoice_city: accountInvoiceAddressInfo.invoice_city,
        invoice_state_iso2: accountInvoiceAddressInfo.invoice_state_iso2,
        invoice_postal_code: accountInvoiceAddressInfo.invoice_postal_code,
        // Get country name from country code (ex: "CA" returns "Canada").
        invoice_country: getName(
          accountInvoiceAddressInfo.invoice_country_iso2,
          "en"
        ),
        // Get state name from state code (ex: "CA-ON" returns "Ontario") if applicable.
        invoice_state:
          subdivision(
            accountInvoiceAddressInfo.invoice_country_iso2,
            accountInvoiceAddressInfo.invoice_state_iso2
          )?.name ?? "",
      },
      previousData: account,
    },
    {
      onSuccess: () => {
        onSuccessCallback();
      },
    }
  );

  return update;
}

export default useUpdateAccountInvoiceAddressInformation;
