import { NullableBooleanInput, SelectInput, TextInput } from "react-admin";

import { AccountRole, DataKind, EmailConfirmationStatus } from "../../types";
import { componentUtils } from "../component_utils";
import { CustomEdit, CustomNumberInput } from "../custom";

const accountRoleChoices =
  componentUtils.generateCapitalCaseSelectChoicesFromEnum(AccountRole);

// We don't allow selecting pending status since this is derived from
// the user's signup date with respect to email verification feature
// launch date.
const emailConfirmationStatusChoices = [
  ...componentUtils.generateCapitalCaseSelectChoicesFromEnum(
    Object.values(EmailConfirmationStatus).filter(
      (emailConfirmationStatus: EmailConfirmationStatus) =>
        emailConfirmationStatus !== EmailConfirmationStatus.Pending
    )
  ),
  // Let's have pending status show up as disabled in the drop-down.
  // This is preferable so that users with pending status will have
  // the field auto-populated while also ensuring the option is not
  // available for selection.
  {
    id: EmailConfirmationStatus.Pending,
    name: componentUtils.snakeCaseToCapitalCase(
      EmailConfirmationStatus.Pending
    ),
    disabled: true,
  },
];

function UserEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.User}>
      <NullableBooleanInput label="Is Approved" source="is_approved" required />
      <TextInput label="First Name" source="first_name" required />
      <TextInput label="Last Name" source="last_name" required />
      <TextInput label="Company" source="company" />
      <TextInput label="Job Title" source="job_title" />
      <TextInput label="Phone Number" source="phone_number" />
      <TextInput label="Email" source="email" />
      <SelectInput
        label="Email Confirmation Status"
        source="email_confirmation_status"
        choices={emailConfirmationStatusChoices}
        required
      />
      <CustomNumberInput label="Account ID" source="account_id" required />
      <SelectInput
        label="Account Role"
        source="account_role"
        choices={accountRoleChoices}
        required
      />
    </CustomEdit>
  );
}

export default UserEdit;
