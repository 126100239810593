import { BooleanField, NumberField, TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  CustomReferenceFieldLazy,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const projectFilters = [
  <TextInput label="Name" source="name_like" alwaysOn />,
  <TextInput label="Address" source="address_like" alwaysOn />,
  <TextInput
    label="Avg Lead Score (>=)"
    source="lead_score_avg_gte"
    alwaysOn
  />,
  <TextInput label="Total Users (>=)" source="total_users_gte" alwaysOn />,
  <TextInput label="30D Users (>=)" source="total_30_day_users_gte" alwaysOn />,
];

function ProjectList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.Project}
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={false}
      filters={projectFilters}
    >
      <TextField label="Name" source="name" />
      <TextField label="Company" source="company_name" sortable={false} />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Deleted At" source="deleted_at" />
      <CustomReferenceField
        label="Deleted By"
        source="deleted_by_user_id"
        reference={Resource.User}
        sortable={false}
      >
        <TextField source="email" />
      </CustomReferenceField>
      <CustomDateTimeField
        label="Last Active At"
        source="last_active_at"
        sortable={false}
      />
      <NumberField
        label="Sum. Lead Score"
        source="lead_score"
        sortable={false}
      />
      <NumberField
        label="Avg. Lead Score"
        source="lead_score_avg"
        sortable={false}
      />
      <NumberField label="Total Users" source="total_users" sortable={false} />
      <NumberField
        label="30 Day Users"
        source="active_30_users"
        sortable={false}
      />
      <NumberField label="Task Count" source="tasks_sequence_counter" />
      <TextField label="Address" source="address" />
      <BooleanField label="BIM Enabled" source="is_3d_bim_enabled" />
      <BooleanField label="Forms Enabled" source="is_forms_enabled" />
      <BooleanField label="Are RFIs Enabled" source="is_pm_enabled" />
      <CustomReferenceFieldLazy
        label="Account"
        source="account_id"
        reference={Resource.Account}
      />
    </CustomList>
  );
}

export default ProjectList;
