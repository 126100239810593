import { TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  CustomReferenceFieldLazy,
} from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const apiKeyFilters = [
  <TextInput label="Account ID" source="account_id_eq" alwaysOn />,
  <TextInput label="User ID" source="user_id_eq" alwaysOn />,
  <TextInput label="Description" source="description_like" alwaysOn />,
];

function ApiKeyList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.ApiKey}
      displayCreate={false}
      filters={apiKeyFilters}
    >
      <TextField label="Id" source="id" />
      <CustomReferenceFieldLazy
        label="Account"
        source="account_id"
        reference={Resource.Account}
      />
      <CustomReferenceField
        label="User"
        source="user_id"
        reference={Resource.User}
        sortable={false}
      >
        <TextField source="email" />
      </CustomReferenceField>
      <TextField label="Description" source="description" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
    </CustomList>
  );
}

export default ApiKeyList;
