import {
  BooleanField,
  FunctionField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { AccountResponse, DataKind, Region, Resource } from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
} from "../custom";

const regionChoices =
  componentUtils.generateUpperCaseSelectChoicesFromEnum(Region);

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const accountFilters = [
  <TextInput label="Owner Email" source="owner_email_like" alwaysOn />,
  <TextInput label="Invoice Email" source="invoice_email_like" alwaysOn />,
  <TextInput label="Fieldwire Rep" source="fw_rep_admin_user_id_eq" alwaysOn />,
  <SelectInput
    choices={regionChoices}
    label="Primary Region"
    source="primary_region_eq"
    alwaysOn
  />,
  <TextInput
    label="Hilti Customer Id"
    source="hilti_customer_id_eq"
    alwaysOn
  />,
];

function AccountList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.Account}
      displayCreate={false}
      filters={accountFilters}
    >
      <TextField label="Id" source="id" />
      <TextField label="Owner" source="owner_email" sortable={false} />
      {/* QUESTION: Why don't we see any invoice emails in the current admin dashboard? */}
      <TextField
        label="Invoice Email"
        source="invoice_email"
        sortable={false}
      />
      <TextField label="Plan Name" source="plan_info" sortable={false} />
      <CustomReferenceField
        label="Company"
        source="company_id"
        reference={Resource.Company}
        sortable={false}
      >
        <TextField source="name" />
      </CustomReferenceField>
      <TextField
        label="Fieldwire Rep"
        source="fw_rep_admin_user_id"
        sortable={false}
      />
      <TextField label="Primary Region" source="primary_region" />
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField label="Updated At" source="updated_at" />
      {/* MISSING: Sum Lead Score */}
      {/* MISSING: Avg Lead Score */}
      {/* MISSING: Total Users */}
      {/* MISSING: 30 Day Users*/}
      <CustomDateTimeField label="Blocked At" source="blocked_at" />
      <BooleanField label="EU Enabled" source="is_eu_region_enabled" />
      <BooleanField label="Forms Enabled" source="is_forms_enabled" />
      <BooleanField label="BIM Enabled" source="is_3d_bim_enabled" />
      <BooleanField label="Are RFIs Enabled" source="is_pm_enabled" />
      <BooleanField
        label="Auto Charge Overage"
        source="is_auto_charge_overage"
        sortable={false}
      />
      <BooleanField label="User Limit" source="is_user_limit" />
      <BooleanField label="API Access Enabled" source="is_api_access_enabled" />
      <BooleanField
        label="Access Control Export URL Enabled"
        source="access_control_export_url_enabled"
      />
      <FunctionField
        label="SSO Domains"
        render={(record: AccountResponse) => record.sso_domains?.join(", ")}
        sortable={false}
      />
      <BooleanField
        label="Daily Report Active"
        source="daily_report_active"
        sortable={false}
      />
      <TextField label="Hilti Customer Id" source="hilti_customer_id" />
    </CustomList>
  );
}

export default AccountList;
