/**
 * These chart options are passed to the chart components
 * (ex: `<Line />`) that are used throughout the codebase.
 */

// The time scale requires both a date library and a corresponding adapter
// to be present. Please see the following link for more information:
//
// https://www.chartjs.org/docs/latest/axes/cartesian/time.html#date-adapters
import "chartjs-adapter-date-fns";

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";

// Chart.js 3 is tree-shakeable which means that we need to
// import and register the controllers, elements, scales and
// plugins we want to use. Notice that we don't register any
// controllers. This is because react-chartjs-2 takes care
// of this for us!
ChartJS.register(
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
);

// These static colors define the border and background colors that our chart datasets
// use. The first dataset on the chart always uses "#3366cc", the second dataset always
// uses "#dc3912", and so on and so forth. In JavaScript, for better or worse, accessing
// an out of bounds index returns `undefined`. Therefore, if the number of datasets on a
// single chart exceeds the length of this array, the border and background colors of that
// dataset will be set to `undefined`, which always defaults to the underlying chart library's
// single default color. If we exceed the number of colors that this array provides, we should
// add more.
const CHART_COLORS = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
];

// NOTE: These options and the `stackedAreaChartOptions` should
// be kept in line, except for an additional property on the
// `stackedAreaChartOptions`, unless determined otherwise.
const lineChartOptions: ChartOptions<"line"> = {
  animation: false,
  datasets: {
    line: {
      spanGaps: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      labels: {
        boxHeight: 10,
        boxWidth: 10,
      },
      position: "bottom",
    },
  },
  responsive: true,
  scales: {
    x: {
      time: {
        displayFormats: {
          week: "M/d",
        },
        tooltipFormat: "MMM d, yyyy",
        unit: "week",
      },
      type: "time",
    },
    y: {
      beginAtZero: true,
    },
  },
};

// NOTE: These options and the `lineChartOptions` should
// be kept in line, except for an additional property on
// these options, unless determined otherwise.
const stackedAreaChartOptions: ChartOptions<"line"> = {
  ...lineChartOptions,
  scales: {
    ...lineChartOptions.scales,
    y: {
      ...(lineChartOptions.scales && lineChartOptions.scales["y"]),
      stacked: true,
    },
  },
};

export { CHART_COLORS, lineChartOptions, stackedAreaChartOptions };
