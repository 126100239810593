import { TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

function DemoProjectTemplateEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.DemoProjectTemplate}>
      <TextInput label="Name" source="name" />
    </CustomEdit>
  );
}

export default DemoProjectTemplateEdit;
