import { TextField } from "react-admin";

import { DataKind } from "../../types";
import { CustomDateTimeField, CustomList } from "../custom";

function DemoProjectUserList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.DemoProjectUser}
      displayCreate={true}
    >
      <TextField label="Id" source="id" sortable={false} />
      <TextField
        label="Demo Project User Id"
        source="demo_project_user_id"
        sortable={false}
      />
      <TextField
        label="Demo Project Shadow User Id"
        source="demo_project_shadow_user_id"
        sortable={false}
      />
      <CustomDateTimeField
        label="Created At"
        source="created_at"
        sortable={false}
      />
      <CustomDateTimeField
        label="Updated At"
        source="updated_at"
        sortable={false}
      />
    </CustomList>
  );
}

export default DemoProjectUserList;
