import { NumberField, TextField, TextInput } from "react-admin";

import { DataKind, Resource } from "../../types";
import { CustomList } from "../custom";

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const payingStripeOwnerFilters = [
  <TextInput label="Email" source="email_like" alwaysOn />,
];

function PayingStripeOwnerList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.PayingStripeOwner}
      displayCreate={false}
      filters={payingStripeOwnerFilters}
      customRowClickConfig={{ reference: Resource.User, source: "user_id" }}
    >
      <TextField label="User Id" source="user_id" sortable={false} />
      <TextField label="Account Id" source="account_id" sortable={false} />
      <TextField label="Email" source="email" sortable={false} />
      <TextField label="Company" source="company" sortable={false} />
      <TextField label="Plan" source="plan_name" sortable={false} />
      <NumberField label="Price" source="current_price" sortable={false} />
      <NumberField
        label="Checkout User Count"
        source="invoiced_quantity"
        sortable={false}
      />
      <NumberField
        label="Current User Count"
        source="current_user_count"
        sortable={false}
      />
    </CustomList>
  );
}

export default PayingStripeOwnerList;
