import AdminUserRole from "./AdminUser";
import EnterprisePlanInvoiceKind from "./EnterprisePlanInvoice";
import StripeSubscriptionKind from "./StripeSubscription";

// NOTE: The following enums don't correspond to a single entity or resource.

enum Action {
  Create = "create",
  Delete = "delete",
  Read = "read",
  Update = "update",
}

// ISO 3166-2 codes for Canada and the United States. Used by
// the checkout modal and its helpers to differentiate between
// which countries we do and don't need to collect state info
// for.
enum CountryCodeRequiringState {
  Canada = "CA",
  UnitedStates = "US",
}

enum EmptyOrPresent {
  Empty = "empty",
  Present = "present",
}

export {
  Action,
  AdminUserRole,
  CountryCodeRequiringState,
  EmptyOrPresent,
  EnterprisePlanInvoiceKind,
  StripeSubscriptionKind,
};

export * from "./Account";
export * from "./AuditLog";
export * from "./Charge";
export * from "./Currency";
export * from "./DataKind";
export * from "./EnterprisePlan";
export * from "./JobInfo";
export * from "./Language";
export * from "./Project";
export * from "./Resource";
export * from "./StripePlan";
export * from "./User";
