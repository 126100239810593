import { buttonClasses } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useEffect } from "react";
import { Button, LoginForm, LoginFormClasses, useCheckAuth } from "react-admin";
import { useNavigate } from "react-router-dom";

import fieldwireLogoPonytail from "../../../assets/fieldwire-logo-ponytail.svg";
import { useModalState } from "../../../hooks";
import { MadeWithLove, TroubleLoggingInModal } from "..";

function TroubleLoggingInButton() {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    <>
      <Button
        label="Trouble logging in?"
        onClick={handleOpen}
        sx={{ marginTop: "16px" }}
        variant="fieldwire-link"
      />
      <TroubleLoggingInModal handleClose={handleClose} modalOpen={modalOpen} />
    </>
  );
}

function CustomLogin() {
  // The following code is taken from React Admin's underlying
  // `Login` component. It should not be directly modified!
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // Already authenticated, redirect to the home page.
        navigate("/");
      })
      .catch(() => {
        // Not authenticated, stay on the login page.
      });
  }, [checkAuth, navigate]);
  // End of code taken from underlying `Login` component.

  return (
    <Box
      sx={{
        alignItems: "center",
        bgcolor: "fieldwire.gray.6",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          alignItems: "center",
          borderColor: "fieldwire.gray.4",
          display: "flex",
          flexDirection: "column",
          padding: "36px 24px",
          // The `LoginForm` component does not expose the `sx`
          // prop so we set styles for pieces of the login form
          // such as the login button, login content, etc here.
          [`& .${LoginFormClasses.button}`]: {
            bgcolor: "fieldwire.blue.3",
            borderColor: "fieldwire.blue.2",
            borderRadius: "3px",
            borderStyle: "solid",
            borderWidth: "1px",
            color: "fieldwire.white.1",
            ":hover": {
              bgcolor: "fieldwire.blue.2",
              borderColor: "fieldwire.blue.1",
            },
            [`&.${buttonClasses.disabled}`]: {
              // #3173FF (fieldwire.blue.3) at 60% opacity.
              bgcolor: "#3173FF99",
              // #0D5BFF (fieldwire.blue.2) at 60% opacity.
              borderColor: "#0D5BFF99",
              color: "fieldwire.white.1",
            },
          },
          [`& .${LoginFormClasses.content}`]: {
            padding: "0",
            // This is necessary because this is a standalone
            // style from React Admin/MUI that overrides the
            // "padding: 0" we set above.
            "&:last-child": {
              paddingBottom: "0",
            },
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <img
            src={fieldwireLogoPonytail}
            height="163"
            width="180"
            alt="Fieldwire logo ponytail"
          />
        </Box>
        <LoginForm />
        <TroubleLoggingInButton />
      </Card>
      <MadeWithLove />
    </Box>
  );
}

export default CustomLogin;
