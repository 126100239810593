import { Identifier } from "react-admin";

class ActiveUserCountsForMarketingResponse {
  // NOTE: The nullability for these analytics tables are mixed. At the database level
  // some fields are not nullable, some are, and some have default values. Since we are
  // not doing anything special for these fields, we decided it would be easier to assume
  // that any of the counts field can be null in the response. That way we can treat them
  // all uniformly on the frontend. Lastly, you might see in the DB that even date field
  // isn't marked as not nullable, but we needn't worry about that since our business
  // logic makes sure it is there for the returned objects.

  // Non-null fields.
  id: Identifier;
  date: string;

  // Potentially null fields.
  android_signups_unknown_source: number | null;
  floorplans_conversion_rate_7_days: number | null;
  floorplans_conversion_rate: number | null;
  invites_accepted: number | null;
  invites_sent: number | null;
  ipad_signups_paid_source: number | null;
  ipad_signups_unknown_source: number | null;
  iphone_signups_paid_source: number | null;
  iphone_signups_unknown_source: number | null;
  last_3_days_total: number | null;
  last_7_days_total: number | null;
  last_day_total: number | null;
  project_users_conversion_rate_7_days: number | null;
  project_users_conversion_rate: number | null;
  projects_conversion_rate_7_days: number | null;
  projects_conversion_rate: number | null;
  signups_with_floorplan_from_android_unknown_source: number | null;
  signups_with_floorplan_ipad_paid_source: number | null;
  signups_with_floorplan_ipad_unknown_source: number | null;
  signups_with_floorplan_iphone_paid_source: number | null;
  signups_with_floorplan_iphone_unknown_source: number | null;
  signups_with_floorplan_web_paid_source: number | null;
  signups_with_floorplan_web_unknown_source: number | null;
  signups: number | null;
  tasks_conversion_rate_7_days: number | null;
  tasks_conversion_rate: number | null;
  web_signups_paid_source: number | null;
  web_signups_unknown_source: number | null;

  constructor() {
    // The constructor is only used to create an empty `ActiveUserCountsForMarketingResponse`
    // object for dates which are missing. Therefore, we assign the date the empty string as
    // it will eventually be overwritten with the value of the actual missing date.
    this.date = "";
    // We don't use `id` so we will assign it a nonsensical value.
    this.id = -1;
    this.android_signups_unknown_source = null;
    this.floorplans_conversion_rate_7_days = null;
    this.floorplans_conversion_rate = null;
    this.invites_accepted = null;
    this.invites_sent = null;
    this.ipad_signups_paid_source = null;
    this.ipad_signups_unknown_source = null;
    this.iphone_signups_paid_source = null;
    this.iphone_signups_unknown_source = null;
    this.last_3_days_total = null;
    this.last_7_days_total = null;
    this.last_day_total = null;
    this.project_users_conversion_rate_7_days = null;
    this.project_users_conversion_rate = null;
    this.projects_conversion_rate_7_days = null;
    this.projects_conversion_rate = null;
    this.signups_with_floorplan_from_android_unknown_source = null;
    this.signups_with_floorplan_ipad_paid_source = null;
    this.signups_with_floorplan_ipad_unknown_source = null;
    this.signups_with_floorplan_iphone_paid_source = null;
    this.signups_with_floorplan_iphone_unknown_source = null;
    this.signups_with_floorplan_web_paid_source = null;
    this.signups_with_floorplan_web_unknown_source = null;
    this.signups = null;
    this.tasks_conversion_rate_7_days = null;
    this.tasks_conversion_rate = null;
    this.web_signups_paid_source = null;
    this.web_signups_unknown_source = null;
  }
}

class ActiveUserCountsForFinancesResponse {
  // NOTE: The nullability for these analytics tables are mixed. At the database level
  // some fields are not nullable, some are, and some have default values. Since we are
  // not doing anything special for these fields, we decided it would be easier to assume
  // that any of the counts field can be null in the response. That way we can treat them
  // all uniformly on the frontend. Lastly, you might see in the DB that even date field
  // isn't marked as not nullable, but we needn't worry about that since our business
  // logic makes sure it is there for the returned objects.

  // Non-null fields.
  id: Identifier;
  date: string;

  // Potentially null fields.
  active_projects: number | null;
  contender_projects: number | null;
  enterprise_estimated_overage_mmr: number | null;
  mrr_business: number | null;
  mrr_business_plus: number | null;
  mrr_enterprise_per_project: number | null;
  mrr_enterprise_per_user: number | null;
  mrr_enterprise_poc: number | null;
  mrr_pro: number | null;
  paying_business: number | null;
  paying_business_plus: number | null;
  paying_enterprise: number | null;
  paying_pro: number | null;
  paying_projects: number | null;

  constructor() {
    // The constructor is only used to create an empty `ActiveUserCountsForFinancesResponse`
    // object for dates which are missing. Therefore, we assign the date the empty string as
    // it will eventually be overwritten with the value of the actual missing date.
    this.date = "";
    // We don't use `id` so we will assign it a nonsensical value.
    this.id = -1;
    this.active_projects = null;
    this.contender_projects = null;
    this.enterprise_estimated_overage_mmr = null;
    this.mrr_business = null;
    this.mrr_business_plus = null;
    this.mrr_enterprise_per_project = null;
    this.mrr_enterprise_per_user = null;
    this.mrr_enterprise_poc = null;
    this.mrr_pro = null;
    this.paying_business = null;
    this.paying_business_plus = null;
    this.paying_enterprise = null;
    this.paying_pro = null;
    this.paying_projects = null;
  }
}

interface MetricsResponse {
  annual_recurring_revenue_end_of_month: number;
}

interface Signup {
  date: string;
  count: number;
}

interface SignupEmailVerificationDistributionResponse {
  total_signups: Signup[];
  unverified_signups: Signup[];
  verified_signups: Signup[];
}

interface UsersSignupsCountryDistributionResponse {
  country_code: string;
  week_counts: WeekCount[];
}

interface WeekCount {
  week_start: string;
  week_count: number;
}

export {
  ActiveUserCountsForFinancesResponse,
  ActiveUserCountsForMarketingResponse,
};

export type {
  MetricsResponse,
  Signup,
  SignupEmailVerificationDistributionResponse,
  UsersSignupsCountryDistributionResponse,
  WeekCount,
};
