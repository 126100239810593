import { TextField } from "react-admin";

import { DataKind, Resource } from "../../types";
import {
  CustomDateTimeField,
  CustomReferenceField,
  CustomShow,
  ShowTable,
} from "../custom";

function AdminRoleMembershipShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.AdminRoleMembership}
      displayDelete={true}
      displayEdit={false}
    >
      <ShowTable title="Admin Role Membership Details">
        <TextField label="Id" source="id" />
        <CustomReferenceField
          label="Admin User"
          source="admin_user_id"
          reference={Resource.AdminUser}
        >
          <TextField source="email" />
        </CustomReferenceField>
        <TextField label="Role" source="role" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
      </ShowTable>
    </CustomShow>
  );
}

export default AdminRoleMembershipShow;
