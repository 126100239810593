import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import { FunctionField, TextField } from "react-admin";

import { DataKind, FeatureFlagResponse } from "../../types";
import { CustomList } from "../custom";

function SystemConfigurationList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.FeatureFlag}
      displayCreate={false}
      disableRowClick
      // This endpoint doesn't allow for pagination
      // so we hide the controls from the UI.
      hidePaginationControl
    >
      <TextField
        label="Key"
        source="key"
        // Will enable sorting pending BE changes for unique ids.
        sortable={false}
      />
      <FunctionField
        label="Value from Env"
        source="value"
        render={(record: FeatureFlagResponse) => {
          if (record.has_no_env_variable) {
            return (
              <Tooltip title="A value for this key might or might not be set up in the ENV. We weren't able to programmatically figure it out!">
                <WarningIcon
                  fontSize="inherit"
                  sx={{ color: "fieldwire.red.3" }}
                />
              </Tooltip>
            );
          } else {
            return record.value;
          }
        }}
        // Will enable sorting pending BE changes for unique ids.
        sortable={false}
      />
      <TextField
        label="Default Value"
        source="default_value"
        // Will enable sorting pending BE changes for unique ids.
        sortable={false}
      />
      <TextField
        label="Module"
        source="mod"
        // Will enable sorting pending BE changes for unique ids.
        sortable={false}
      />
    </CustomList>
  );
}

export default SystemConfigurationList;
