import { NullableBooleanInput, TextInput } from "react-admin";

import { DataKind } from "../../types";
import { CustomEdit } from "../custom";

function InAppPurchaseEdit() {
  return (
    <CustomEdit checkPermissionFor={DataKind.InAppPurchase}>
      <TextInput label="App Store ID" source="app_store_id" required />
      <TextInput label="Description" source="description" required />
      <NullableBooleanInput label="Is Enabled" source="is_enabled" required />
    </CustomEdit>
  );
}

export default InAppPurchaseEdit;
