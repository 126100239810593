/**
 * An opinionated confirmation modal that displays a title, a body of
 * text (description), and cancel + confirmation buttons. The variant
 * and text of the confirm button is configurable, while the variant
 * and text of the cancel button is not.
 */

import Box from "@mui/material/Box";
import { ReactEventHandler } from "react";
import { Button } from "react-admin";

import { ActionButtonsBar, Modal } from "..";

interface ConfirmationModalProps {
  cancelHandler: ReactEventHandler<HTMLButtonElement>;
  confirmHandler: ReactEventHandler<HTMLButtonElement>;
  confirmButtonText: string;
  confirmButtonVariant: "fieldwire-primary" | "fieldwire-danger";
  description: string;
  modalOpen: boolean;
  title: string;
}

function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <Modal open={props.modalOpen} title={props.title}>
      <Box
        component="p"
        sx={{ color: "fieldwire.gray.1", lineHeight: "150%", margin: "0" }}
      >
        {props.description}
      </Box>
      <ActionButtonsBar>
        <Button
          label="Cancel"
          onClick={props.cancelHandler}
          variant="fieldwire-secondary"
        />
        <Button
          label={props.confirmButtonText}
          onClick={props.confirmHandler}
          variant={props.confirmButtonVariant}
        />
      </ActionButtonsBar>
    </Modal>
  );
}

export default ConfirmationModal;
